<template>
    <div>
        <div class="cardDefault">
            <div class="card-body">
                <h3 class="titleDefault">
                    Informações de pagamento

                    <v-btn
                        outlined
                        color="successAlt"
                        class="mr-4"
                        style="float: right"
                        v-if="$hasPermission('create-payments')"
                        @click="dialog = true"
                    >
                        <v-icon class="mr-20">mdi-plus-circle</v-icon>
                        Adicionar novo pagamento
                    </v-btn>
                </h3>

                <v-layout row wrap>
                    <v-flex xs1 class="filtrosArea mt-5">
                        <!-- <span>FILTROS:</span> -->
                    </v-flex>
                    <v-flex xs3>
                        <v-select
                            :items="statusItems"
                            label="Status"
                            @change="filterPaymentStatus"
                            item-text="name"
                            item-value="id"
                            clearable
                        ></v-select>
                    </v-flex>
                    <v-flex xs3>
                        <v-menu
                            ref="show_start_date"
                            :close-on-content-click="false"
                            v-model="show_start_date"
                            :nudge-right="40"
                            :return-value.sync="start_date"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    :value="changeDate(start_date)"
                                    label="Data de início"
                                    prepend-icon="mdi-calendar-arrow-left"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="start_date" @input="filterStartDate" />
                        </v-menu>
                    </v-flex>

                    <v-flex xs3>
                        <v-menu
                            ref="show_end_date"
                            :close-on-content-click="false"
                            v-model="show_end_date"
                            :nudge-right="40"
                            :return-value.sync="end_date"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    slot="activator"
                                    :value="changeDate(end_date)"
                                    label="Data fim"
                                    prepend-icon="mdi-calendar-arrow-right"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker v-model="end_date" @input="filterEndDate" />
                        </v-menu>
                    </v-flex>
                </v-layout>

                <v-data-table
                    :headers="!$hasRole('Diwer') ? headersPartners : headers"
                    :items="transactions"
                    :items-per-page="10"
                    :loading="$store.getters.loading"
                    item-key="id"
                >
                    <template v-slot:[`item.amount`]="{ item }">
                        {{ item.amount | currency }}
                    </template>
                    <template v-slot:[`item.due_date`]="{ item }">
                        {{ item.due_date | date }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            outlined
                            :color="
                                item.payment_status.id === 1
                                    ? 'successAlt'
                                    : 'grey'
                            "
                        >
                            {{ item.payment_status.name }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.observation`]="{ item }">
                        {{ item.observation }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            color="successAlt"
                            :disabled="!$hasPermission('edit-payments-any-status') && item.status_id === 1"
                            outlined
                            class="mr-3 buttonWhite"
                            @click="openDialog(item.id)"
                            v-if="$hasPermission('edit-payments')"
                        >
                            <v-icon>mdi-pencil</v-icon> Editar
                        </v-btn>
                        <v-btn
                            color="errorAlt"
                            outlined
                            class="buttonWhite"
                            @click="excludeTransaction(item.id)"
                            v-if="$hasPermission('delete-payments')"
                        >
                            <v-icon>mdi-close-circle</v-icon> Excluir
                        </v-btn>
                    </template>
                    <template v-slot:[`body.append`]>
                        <tr class="bodyFinal">
                            <td><b>Total:</b></td>
                            <td>
                                <b>
                                    {{ transactions.reduce( (a, b) => a + (b['amount'] || 0), 0 ) | currency }}
                                </b>
                            </td>
                            <td colspan="4" />
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </div>

        <v-dialog v-model="dialog" max-width="600" persistent="">
            <v-card class="defaultModal">
                <v-card-title>{{ !edit ? 'Novo Pagamento' : 'Editando o transaction' }}</v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="transactionValid">
                        <v-text-field
                            outlined
                            required
                            prefix="R$"
                            v-money="moneyCents"
                            v-model="transaction.amount"
                            :rules="[
                                !!transaction.amount ||
                                    'Quantidade é requerido',
                            ]"
                            label="Valor Total"
                        />

                        <v-text-field
                            outlined
                            required
                            type="number"
                            v-if="!edit"
                            v-model="transaction.installments"
                            :rules="[
                                !!transaction.installments ||
                                    'Quantidade é requerido',
                            ]"
                            label="Quantidade de parcelas"
                        />

                        <v-text-field
                            outlined
                            required
                            prepend-inner-icon="mdi-calendar-range"
                            :value="changeDate(transaction.due_date)"
                            @click="showPicker = true"
                            label="Dia do Pagamento"
                            placeholder="Selecione uma data"
                            readonly
                        />

                        <v-select
                            v-if="edit"
                            outlined
                            v-model="transaction.status_id"
                            label="Status"
                            :items="statusItems"
                            item-text="name"
                            item-value="id"
                        />
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="errorAlt" text @click="cancelDialog">
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="successAlt"
                        dark
                        depressed
                        @click="savePayment"
                        :loading="$store.getters.loading"
                        :disabled="$store.getters.loading"
                    >
                        Salvar Pagamento
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showPicker" max-width="400">
            <v-date-picker
                @input="selectedDate"
                locale="pt-br"
                v-model="transaction.due_date"
                :show-current="true"
            ></v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
    import object from '../../../util/mixins/object/object';
    import feedback from '../../../util/mixins/feedback/feedback';
    import api from '../../../util/api/api';
    import moment from 'moment';

    export default {
        name: 'JobsPayment',
        mixins: [object, api, feedback],
        props: {
            id: { type: String, required: true },
        },
        data() {
            return {
                show_start_date: false,
                start_date: null,
                show_end_date: false,
                end_date: null,
                filters: {
                    search: '',
                    status: '',
                    start_date: null,
                    end_date: null,
                },
                transactions: [],
                dialog: false,
                transactionValid: false,
                transaction: {},
                showPicker: false,
                edit: false,
                headers: [
                    { text: 'ID', align: 'start', value: 'id' },
                    { text: 'Quantidade', align: 'start', value: 'amount' },
                    { text: 'Data Pagamento', value: 'due_date' },
                    { text: 'Observação', value: 'observation' },
                    { text: 'Status', value: 'status', width: '30%' },
                    { text: 'Ações', value: 'actions' },
                ],
                headersPartners: [
                    { text: 'ID', align: 'start', value: 'id' },
                    { text: 'Quantidade', align: 'start', value: 'amount' },
                    { text: 'Data', value: 'date' },
                    { text: 'Status', value: 'status', width: '30%' },
                ],
            };
        },
        created() {
            this.getTransactions();
        },
        methods: {
            getTransactions() {
                var params = '';
                if (this.filters.status) {
                    params += '&status_id=' + this.filters.status;
                }
                if (this.filters.start_date) {
                    params += '&start_date=' + this.filters.start_date;
                }
                if (this.filters.end_date) {
                    params += '&end_date=' + this.filters.end_date;
                }
                this.get(`/jobs/${this.id}/transaction?1=1${params}`).then(response => {
                    this.transactions = response.data.data;
                });
            },
            openDialog(item) {
                this.edit = true;
                this.get(`/transactions/${item}`).then(response => {
                    this.transaction = response.data.data;
                    this.transaction.amount = this.unformatMaskByType(this.transaction.amount, 'MONEYMASK');
                    this.dialog = true;
                });
            },
            cancelDialog() {
                this.transaction = {};
                this.dialog = false;
                this.edit = false;
            },
            selectedDate(valor) {
                if (valor) {
                    this.showPicker = false;
                }
            },
            excludeTransaction(id) {
                if (
                    this.askForConfirmation(
                        'Remover transação',
                        'Realmente deseja remover essa transação? Esse processo é irreversível!',
                        () => {
                            this.delete(`/transactions/${id}`).then(() => {
                                this.dialog = false;
                                this.getTransactions();
                            });
                        }
                    )
                );
            },
            changeDate(value) {
                if (value) return moment(value).format('DD/MM/YYYY');
            },
            savePayment() {
                if (this.$refs.form.validate()) {
                    if (this.transaction.id) {
                        const transactionObject = {
                            due_date: this.transaction.due_date,
                            status_id: this.transaction.status_id,
                            amount: this.unformatMaskByType(this.transaction.amount, 'MONEY'),
                        };
                        this.put(`/transactions/${this.transaction.id}`, transactionObject).then(() => {
                            this.dialog = false;
                            this.edit = false;
                            this.getTransactions();
                        });
                    } else {
                        const transactionObject = {
                            ...this.transaction,
                            amount: this.unformatMaskByType(this.transaction.amount, 'MONEY'),
                            job_id: this.id,
                        };
                        this.post(`/jobs/transaction`, transactionObject).then(() => {
                            this.dialog = false;
                            this.getTransactions();
                        });
                    }
                    this.dialog = false;
                }
            },
            filterPaymentStatus(val) {
                this.filters = this.$MultiFilters.updateFilters(this.filters, {
                    status: val,
                });
                this.getTransactions();
            },
            filterStartDate(val) {
                // Close the date picker.
                this.$refs.show_start_date.save(val);
                //Convert the value to a timestamp before saving it.
                //const timestamp = new Date(val + 'T00:00:00Z').getTime();
                this.filters = this.$MultiFilters.updateFilters(this.filters, {
                    start_date: moment(val).format(),
                });
                if (!this.filters.end_date) {
                    this.filters = this.$MultiFilters.updateFilters(this.filters, {
                        end_date: moment(new Date()).format(),
                    });
                }

                this.getTransactions();
            },
            filterEndDate(val) {
                // Close the date picker.
                this.$refs.show_end_date.save(val);
                //Convert the value to a timestamp before saving it.
                //const timestamp = new Date(val + 'T00:00:00Z').getTime();
                this.filters = this.$MultiFilters.updateFilters(this.filters, {
                    end_date: moment(val).format(),
                });
                this.getTransactions();
            },
        },
        computed: {
            statusItems() {
                return [
                    { id: 2, name: 'Pendente' },
                    { id: 1, name: 'Aprovado' },
                ];
            },
            user() {
                return this.$store.getters.userLogged;
            },
        },
    };
</script>

<style scoped>
    tr.bodyFinal {
        background: #eaf0f7;
    }

    .flex.filtrosArea.xs3 {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-right: 29px;
        font-size: 19px;
        font-weight: 700;
    }
</style>
