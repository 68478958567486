<template>
    <div class="home">
        <div
            class="actionOnPage"
            :style="!$hasRole('Diwer') ? 'margin-top: 10px;' : ''"
        >
            <v-btn
                color="primary"
                @click="$router.push('/jobs/novo')"
                v-if="$hasPermission('create-jobs')"
                class="mb-4"
            >
                <v-icon>mdi-plus-circle</v-icon>
                Adicionar demanda
            </v-btn>
        </div>
        <v-row>
            <v-col sm="12">
                <div class="cardDefault partnersCard">
                    <div class="card-body">
                        <v-row>
                            <v-col sm="3">
                                <v-text-field
                                    v-model="filter.customer"
                                    label="Cliente"
                                    clearable
                                />
                            </v-col>
                            <v-col sm="3">
                                <v-text-field
                                    v-model="filter.project"
                                    label="Projeto"
                                    clearable
                                />
                            </v-col>
                            <v-col sm="3">
                                <v-text-field
                                    v-model="filter.job"
                                    label="Demanda"
                                    clearable
                                />
                            </v-col>
                            <v-col sm="3">
                                <v-text-field
                                    v-model="filter.partner"
                                    label="Parceiro"
                                    clearable
                                />
                            </v-col>
                            <v-col sm="3">
                                <v-select
                                    :items="statusItems"
                                    v-model="filter.status"
                                    label="Status"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                />
                            </v-col>
                            <v-col sm="2" class="mb-2">
                                <v-btn color="success" @click="fetchRecords">
                                    Filtrar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="
                                !$hasRole('Diwer') ? headersPartners : headers
                            "
                            :items="data"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.user`]="{ item }">
                                <span v-if="item.user">{{
                                    item.user.name
                                }}</span>
                                <v-chip v-else outlined color="orange"
                                    >Não selecionado</v-chip
                                >
                            </template>
                            <template v-slot:[`item.project`]="{ item }">{{
                                item.project.name
                            }}</template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | date }}
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip
                                    outlined
                                    :color="getStatusColor(item.status)"
                                >
                                    {{ getStatusName(item.status) }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.client`]="{ item }">{{
                                item.client.name
                            }}</template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                {{ item.start_date | date }}
                            </template>
                            <template v-slot:[`item.end_date`]="{ item }">
                                {{ item.end_date | date }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <el-button-group>
                                    <el-button
                                        size="mini"
                                        type="success"
                                        icon="el-icon-view"
                                        v-if="$hasPermission('view-jobs')"
                                        @click="
                                            $router.push(
                                                '/jobs/editar/' + item.id
                                            )
                                        "
                                    >
                                        Detalhes
                                    </el-button>
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        icon="el-icon-error"
                                        v-if="$hasPermission('delete-jobs')"
                                        @click="exclude(item.id)"
                                    >
                                        Excluir
                                    </el-button>
                                </el-button-group>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import feedback from '../../util/mixins/feedback/feedback';
import object from '../../util/mixins/object/object';
import api from '../../util/api/api';

export default {
    name: 'Jobs',
    mixins: [feedback, api, object],
    data() {
        return {
            filter: {
                project: '',
                search: '',
                customer: '',
                partner: '',
            },
            modalFinal: false,
            modalStart: false,
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Nome', value: 'name' },
                { text: 'Cliente', value: 'client' },
                { text: 'Projeto', value: 'project' },
                { text: 'Competência', value: 'created_at' },
                { text: 'Início', value: 'start_date' },
                { text: 'Fim', value: 'end_date' },
                { text: 'Parceiro', value: 'user' },
                { text: 'Status', value: 'status' },
                { text: 'Ações', value: 'actions', width: '200px' },
            ],
            headersPartners: [
                { text: 'Nome', value: 'name' },
                { text: 'Cliente', value: 'client' },
                { text: 'Projeto', value: 'project' },
                { text: 'Prazo de entrega', value: 'end_date' },
                { text: 'Status', value: 'status' },
                { text: 'Ações', value: 'actions' },
            ],
            data: [],
        };
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.fetchRecords();
    },
    methods: {
        fetchRecords() {
            let filter = {};
            if (this.filter.job) {
                filter.job = this.filter.job;
            }
            if (this.filter.customer) {
                filter.customer = this.filter.customer;
            }
            if (this.filter.project) {
                filter.project = this.filter.project;
            }
            if (this.filter.partner) {
                filter.partner = this.filter.partner;
            }
            if (this.filter.start_date) {
                filter.start_date = this.filter.start_date;
            }
            if (this.filter.end_date) {
                filter.end_date = this.filter.end_date;
            }
            if (this.filter.status) {
                filter.status = this.filter.status;
            }
            this.get('/jobs', filter).then((response) => {
                this.data = response.data.data;
            });
        },
        exclude(id) {
            if (
                this.askForConfirmation(
                    'Remover Registro',
                    'Realmente deseja remover esse registro?',
                    () => {
                        this.delete(`/jobs/${id}`).then(() => {
                            this.$store.commit('showMessage', {
                                message: 'Demanda deletada com sucesso.',
                                type: 'success',
                            });
                            this.fetchRecords();
                        });
                    }
                )
            );
        },
    },
    computed: {
        statusItems() {
            return [
                { id: 'open', name: 'Aberto' },
                { id: 'delivered', name: 'Entregue' },
                { id: 'inprogress', name: 'Em andamento' },
            ];
        },
        user() {
            return this.$store.getters.userLogged;
        },
    },
};
</script>
