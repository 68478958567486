<template>
    <div class="home">
        <div class="actionOnPage" v-if="$hasPermission('create-competences')">
            <v-btn color="primary" @click="dialog = true">
                <v-icon>mdi-plus-circle</v-icon> Adicionar competência
            </v-btn>
        </div>
        <v-row>
            <v-col sm="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    hide-details
                    solo
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <div class="cardDefault partnersCard">
                    <div class="card-body">
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :search="search"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.skills`]="{ item }">
                                <span v-if="item.skills.length > 0">
                                    <v-chip
                                        v-for="skill in item.skills"
                                        v-bind:key="skill.id"
                                        outlined
                                        class="ma-2"
                                        color="primary"
                                    >
                                        {{ skill.name }}
                                    </v-chip>
                                </span>
                                <span v-else>Nenhuma skill cadastrada</span>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | dateHour }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <el-button-group>
                                    <el-button
                                        v-if="
                                            $hasPermission('edit-competences')
                                        "
                                        size="mini"
                                        type="primary"
                                        icon="el-icon-view"
                                        @click="
                                            $router.push(
                                                `/competencias/editar/${item.id}`
                                            )
                                        "
                                    >
                                        Editar
                                    </el-button>
                                    <el-button
                                        v-if="
                                            $hasPermission('delete-competences')
                                        "
                                        size="mini"
                                        type="danger"
                                        icon="el-icon-error"
                                        @click="exclude(item.id)"
                                    >
                                        Excluir
                                    </el-button>
                                </el-button-group>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600">
            <v-card class="defaultModal">
                <v-card-title>Criar competência</v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-text-field
                            outlined
                            required
                            v-model="competence.name"
                            :rules="[!!competence.name || 'Nome é requerido']"
                            label="Nome da competência"
                        ></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="errorAlt" text @click="dialog = false">
                        Cancelar
                    </v-btn>

                    <v-btn
                        color="successAlt"
                        dark
                        depressed
                        :loading="$store.getters.loading"
                        :disabled="$store.getters.loading"
                        @click="createCompetence"
                    >
                        Salvar competência
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import feedback from '../../util/mixins/feedback/feedback';
import api from '../../util/api/api';

export default {
    name: 'Ability',
    mixins: [feedback, api],
    data() {
        return {
            especialidade: null,
            search: '',
            dialog: false,
            valid: false,
            competence: {},
            headers: [
                {
                    text: 'Competência',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Skills', value: 'skills' },
                { text: 'Criado em', value: 'created_at' },
                { text: 'Ações', value: 'actions', width: '200px' },
            ],
            data: [],
        };
    },
    computed: {
        especialidadeItems() {
            return [
                'FullStack',
                'Wordpress',
                'FrontEnd',
                'Designer',
                'Marketing',
                'Conteúdo',
            ];
        },
        ordernacao() {
            return ['Valor Hora', 'Nome', 'Cidade'];
        },
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.getData();
    },
    methods: {
        getData() {
            this.get('/competences').then((response) => {
                const {
                    data: { data },
                } = response;
                this.data = data;
            });
        },
        exclude(id) {
            if (
                this.askForConfirmation(
                    'Remover Registro',
                    'Realmente deseja remover esse registro?',
                    () => {
                        this.delete(`/competences/${id}`).then(() => {
                            this.$store.commit('showMessage', {
                                message: 'Competência deletada com sucesso.',
                                type: 'success',
                            });
                            this.getData();
                        });
                    }
                )
            );
        },
        createCompetence() {
            if (this.$refs.form.validate()) {
                this.post('/competences', this.competence).then(() => {
                    this.$store.commit('showMessage', {
                        message: 'Competência adicionada com sucesso.',
                        type: 'success',
                    });
                    this.getData();
                    this.dialog = false;
                });
            }
        },
    },
};
</script>
