<template>
    <v-row>
        <v-col sm="12">
            <h2 class="titleBox">Próximos pagamentos</h2>
            <div class="cardDefault partnersCard">
                <div class="card-body">
                    <v-row>
                        <v-col>
                            <el-button-group>
                                <el-button
                                    type="primary"
                                    @click="exportToExcel"
                                    :loading="loading.export"
                                    :disabled="!dataTransactions.length"
                                    v-if="$hasPermission('report-finance')"
                                >
                                    <span
                                        class="
                                            text-white
                                            mr-20
                                            el-icon-download
                                        "
                                    />
                                    Exportar Dados
                                </el-button>
                                <el-dropdown v-if="form.transaction_ids.length">
                                    <el-button type="info">
                                        <span
                                            class="
                                                text-white
                                                mr-20
                                                el-icon-more
                                                rotate-90
                                            "
                                        />
                                        Ações
                                        <i
                                            class="
                                                el-icon-arrow-down
                                                el-icon--right
                                            "
                                        />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            @click.native="dialog.status = true"
                                            v-if="$hasPermission('edit-status-finance')"
                                        >
                                            Alterar <b>Status</b> do(s) Items(s) Selecionado(s)
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            v-if="$hasPermission('edit-date-finance')"
                                            @click.native="dialog.payment_date = true"
                                        >
                                            Alterar Data de
                                            <b>Pagamento</b> do(s) Items(s) Selecionado(s)
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-button-group>
                        </v-col>
                    </v-row>
                    <v-row class="mb-3">
                        <v-col sm="3">
                            <v-select
                                :items="statusItems"
                                v-model="filters.status"
                                label="Status"
                                item-text="name"
                                item-value="id"
                                clearable
                            />
                        </v-col>
                        <v-col sm="3">
                            <v-select
                                :items="dateItems"
                                v-model="filters.filter_date"
                                label="Filtrar por ..."
                                item-text="name"
                                item-value="id"
                            />
                        </v-col>
                        <v-col sm="3">
                            <v-menu
                                ref="modalStart"
                                :close-on-content-click="false"
                                v-model="modalStart"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formatStartDate"
                                        clearable
                                        slot="activator"
                                        label="Data início"
                                        prepend-icon="mdi-calendar-arrow-left"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filters.start_date = null"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="filters.start_date"
                                    no-title
                                    scrollable
                                    @input="() => (modalStart = false)"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col sm="3">
                            <v-menu
                                ref="modalFinal"
                                :close-on-content-click="false"
                                v-model="modalFinal"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formatFinalDate"
                                        clearable
                                        slot="activator"
                                        label="Data Final"
                                        prepend-icon="mdi-calendar-arrow-left"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filters.end_date = null"
                                    />
                                </template>
                                <v-date-picker v-model="filters.end_date" no-title scrollable @input="() => (modalFinal = false)">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="3">
                            <v-text-field v-model="filters.partner" label="Parceiro" clearable />
                        </v-col>
                        <v-col sm="3">
                            <v-text-field v-model="filters.customer" label="Cliente" clearable />
                        </v-col>
                        <v-col sm="3">
                            <v-text-field v-model="filters.project" label="Projeto" clearable />
                        </v-col>
                        <v-col sm="3">
                            <v-text-field v-model="filters.job" label="Demanda" clearable />
                        </v-col>
                        <v-col sm="2" class="mb-2 ml-auto text-right">
                            <v-btn color="success" @click="fetchRecords">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-data-table
                        :headers="headers"
                        :items="dataTransactions"
                        :items-per-page="10"
                        :single-select="false"
                        item-key="id"
                        v-model="form.transaction_ids"
                        :show-select="
                            $hasPermission('edit-status-finance') ||
                                $hasPermission('edit-date-finance')
                        "
                        show-expand
                        :loading="$store.getters.loading"
                    >
                        <template v-slot:[`item.user`]="{ item }">
                            {{ item.user.name }} <br />
                            <strong v-if="item.user.partner">
                                {{ item.user.partner.company_name }}
                            </strong>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount | currency }}
                        </template>
                        <template v-slot:[`item.job.created_at`]="{ item }">
                            {{ item.job.created_at | date }}
                        </template>
                        <template v-slot:[`item.due_date`]="{ item }">
                            {{ item.due_date | date }}
                        </template>
                        <template v-slot:[`item.customer`]="{ item }">
                            <router-link :to="`/clientes/editar/${item.job.client.id}`">
                                {{ item.job.client.name }}
                            </router-link>
                        </template>
                        <template v-slot:[`item.project`]="{ item }">
                            <router-link :to="`/projetos/editar/${item.job.project.id}`">
                                {{ item.job.project.name }}
                            </router-link>
                        </template>
                        <template v-slot:[`item.job`]="{ item }">
                            <router-link :to="`/jobs/editar/${item.job.id}`">
                                {{ item.job.name }}
                            </router-link>
                        </template>
                        <template v-slot:[`item.observation`]="{ item }">
                            {{ item.observation.replace('Parcela ', '') }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip style="zoom: 0.8" outlined :color="item.payment_status.id === 1 ? 'successAlt' : 'grey'">
                                {{ item.payment_status.name }}
                            </v-chip>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" style="padding: 40px 10px">
                                <TransactionDetails :item="item" />
                            </td>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-col>
        <el-dialog title="Alteração de Status" :visible.sync="dialog.status">
            <div class="mb-40">
                Selecione o status que deseja definir para o(s) pagamento(s) selecionado(s)
            </div>
            <v-select
                :items="statusItems"
                v-model="form.status_id"
                label="Selecione o Status ..."
                item-text="name"
                item-value="id"
                clearable
            />
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelModalStatus"> Cancelar </el-button>
                <el-button :disabled="!form.status_id" type="primary" @click="onChangeConfirm" :loading="loading.dialog">
                    Confirmar
                </el-button>
            </span>
        </el-dialog>
        <el-dialog title="Alteração de data de pagamento" :visible.sync="dialog.payment_date">
            <div class="d-flex flex-column">
                <div class="mb-40">
                    Selecione data de pagamento que deseja definir para o(s) pagamento(s) selecionado(s)
                </div>
                <div class="d-flex justify-content-center">
                    <v-date-picker v-model="form.due_date" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelModalPayment"> Cancelar </el-button>
                <el-button :disabled="!form.due_date" type="primary" @click="onChangeConfirm" :loading="loading.dialog">
                    Confirmar
                </el-button>
            </span>
        </el-dialog>
    </v-row>
</template>

<script>
    import object from '../../util/mixins/object/object';
    import api from '../../util/api/api';
    import { map } from 'lodash';
    import moment from 'moment';
    import TransactionDetails from '../../components/transactions/TransactionDetail';
    export default {
        name: 'TransactionIndex',
        mixins: [api, object],
        created() {
            this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
            this.fetchRecords();
        },
        components: {
            TransactionDetails,
        },
        data() {
            return {
                loading: {
                    status: false,
                    export: false,
                },
                dialog: {
                    status: false,
                    payment_date: false,
                },
                data: [],
                modalStart: false,
                modalFinal: false,
                selected: [],
                form: {
                    transaction_ids: [],
                    status_id: null,
                    due_date: null,
                    partner: null,
                    job: null,
                    project: null,
                },
                filters: {
                    customer: '',
                    partner: '',
                    project: '',
                    job: '',
                    search: '',
                    status: '',
                    start_date: '',
                    end_date: '',
                    filter_date: 'created_at',
                },
                headers: [
                    { text: 'Status', value: 'status' },
                    { text: 'ID', align: 'start', value: 'id' },
                    { text: 'Total', align: 'start', value: 'amount' },
                    { text: 'Competência', value: 'job.created_at' },
                    { text: 'Pagto', value: 'due_date' },
                    { text: 'Parcela', value: 'observation', sortable: false },
                    { text: 'Parceiro', value: 'user' },
                    { text: 'Cliente', value: 'customer' },
                    { text: 'Projeto', value: 'project' },
                    { text: 'Demanda', value: 'job' },
                ],
                dataTransactions: [],
            };
        },
        methods: {
            cancelModalPayment() {
                this.form.due_date = null;
                this.dialog.payment_date = false;
            },
            cancelModalStatus() {
                this.form.status_id = null;
                this.dialog.status = false;
            },
            fetchRecords() {
                let query_filter = {};
                if (this.filters.status) {
                    query_filter.status_id = this.filters.status;
                }
                if (this.filters.start_date) {
                    query_filter.start_date = this.filters.start_date;
                }
                if (this.filters.end_date) {
                    query_filter.end_date = this.filters.end_date;
                }
                if (this.filters.filter_date) {
                    query_filter.filter_date = this.filters.filter_date;
                }
                if (this.filters.job) {
                    query_filter.job = this.filters.job;
                }
                if (this.filters.project) {
                    query_filter.project = this.filters.project;
                }
                if (this.filters.partner) {
                    query_filter.partner = this.filters.partner;
                }
                if (this.filters.customer) {
                    query_filter.customer = this.filters.customer;
                }
                if (!this.$hasRole('Diwer')) {
                    this.get(`/jobs`, {
                        user_id: this.user.id,
                    }).then(response => {
                        this.data = response.data.data;

                        this.get(`/partners/${this.user.id}/transaction`, {
                            ...query_filter,
                        }).then(response => {
                            this.dataTransactions = response.data.data;
                        });
                    });
                } else {
                    this.get(`/jobs`).then(response => {
                        this.data = response.data.data;
                        this.get(`/transactions`, {
                            ...query_filter,
                        }).then(response => {
                            this.dataTransactions = response.data.data;
                        });
                    });
                }
            },
            async onChangeConfirm() {
                this.$confirm('Deseja mesmo o(s) pagamento(s) selecionado(s) ?', 'Confirmação', {
                    type: 'warning',
                }).then(async () => {
                    this.loading.dialog = true;
                    const formData = {
                        ...this.form,
                        transaction_ids: map(this.form.transaction_ids, item => item.id),
                    };
                    const request = await this.patch('/transactions/update', formData);
                    const { status } = request;

                    if (status === 200) {
                        this.$store.commit('showMessage', {
                            message: 'Pagamento(s) atualizado com sucesso!',
                            type: 'success',
                        });
                        this.form = {
                            transaction_ids: [],
                            status_id: null,
                            due_date: null,
                        };
                        this.loading.dialog = false;
                        this.dialog.status = false;
                        this.dialog.payment_date = false;
                        this.dataTransactions = [];
                        this.fetchRecords();
                    }
                });
            },
            exportToExcel() {
                this.loading.export = true;
                import('../../util/helpers/export-excel').then(async excel => {
                    const tHeader = [
                        'Data de Vencimento',
                        'Parcela',
                        'Tipo de Parceiro/Serviço',
                        'Parceiro',
                        'Demanda',
                        'Valor',
                        'Cliente',
                        'Emissor',
                        'Forma de Pagamento',
                        'Centro de Custos (Squad)',
                        'Tipo de documento',
                        'Data Competência',
                        'Projeto',
                        'Mês Vencimento',
                        'Ano Vencimento',
                        'Mês Competência',
                        'Ano Competência',
                    ];
                    const filterVal = [
                        'due_date',
                        'observation',
                        'type',
                        'partner',
                        'job',
                        'amount',
                        'client',
                        'company_name',
                        'payment_type',
                        'cost_center',
                        'document_type',
                        'competency_date',
                        'project',
                        'due_date_month',
                        'due_date_year',
                        'competence_month',
                        'competence_year'
                    ];
                    // const formatPrice = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' }

                    const transactions = this.form.transaction_ids.length ? this.form.transaction_ids : this.dataTransactions;

                    const finalTransactions = transactions.sort((a, b) => a.user.name.localeCompare(b.user.name));
                    
                    const list = map(finalTransactions, element => {
                        const due_date = new Date(element.due_date);
                        const competence_data = new Date(element.job.created_at);
                        const competences = [...new Set(element.user.skills.map(skill => skill.competence.name))];
                        return {
                            type: `[Parceiro ${competences.join(', ')}]`,
                            // type: `[Parceiro ${element.user.skills[0].competence.name}]`,
                            competency_date: competence_data.toLocaleDateString('pt-BR', { dateStyle: 'short' }),
                            due_date: due_date.toLocaleDateString('pt-BR', {
                                dateStyle: 'short',
                            }),
                            observation: `${element.observation.replace('Parcela', '').replace('Pagamento', '')}`,
                            partner: element.user.name.toUpperCase(),
                            company_name: element.user.company_name
                                ? element.user.company_name.toUpperCase()
                                : element.user.name.toUpperCase(),
                            job: `Demanda: ${element.job.name}`,
                            amount: (element.amount * -1).toFixed(2).replace('.', ','),
                            client: element.job.client.name.toUpperCase(),
                            payment_type: 'Transferência',
                            cost_center: element.job.squad,
                            document_type: 'Nota Fiscal',
                            project: element.job.project.name,
                            due_date_month : due_date.getMonth() + 1,
                            due_date_year : due_date.getFullYear(),
                            competence_month : competence_data.getMonth() + 1,
                            competence_year : competence_data.getFullYear(),
                        };
                    });

                    const data = await this.formatJson(filterVal, list);
                    excel.export_json_to_excel({
                        header: tHeader,
                        data,
                        filename: `relatorio-pagamento-${new Date().getTime()}`,
                        autoWidth: true,
                        bookType: 'xlsx',
                    });
                    this.loading.export = false;
                });
            },

            formatJson(filterVal, jsonData) {
                return jsonData.map(v =>
                    filterVal.map(j => {
                        if (j === 'timestamp') {
                            return v[j];
                        } else {
                            return v[j];
                        }
                    })
                );
            },
            changeDate(value) {
                if (value) return moment(value).format('DD/MM/YYYY');
            },
        },
        computed: {
            dateItems() {
                return [
                    { id: 'created_at', name: 'Data de Competência' },
                    { id: 'due_date', name: 'Data de Pagamento' },
                ];
            },
            statusItems() {
                return [
                    { id: 1, name: 'Aprovado' },
                    { id: 2, name: 'Pendente' },
                ];
            },
            user() {
                return this.$store.getters.userLogged;
            },
            formatStartDate() {
                return this.filters.start_date ? moment(this.filters.start_date).format('DD/MM/YYYY') : '';
            },
            formatFinalDate() {
                return this.filters.end_date ? moment(this.filters.end_date).format('DD/MM/YYYY') : '';
            },
        },
    };
</script>
