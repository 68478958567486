<template>
    <v-app v-if="loggedIn">
        <app-header @openMenu="openSidebar" />
        <main-sidebar ref="sidebar" />

        <v-main
            class="main-content bg"
            :class="{
                'filter-sidebar-opened':
                    $vuetify.breakpoint.lgAndUp && !openMenu,
                'filter-sidebar-opened-mini':
                    $vuetify.breakpoint.lgAndUp && openMenu,
            }"
        >
            <div class="page-title-box">
                <h5>{{ titlePage }}</h5>
            </div>
            <router-view />
            <diwe-alert />
        </v-main>
    </v-app>
    <v-app v-else class="bgLogin">
        <router-view />
    </v-app>
</template>

<script>
import AppHeader from './components/header/AppHeader.vue';
import MainSidebar from './components/sidebar/MainSidebar.vue';
import DiweAlert from './components/common/DiweAlert';
import interceptor from './util/api/interceptor';

export default {
    name: 'App',
    mixins: [interceptor],
    components: { AppHeader, MainSidebar, DiweAlert },
    data() {
        return {};
    },
    head: {
        title() {
            return {
                inner: this.titlePage,
            };
        },
    },
    created() {
        this.createInterceptors();
    },
    methods: {
        openSidebar() {
            this.$refs.sidebar.open();
        },
    },
    computed: {
        loggedIn() {
            return this.$store.getters.isLoggedIn;
        },
        openMenu() {
            return this.$store.getters.isOpenMenu;
        },
        titlePage() {
            return this.$store.getters.titlePage;
        },
    },
};
</script>
