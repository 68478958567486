<template>
  <v-dialog v-model="dialog"  max-width="600" >
    <v-card class="defaultModal">
      <v-card-title>Novo Projeto</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mt-5">
            <v-col sm="12">
              <v-text-field
                outlined required
                v-model="form.name"
                :rules="[!!form.name || 'Nome é requerido']"
                label="Nome do projeto" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="errorAlt"
          text
          @click="dialog = false" >
          Cancelar
        </v-btn>
        <v-btn
          class="ma-2 buttonWhite"
          color="successAlt"
          @click="handlerCreate">
          Salvar alterações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import feedback from '../../../util/mixins/feedback/feedback';
import object from '../../../util/mixins/object/object';
import api from '../../../util/api/api';

export default {
    name: 'ProjectCreateSimplified',
    mixins: [feedback, object, api],
    data() {
        return {
            dialog: false,
            form: {
                name: ''
            },
        }
    },
    props: {
        token: {
            type: String,
            default: ''
        },
        clientId: {
            type: String,
            default: ''
        }
    },
    watch: {
        token () {
            this.dialog = true
        }
    },
    methods: {
        handlerCreate(){
            const data = {
                ...this.form,
                ...{ client_id: this.clientId}
            }
            if(this.$refs.form.validate()) {
                this.post('/projects', data).then(() => {
                    this.$store.commit('showMessage', {message: 'Projeto adicionado com sucesso.', type: 'success' });
                    this.dialog = false
                    this.$emit('on-create', true)
                });
            }
        },
    },
}
</script>
