<template>
    <v-row>
        <v-col sm="12">
            <v-tabs v-model="tab">
                <v-tab>Detalhes</v-tab>
                <v-tab v-if="$hasPermission('logs') && item.id">Logs</v-tab>
            </v-tabs>
            <template v-if="item.id && tab == 1 && $hasPermission('logs')">
                <LogsIndex feature="transactions" :feature_id="item.id" />
            </template>
            <template v-if="tab == 0">
                <div class="d-flex flex-row mb-40 mt-8">
                    <div class="d-flex flex-column col-12">
                        <div class="d-flex flex-row">
                            <div class="col-4" style="padding-left: 0">
                                <div><b>Id : </b> {{ item.id }}</div>
                                <div>
                                    <b>Status : </b>
                                    <v-chip
                                        style="zoom: 0.8"
                                        outlined
                                        :color="
                                                                                item.payment_status.id === 1
                                                                                    ? 'successAlt'
                                                                                    : 'grey'
                                                                            "
                                    >
                                        {{ item.payment_status.name }}
                                    </v-chip>
                                </div>
                                <div>
                                    <b>Parceiro : </b>
                                    {{ item.user.name }} <br />
                                    <strong v-if="item.user.partner">
                                        {{ item.user.partner.company_name }}
                                    </strong>
                                </div>
                            </div>
                            <div class="col-4" style="padding-left: 0">
                                <div>
                                    <b>Cliente : </b>
                                    {{ item.job.client.name }}
                                </div>
                                <div>
                                    <b>Projeto : </b>
                                    {{ item.job.project.name }}
                                </div>
                                <div>
                                    <b>Demanda : </b>
                                    {{ item.job.name }}
                                </div>
                            </div>
                            <div class="col-4" style="padding-left: 0">
                                <div>
                                    <b>
                                        Data de Competência :
                                    </b>
                                    {{ item.created_at | date }}
                                </div>
                                <div>
                                    <b>Data de Pagamento : </b>
                                    {{ item.due_date | date }}
                                </div>
                                <div>
                                    <b>Total : </b>
                                    {{ item.amount | currency }}
                                </div>
                                <div>
                                    <b>Parcela : </b>
                                    {{ item.observation.replace('Parcela', '') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </v-col>
    </v-row>
</template>
<script>
    import LogsIndex from '../../views/Logs/LogsIndex';

    export default {
        props: ['item'],
        components: {
            LogsIndex,
        },
        data() {
            return {
                tab: 0,
            };
        },
    };
</script>
