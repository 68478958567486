<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="home">
                    <div class="actionOnPage mb-2">
                        <v-btn color="primary" small text @click="$router.push('/parceiros')">
                            <v-icon>mdi-arrow-left</v-icon>
                            Voltar
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-tabs v-model="tab">
                    <v-tab>Visualização / Edição</v-tab>
                    <v-tab v-if="$hasPermission('logs') && id">Logs</v-tab>
                </v-tabs>
                <div class="home">
                    <template v-if="id && tab == 1 && $hasPermission('logs')">
                        <LogsIndex class="mt-5" feature="partners" :feature_id="id" />
                    </template>
                    <template v-if="tab == 0">
                        <div class="home jobsPage">
                            <loading :condition="loading">
                                <div class="mt-5 viewPartner">
                                    <div class="headerPartner">
                                        <div class="headerPartnerLeft">
                                            <v-avatar size="100" v-if="parceiro.user.image && !imageErro">
                                                <img :src="$serverURI(parceiro.user.image)" @error="imageError" />
                                            </v-avatar>
                                            <v-avatar size="100" class="gradient" v-else>
                                                <span class="white--text headline">
                                                    {{ getInitials(parceiro.user.name) }}
                                                </span>
                                            </v-avatar>
                                            <div class="photoIcon gradient">
                                                <v-icon>mdi-camera</v-icon>
                                            </div>
                                            <div>
                                                <h3>{{ parceiro.user.name }}</h3>
                                                <h4>Avaliação Diwe</h4>
                                                <v-rating
                                                    v-model="rating"
                                                    background-color="orange lighten-3"
                                                    color="orange"
                                                    small
                                                ></v-rating>
                                            </div>
                                        </div>
                                        <div class="headerPartnerRight">
                                            <span><v-icon>mdi-phone</v-icon> <b>telefone</b> : {{ parceiro.phone }}</span>
                                            <span><v-icon>mdi-email</v-icon> <b>email</b> : {{ parceiro.user.email }}</span>
                                            <span
                                                ><v-icon>mdi-earth</v-icon> <b>portfólio</b> :
                                                <a :href="parceiro.portfolio" target="_blank">{{ parceiro.portfolio }}</a></span
                                            >
                                        </div>
                                    </div>
                                    <div class="containerPartner cardDefault">
                                        <div class="card-body">
                                            <v-chip
                                                v-for="skill in parceiro.user.skills"
                                                v-bind:key="skill.id"
                                                class="ma-2"
                                                color="primary"
                                                label
                                                text-color="white"
                                            >
                                                <v-icon left>mdi-label</v-icon>
                                                {{ skill.name }}
                                            </v-chip>
                                        </div>
                                    </div>
                                </div>

                                <v-tabs v-model="activeTab" light slider-color="primary">
                                    <v-tab key="DATA"> Dados </v-tab>
                                    <v-tab-item key="DATA">
                                        <v-form ref="form" v-model="valid">
                                            <v-row class="mt-5" v-if="edit">
                                                <v-col sm="6">
                                                    <div class="cardDefault">
                                                        <div class="card-body">
                                                            <h3 class="titleDefault">
                                                                Informações do parceiro
                                                            </h3>
                                                            <file-upload-image :name="parceiro.user.name" @image="image" />
                                                            <v-text-field
                                                                outlined
                                                                required
                                                                v-model="parceiro.user.name"
                                                                :rules="[
                                                !!parceiro.user.name ||
                                                    'Nome é requerido',
                                            ]"
                                                                label="Nome completo do parceiro"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                outlined
                                                                required
                                                                v-model="parceiro.user.email"
                                                                :rules="[
                                                !!parceiro.user.email ||
                                                    'E-mail é requerido',
                                            ]"
                                                                label="E-mail do parceiro"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                outlined
                                                                required
                                                                v-model="parceiro.phone"
                                                                v-mask="getMaskByType('CELLPHONE')"
                                                                :rules="[
                                                !!parceiro.phone ||
                                                    'Telefone é requerido',
                                            ]"
                                                                label="Telefone do parceiro"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                outlined
                                                                required
                                                                prefix="R$"
                                                                suffix="/h"
                                                                v-model="parceiro.hour_value"
                                                                v-money="moneyCents"
                                                                :rules="[
                                                !!parceiro.hour_value ||
                                                    'Valor/hora é requerido',
                                            ]"
                                                                label="Valor/h do parceiro"
                                                                placeholder="30,00"
                                                            ></v-text-field>
                                                            <v-row class="no-padding">
                                                                <v-col sm="8">
                                                                    <file-upload-image
                                                                        :has-avatar="false"
                                                                        label="Contrato"
                                                                        placeholder="Selecione um contrato"
                                                                        @image="
                                                        onListenerUploadAgreement
                                                    "
                                                                    >
                                                                    </file-upload-image>
                                                                    <div
                                                                        v-if="parceiro.agreement"
                                                                        class="mt-n5 text-right"
                                                                        style="
                                                        z-index: 9999;
                                                        position: relative;
                                                    "
                                                                    >
                                                                        <a
                                                                            :href="
                                                            parceiro.agreement
                                                        "
                                                                            target="_blank"
                                                                        >
                                                                            <v-icon small>mdi-file</v-icon>
                                                                            Download Contrato
                                                                        </a>
                                                                    </div>
                                                                </v-col>
                                                                <v-col sm="4">
                                                                    <v-text-field
                                                                        :value="
                                                        changeDate(
                                                            parceiro.agreement_due_date
                                                        )
                                                    "
                                                                        prepend-inner-icon="mdi-calendar-range"
                                                                        label="Data vencimento"
                                                                        @click="
                                                        datepickerAgreement = true
                                                    "
                                                                        readonly
                                                                        outlined
                                                                    />
                                                                    <v-dialog
                                                                        v-model="
                                                        datepickerAgreement
                                                    "
                                                                        max-width="400"
                                                                    >
                                                                        <v-date-picker
                                                                            @input="selectedDate"
                                                                            :close-on-content-click="
                                                            false
                                                        "
                                                                            locale="pt-br"
                                                                            v-model="
                                                            parceiro.agreement_due_date
                                                        "
                                                                            :show-current="true"
                                                                        />
                                                                    </v-dialog>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col sm="6">
                                                    <div class="cardDefault">
                                                        <div class="card-body">
                                                            <h3 class="titleDefault">
                                                                Skills e informações Diwe
                                                            </h3>
                                                            <v-autocomplete
                                                                multiple
                                                                outlined
                                                                small
                                                                v-model="skillsSelected"
                                                                :items="skillsItems"
                                                                item-text="skill.name"
                                                                item-value="skill.id"
                                                                label="Skills"
                                                            >
                                                                <template v-slot:selection="{ item }">
                                                                    <v-chip color="primary">
                                                                        <span>{{ item.skill.name }}</span>
                                                                    </v-chip>
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    <template
                                                                        v-if="
                                                        typeof data.item !==
                                                        'object'
                                                    "
                                                                    >
                                                                        <v-list-title v-text="data.item"></v-list-title>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div>
                                                                            <div
                                                                                class="head"
                                                                                v-html="
                                                                data.item.skill
                                                                    .name
                                                            "
                                                                            ></div>
                                                                            <div
                                                                                class="
                                                                subhead
                                                                d-block
                                                            "
                                                                                v-html="
                                                                data.item
                                                                    .competence
                                                                    .name
                                                            "
                                                                            ></div>
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                            </v-autocomplete>
                                                            <v-text-field
                                                                outlined
                                                                prepend-inner-icon="mdi-earth"
                                                                v-model="parceiro.portfolio"
                                                                label="Portfólio (URL)"
                                                            ></v-text-field>

                                                            <v-text-field
                                                                outlined
                                                                required
                                                                prepend-inner-icon="mdi-calendar-range"
                                                                :value="
                                                changeDate(parceiro.date_start)
                                            "
                                                                @click="datepicker = true"
                                                                label="Parceiro desde"
                                                                placeholder="Selecione uma data"
                                                                readonly
                                                            ></v-text-field>
                                                            <app-editor
                                                                v-model="parceiro.obs"
                                                                :value="parceiro.obs"
                                                                height="100"
                                                                class="mb-12"
                                                                for-id="obs"
                                                                placeholder="Observações"
                                                            />

                                                            <v-dialog v-model="datepicker" max-width="400">
                                                                <v-date-picker
                                                                    @input="selectedDate"
                                                                    :close-on-content-click="false"
                                                                    locale="pt-br"
                                                                    v-model="parceiro.date_start"
                                                                    :show-current="true"
                                                                />
                                                            </v-dialog>
                                                        </div>
                                                        <div class="card-body">
                                                            <h3 class="titleDefault">
                                                                Dados bancários
                                                            </h3>
                                                            <v-row class="no-padding mt-2">
                                                                <v-col sm="12">
                                                                    <v-text-field
                                                                        outlined
                                                                        v-model="
                                                        parceiro.company_name
                                                    "
                                                                        label="Nome para emissão da nota"
                                                                    />
                                                                </v-col>
                                                                <v-col sm="12">
                                                                    <v-text-field
                                                                        outlined
                                                                        v-model="parceiro.cnpj"
                                                                        v-mask="
                                                        getMaskByType('CNPJ')
                                                    "
                                                                        label="CNPJ"
                                                                    />
                                                                </v-col>
                                                                <v-col sm="12">
                                                                    <app-editor
                                                                        v-model="
                                                        parceiro.bank_details
                                                    "
                                                                        :value="
                                                        parceiro.bank_details
                                                    "
                                                                        class="mb-12"
                                                                        height="120"
                                                                        for-id="bank_data"
                                                                        placeholder="Informações bancárias"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col sm="12">
                                                    <div class="cardDefault">
                                                        <div class="card-body">
                                                            <v-btn
                                                                v-if="
                                                $hasPermission('edit-partners')
                                            "
                                                                :loading="$store.getters.loading"
                                                                :disabled="$store.getters.loading"
                                                                class="ma-2 buttonWhite"
                                                                color="successAlt"
                                                                @click="savePartner"
                                                            >
                                                                Salvar alterações
                                                            </v-btn>
                                                            <v-btn
                                                                class="ma-2 buttonWhite"
                                                                color="errorAlt"
                                                                @click="$router.push('/parceiros')"
                                                            >
                                                                Cancelar
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-tab-item>
                                    <v-tab key="PAGAMENTO"> Pagamento </v-tab>
                                    <v-tab-item key="PAGAMENTO">
                                        <partners-payment :id="id" />
                                    </v-tab-item>
                                </v-tabs>
                            </loading>
                        </div>
                    </template>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    /* eslint-disable indent */

    import FileUploadImage from '../../components/common/FileUploadImage';
    import PartnersPayment from './tabs/PartnersPayment.vue';
    import object from '../../util/mixins/object/object';
    import api from '../../util/api/api';
    import Loading from '../../components/common/DiweLoading.vue';
    import AppEditor from '../../components/form/AppEditor';
    import moment from 'moment';
    import LogsIndex from '../../views/Logs/LogsIndex';

    export default {
        components: {
            FileUploadImage,
            PartnersPayment,
            Loading,
            AppEditor,
            LogsIndex,
        },
        mixins: [object, api],
        name: 'PartnersEdit',
        data() {
            return {
                tab: 0,
                valid: false,
                parceiro: {},
                phoneMask: '(##) #####-####',
                datepicker: false,
                datepickerAgreement: false,
                edit: true,
                rating: 5,
                skillsItems: [],
                skillsSelected: [],
                loading: false,
                activeTab: 0,
                id: null,
                imageErro: false,
            };
        },
        computed: {
            competencias() {
                return ['FrontEnd', 'Backend', 'FullStack', 'Designer', 'Marketing', 'Negócios'];
            },
        },
        created() {
            this.id = this.$route.params.id;
            this.getData();
            this.getSkills();
        },
        methods: {
            imageError() {
                this.imageErro = true;
            },
            changeDate(value) {
                if (value) return moment(value).format('DD/MM/YYYY');
            },
            getSkills() {
                this.get(`/skills`, {
                    grouped: true,
                }).then(response => {
                    this.skillsItems = response.data.data;
                });
            },
            getData() {
                this.loading = true;
                this.get(`/partners/${this.id}`).then(response => {
                    this.parceiro = response.data.data;
                    this.skillsSelected = this.parceiro.user.skills.map(item => item.id);
                    this.parceiro.hour_value = this.parceiro.hour_value ? this.parceiro.hour_value.toFixed(2) : 0;
                    this.$store.commit(
                        'changeTitle',
                        this.$router.currentRoute.meta.title.replace('{partner_name}', this.parceiro.user.name)
                    );
                    this.loading = false;
                });
            },
            image(image) {
                this.parceiro.user.image = image;
            },
            onListenerUploadAgreement(image) {
                this.parceiro.agreement = image;
            },
            selectedDate(valor) {
                if (valor) {
                    this.datepicker = false;
                    this.datepickerAgreement = false;
                }
            },
            savePartner() {
                if (this.$refs.form.validate()) {
                    const parceiroObject = {
                        ...this.parceiro,
                        hour_value: this.unformatMaskByType(this.parceiro.hour_value, 'MONEY'),
                        phone: this.unformatMaskByType(this.parceiro.phone, 'PHONE'),
                        name: this.parceiro.user.name,
                        email: this.parceiro.user.email,
                        image: this.parceiro.user.image,
                        portfolio: this.parceiro.portfolio,
                        obs: this.parceiro.obs,
                        date_start: this.parceiro.date_start,
                        skills: this.skillsSelected,
                    };
                    this.put(`/partners/${this.id}`, parceiroObject).then(() => {
                        this.$store.commit('showMessage', {
                            message: 'Parceiro atualizado com sucesso.',
                            type: 'success',
                        });
                        this.$router.push('/parceiros');
                    });
                }
            },
            getInitials(name) {
                let nameInitial;
                if (name) {
                    nameInitial = name
                        .replace(/[^A-Za-z0-9À-ÿ ]/gi, '')
                        .replace(/ +/gi, ' ')
                        .split(/ /)
                        .reduce((acc, item) => acc + item[0], '')
                        .concat(name.substr(1))
                        .concat(name)
                        .substr(0, 2)
                        .toUpperCase();
                }
                return nameInitial;
            },
        },
    };
</script>

<style lang="scss">
    .head {
        font-size: 15px;
    }
    .subhead {
        font-size: 13px;
        color: #ccc;
    }
    .headerPartner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #141414;
        width: 100%;
        background-image: url(../../assets/images/headerbg.svg);
        padding: 20px 100px;
        .headerPartnerLeft {
            display: flex;
            align-items: center;
            position: relative;

            .photoIcon {
                position: absolute;
                bottom: 0;
                left: 68px;
                border-radius: 100%;
                padding: 6px 8px;
                .v-icon {
                    color: #fff;
                    font-size: 19px;
                }
            }
            div {
                margin-left: 30px;
                h3 {
                    font-weight: 700;
                    color: #fff;
                    font-size: 20px;
                    margin-bottom: 0px;
                }
                h4 {
                    color: #fff;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
        .headerPartnerRight {
            span {
                display: block;
                margin-bottom: 15px;
                color: #fff;
                font-size: 14px;
                a {
                    color: #fff;
                    text-decoration: underline;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                .v-icon {
                    color: #f70a7e;
                }
            }
        }
    }
</style>
