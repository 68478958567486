<template>
    <div class="home">
        <div class="actionOnPage" v-if="$hasPermission('create-partners')">
            <v-btn color="primary" @click="$router.push('/parceiros/novo')">
                <v-icon>mdi-plus-circle</v-icon>
                Adicionar parceiro
            </v-btn>
        </div>
        <v-row class="mt-4">
            <v-col sm="3">
                <v-text-field label="Pesquise por nome" v-model="form.search" solo />
            </v-col>
            <v-col sm="3">
                <v-select
                    v-model="form.skills"
                    :items="skills"
                    item-text="name"
                    item-value="id"
                    chips
                    label="Especialidades"
                    multiple
                    solo
                    small
                    hide-details
                />
            </v-col>
            <v-col sm="3">
                <v-select
                    v-model="form.competences"
                    :items="competences"
                    item-text="name"
                    item-value="id"
                    label="Competências"
                    chips
                    multiple
                    solo
                    small
                    hide-details
                />
            </v-col>
            <v-col sm="3">
                <v-select
                    v-model="form.orderBy"
                    :items="ordination"
                    item-text="item"
                    item-value="value"
                    label="Ordenar por"
                    solo
                    small
                    hide-details
                />
            </v-col>
        </v-row>
        <loading :condition="loading">
            <v-row>
                <v-col v-for="partner in data" v-bind:key="partner.id" outlined sm="3">
                    <div class="cardDefault partnersCard">
                        <div class="card-body text-center">
                            <div class="hourJob">{{ partner.hour_value | currency }}/h</div>
                            <v-avatar size="82" v-if="partner.user.image">
                                <img :src="$serverURI(partner.user.image)" />
                            </v-avatar>
                            <v-avatar size="82" color="primary" v-else>
                                {{ getInitials(partner.user.name) }}
                            </v-avatar>
                            <h5 class="client-name">{{ partner.user.name }}</h5>
                            <div class="competence">
                                {{ getCompetenceBySkills(partner.user.skills) }}
                            </div>
                            <span
                                ><v-icon small>mdi-calendar</v-icon>
                                {{ partner.date_start | date }}</span
                            >
                            <span><v-icon small>mdi-phone</v-icon> {{ partner.phone }}</span>
                            <p class="max-length">{{ partner.obs }}</p>
                            <v-chip
                                v-for="skill in partner.user.skills.slice(0, 5)"
                                v-bind:key="skill.id"
                                class="ma-2"
                                color="primary"
                                label
                                text-color="white"
                            >
                                {{ skill.name }}
                            </v-chip>
                            <div class="footerCard">
                                <el-button-group>
                                    <el-button
                                        v-if="$hasPermission('view-partners')"
                                        type="success"
                                        icon="el-icon-view"
                                        @click="$router.push(`/parceiros/editar/${partner.id}`)"
                                    >
                                        Detalhes
                                    </el-button>
                                    <el-button
                                        v-if="$hasPermission('delete-partners')"
                                        type="danger"
                                        icon="el-icon-error"
                                        @click="exclude(partner.id)"
                                    >
                                        Excluir
                                    </el-button>
                                </el-button-group>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div class="text-center mt-6">
                <v-pagination
                    v-model="pagination.page"
                    :length="Math.ceil(pagination.total / pagination.perPage)"
                    :total-visible="8"
                    @input="changePagination"
                    prev-icon="mdi-menu-left"
                    next-icon="mdi-menu-right"
                />
            </div>
        </loading>
    </div>
</template>

<script>
import api from '../../util/api/api';
import Loading from '../../components/common/DiweLoading.vue';
import feedback from '../../util/mixins/feedback/feedback';
export default {
    name: 'Partners',
    components: { Loading },
    mixins: [api, feedback],
    data() {
        return {
            loading: false,
            imagebroken: false,
            data: [],
            skills: [],
            competences: [],
            timeout: null,
            ordination: [
                { item: 'Ordenação', value: '' },
                { item: 'Nome', value: 'name' },
                { item: 'Valor Hora', value: 'value_desc' }
            ],
            form: {
                search: '',
                skills: [],
                competences: [],
                orderBy: ''
            },
            pagination: {
                page: 1,
                perPage: 8,
                total: 0
            }
        };
    },
    head: {
        title() {
            return {
                inner: this.$router.currentRoute.meta.title
            };
        }
    },
    async created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.getData();
    },
    watch: {
        form: {
            async handler(form) {
                if (form.search.length >= 2 || form.search.length === 0) {
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        this.pagination.page = 1;
                        this.fetchRecords();
                    }, 1000);
                }
            },
            deep: true
        }
    },
    methods: {
        async getData() {
            await this.fetchRecords();
            await this.fetchSkills();
            await this.fetchCompetencies();
        },
        fetchRecords() {
            this.loading = true;
            const params = this.setQueryParams();

            this.get('/partners', params).then(response => {
                const { data } = response;

                this.data = data.data;
                this.pagination.total = data.total;
                this.loading = false;
            });
        },
        setQueryParams() {
            const { page, perPage } = this.pagination;
            const { search, skills, competences, orderBy } = this.form;
            const filterName = search ? { 'filter[name]': search } : null;
            const filterSkills = skills.length ? { 'filter[skills]': skills.join() } : null;
            const filterCompetences = competences.length
                ? { 'filter[competences]': competences.join() }
                : null;
            const filterOrderBy = orderBy ? { 'filter[orderBy]': orderBy } : null;

            return {
                page: page || 1,
                perPage: perPage,
                ...filterName,
                ...filterSkills,
                ...filterOrderBy,
                ...filterCompetences
            };
        },
        fetchSkills() {
            this.get('/skills').then(response => {
                const { data } = response;
                this.skills = data.data;
            });
        },
        fetchCompetencies() {
            this.get('/competences').then(response => {
                const { data } = response;
                this.competences = data.data;
            });
        },
        defaultImage() {
            this.imagebroken = true;
        },
        getInitials(name) {
            let nameInitial;
            if (name) {
                nameInitial = name
                    .replace(/[^A-Za-z0-9À-ÿ ]/gi, '')
                    .replace(/ +/gi, ' ')
                    .split(/ /)
                    .reduce((acc, item) => acc + item[0], '')
                    .concat(name.substr(1))
                    .concat(name)
                    .substr(0, 2)
                    .toUpperCase();
            }
            return nameInitial;
        },
        exclude(id) {
            if (
                this.askForConfirmation(
                    'Remover Registro',
                    'Realmente deseja remover esse registro?',
                    () => {
                        this.delete(`/partners/${id}`).then(() => {
                            this.$store.commit('showMessage', {
                                message: 'Parceiro deletado com sucesso.',
                                type: 'success'
                            });
                            this.getData();
                        });
                    }
                )
            );
        },
        getCompetenceBySkills(skills) {
            return skills[0].competence.name;
        },
        async changePagination(current) {
            this.pagination.page = current;
            await this.fetchRecords();
        }
    }
};
</script>

<style lang="scss" scoped>
.partnersCard {
    position: relative;
    .hourJob {
        position: absolute;
        top: 20px;
        right: 1rem;
        font-size: 0.75em;
        padding: 2px 6px;
        background: #fa9d09;
        border-radius: 4px;
        font-weight: 500;
        color: darken(#fa9d09, 30%);
    }
    .competence {
        position: absolute;
        top: 20px;
        left: 1rem;
        font-size: 0.75em;
        padding: 2px 6px;
        background: #fa9d09;
        border-radius: 4px;
        font-weight: 500;
        color: darken(#fa9d09, 30%);
    }
    span,
    p,
    h5 {
        color: #a4abc5;
        font-size: 15px;
    }
    span {
        &:first-of-type {
            margin-right: 30px;
        }
    }
    h5 {
        margin: 15px 0;
        font-weight: 700;
    }
    p {
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}
</style>
