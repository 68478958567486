import EventBus from '../../other/EventBus'

export default {
	name: 'feedback',
	methods: {
		askForConfirmation(title, text, successCallback, errorCallback, confirmText = 'Confirmar', cancelText = 'Cancelar') {
			this.$confirm(text, title, {
				confirmButtonText: confirmText,
				cancelButtonText: cancelText,
				type: 'warning'
			}).then(() => {
				successCallback()
			}).catch(() => {
				errorCallback()
			})
		},
	},
}
