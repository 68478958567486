import Vue from 'vue'

Vue.prototype.$getValue = (obj, index, fallback = "") => obj ? (obj[index] ? obj[index] : fallback) : fallback

Vue.prototype.$waitElement = (el, callback) => {
	let t = setInterval(() => {
		let els = document.querySelectorAll(el)
		if (els.length > 0) {
			clearInterval(t)
			callback(els)
		}
	}, 50)
}

Vue.prototype.$serverURI = (complement) => {
	let serverURI = process.env.VUE_APP_SERVER_RAW_URI
	return  `${serverURI}/${complement}`
}


String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}