var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"actionOnPage"},[_c('v-btn',{attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.$router.push('/clientes')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar")],1)],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v("Informações do cliente")]),_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[!!_vm.cliente.name || 'Nome é requerido'],"label":"Nome do cliente"},model:{value:(_vm.cliente.name),callback:function ($$v) {_vm.$set(_vm.cliente, "name", $$v)},expression:"cliente.name"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMaskByType('CELLPHONE')),expression:"getMaskByType('CELLPHONE')"}],attrs:{"outlined":"","required":"","rules":[
                                !!_vm.cliente.phone || 'Telefone é requerido' ],"label":"Telefone"},model:{value:(_vm.cliente.phone),callback:function ($$v) {_vm.$set(_vm.cliente, "phone", $$v)},expression:"cliente.phone"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","prepend-inner-icon":"mdi-earth","rules":[
                                !!_vm.cliente.site_url || 'Website é requerido' ],"label":"Website"},model:{value:(_vm.cliente.site_url),callback:function ($$v) {_vm.$set(_vm.cliente, "site_url", $$v)},expression:"cliente.site_url"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","prepend-inner-icon":"mdi-file-document-edit","rules":[
                                !!_vm.cliente.guide_url ||
                                    'Guia de práticas é requerido' ],"label":"Guia de boas práticas"},model:{value:(_vm.cliente.guide_url),callback:function ($$v) {_vm.$set(_vm.cliente, "guide_url", $$v)},expression:"cliente.guide_url"}})],1)])]),_c('v-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v(" Informações do responsável ")]),_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[
                                !!_vm.cliente.responsible_name ||
                                    'Nome do responsável é requerido' ],"label":"Nome do responsável"},model:{value:(_vm.cliente.responsible_name),callback:function ($$v) {_vm.$set(_vm.cliente, "responsible_name", $$v)},expression:"cliente.responsible_name"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[
                                !!_vm.cliente.responsible_email ||
                                    'E-mail do responsável é requerido' ],"label":"E-mail do responsável"},model:{value:(_vm.cliente.responsible_email),callback:function ($$v) {_vm.$set(_vm.cliente, "responsible_email", $$v)},expression:"cliente.responsible_email"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMaskByType('CELLPHONE')),expression:"getMaskByType('CELLPHONE')"}],attrs:{"outlined":"","required":"","rules":[
                                !!_vm.cliente.responsible_phone ||
                                    'Telefone do responsável é requerido' ],"label":"Telefone do responsável"},model:{value:(_vm.cliente.responsible_phone),callback:function ($$v) {_vm.$set(_vm.cliente, "responsible_phone", $$v)},expression:"cliente.responsible_phone"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Categoria de Faturamento"},model:{value:(_vm.cliente.category),callback:function ($$v) {_vm.$set(_vm.cliente, "category", $$v)},expression:"cliente.category"}})],1)])]),_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"ma-2 buttonWhite",attrs:{"loading":_vm.$store.getters.loading,"disabled":_vm.$store.getters.loading,"color":"successAlt"},on:{"click":_vm.createClient}},[_vm._v("Salvar alterações")]),_c('v-btn',{staticClass:"ma-2 buttonWhite",attrs:{"color":"errorAlt"},on:{"click":function($event){return _vm.$router.push('/clientes')}}},[_vm._v("Cancelar")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }