var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home defaultDashboard"},[_c('div',{staticClass:"box-wel"},[_c('div',{staticClass:"left-flex"},[_c('h1',[_vm._v("Bem-vindo de volta, "+_vm._s(_vm.user.name)+"!")]),(!_vm.$hasRole('Diwer'))?_c('h3',[_vm._v(" Você atualmente está participando de "),_c('b',[_vm._v(_vm._s(_vm.data.length))]),_vm._v(" demandas. ")]):_c('h3',[_vm._v(" Atualmente temos "),_c('b',[_vm._v(_vm._s(_vm.data.length))]),_vm._v(" demandas ativas. ")])]),_vm._m(0)]),(_vm.$hasPermission('last-jobs'))?_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('h2',{staticClass:"titleBox"},[_vm._v("Últimas Demandas")]),(!_vm.$hasRole('Diwer'))?_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":!_vm.$hasRole('Diwer') ? _vm.headersPartners : _vm.headers,"items":_vm.data,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.user, 'name', ' - '))+" ")]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.project, 'name', ' - '))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.client, 'name', ' - '))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.start_date))+" ")]}}],null,true)})],1)]):_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.user, 'name', ' - '))+" ")]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.project, 'name', ' - '))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getValue(item.client, 'name', ' - '))+" ")]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.start_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-view"},on:{"click":function($event){return _vm.$router.push('/jobs/editar/' + item.id)}}},[_vm._v(" Visualizar ")])]}}],null,true)})],1)])])],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-flex"},[_c('img',{attrs:{"src":require("../../assets/images/womenvector.svg")}})])}]

export { render, staticRenderFns }