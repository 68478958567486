/* eslint-disable indent */
import qs from 'qs'
import axios from 'axios'
//import fileApi from './fileApi';
//const baseUrl = 'http://diwe.local/api';
const baseUrl = process.env.VUE_APP_SERVER_URI

export default {
	name: 'api',
	methods: {
		get(url, params = null) { return axios({ method: 'GET', url: baseUrl + url, params: params, }) },
		post(url, data) { return axios({ method: 'POST', url: baseUrl + url, data }) },
		put(url, data) { return axios({ method: 'PUT', url: baseUrl + url, data }) },
		patch(url, data) { return axios({ method: 'PATCH', url: baseUrl + url, data }) },
		delete(url) { return axios({ method: 'DELETE', url: baseUrl + url }) },
		postPlain(url, data) { return axios({ method: 'POST', url: baseUrl + url, data, headers: { 'Content-Type': 'text/plain' } }) },
		postFormData(url, data) {
			return axios({
				method: 'POST', url: baseUrl + url, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, data: qs.stringify(data),
			})
		},
		postMultipart(url, formData) {
			return axios({
				method: 'POST', url: baseUrl + url, headers: { 'Content-Type': 'multipart/form-data' }, data: formData,
			})
		},
	},
}
