<template>
    <div>
        <div class="mt-3 mb-4">
            <v-btn color="primary" @click="$router.push({name : 'userCreate'})" v-if="$hasPermission('create-users')">
                <v-icon>mdi-plus-circle</v-icon>
                Adicionar Usuário
            </v-btn>
        </div>
        <v-row class="mb-3">
            <v-col sm="3">
                <v-text-field v-model="filter.name" label="Nome" clearable />
            </v-col>
            <v-col sm="3">
                <v-text-field v-model="filter.email" label="Email" clearable />
            </v-col>
            <v-col sm="3">
                <v-select
                    :items="roles.filter(x=>['Diwer','Parceiro'].includes(x.name))"
                    v-model="filter.role"
                    label="Roles"
                    item-text="name"
                    item-value="name"
                    clearable
                />
            </v-col>
            <v-col sm="3">
                <v-select
                    :items="roles.filter(x=>!['Diwer','Parceiro'].includes(x.name))"
                    v-model="filter.acl"
                    label="Grupos de Acesso"
                    item-text="name"
                    item-value="name"
                    clearable
                />
            </v-col>
            <v-col sm="2" class="mb-2 ml-auto text-right">
                <v-btn color="success" @click="fetchRecords">
                    Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="users" :items-per-page="10" item-key="id" :loading="loading">
            <template v-slot:[`item.roles`]="{ item }">
                {{ item.roles.filter(x=>['Diwer','Parceiro'].includes(x.name)).map(role => role.name).join(', ') }}
            </template>
            <template v-slot:[`item.acl`]="{ item }">
                {{ item.roles.filter(x=>!['Diwer','Parceiro'].includes(x.name)).map(role => role.name).join(', ') }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <el-button-group>
                    <el-button
                        size="mini"
                        type="success"
                        icon="el-icon-view"
                        v-if="$hasPermission('edit-users')"
                        @click="$router.push({ name: 'userEdit', params: { id: item.id } })"
                    >
                        Detalhes
                    </el-button>
                </el-button-group>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import feedback from '../../../util/mixins/feedback/feedback';
    import api from '../../../util/api/api';
    export default {
        mixins: [feedback, api],
        data() {
            return {
                loading: true,
                users: [],
                roles: [],
                headers: [
                    { text: 'ID', align: 'start', value: 'id' },
                    { text: 'Nome', align: 'start', value: 'name' },
                    { text: 'Email', align: 'start', value: 'email' },
                    { text: 'Roles', align: 'start', value: 'roles' },
                    { text: 'Grupos de Acesso', align: 'start', value: 'acl' },
                    { text: 'Ações', value: 'actions', width: '200px' },
                ],
                filter: {
                    name: '',
                    email: '',
                    role: '',
                    acl: '',
                },
            };
        },
        created() {
            Promise.all([this.fetchUsers(), this.fetchRoles()]).then(() => {
                this.loading = false;
            });
        },
        methods: {
            async fetchRoles() {
                let { data } = await this.get('/acl/roles');
                this.roles = data;
            },
            async fetchUsers() {
                let { data } = await this.get('/user-list', this.filter);
                this.users = data;
            },
            async fetchRecords() {
                this.loading = true;
                await this.fetchUsers();
                this.loading = false;
            },
        },
    };
</script>
