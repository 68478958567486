import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"
import Home from '../views/Home/Home.vue'

import Login from '../views/Login/Login.vue'
import ForgetMyPassword from '../views/Login/ForgetMyPassword.vue'

// import Register from '../views/Login/Register.vue'

import Partners from '../views/Partners/Partners.vue'
import PartnersCreate from '../views/Partners/PartnersCreate.vue'
import PartnersEdit from '../views/Partners/PartnersEdit.vue'

import Competences from '../views/Competences/Competences.vue'
import CompetencesEdit from '../views/Competences/CompetencesEdit.vue'

import Clients from '../views/Clients/Clients.vue'
import ClientsCreate from '../views/Clients/ClientsCreate.vue'
import ClientsEdit from '../views/Clients/ClientsEdit.vue'

import Projects from '../views/Projects/Projects.vue'
import ProjectsCreate from '../views/Projects/ProjectsCreate.vue'
import ProjectsEdit from '../views/Projects/ProjectsEdit.vue'

import Jobs from '../views/Jobs/Jobs.vue'
import JobsCreate from '../views/Jobs/JobsCreate.vue'
import JobsEdit from '../views/Jobs/JobsEdit.vue'

import TransactionIndex from '../views/Finance/TransactionIndex'

import LogsIndex from '../views/Logs/LogsIndex'
import SettingsIndex from '../views/Settings/SettingsIndex'


import UserEdit from '../views/Users/UserEdit'
import UserCreate from '../views/Users/UserCreate'

Vue.use(VueRouter)

const routes = [
	{
		path: '/', name: 'Home', component: Home, meta: {
			title: {
				diwer: "Dashboard Diwe",
				partner: "Dashboard do Parceiro"
			}
		}
	},
	{ path: '/login', name: 'Login', component: Login, meta: { title: "Login" } },
	{ path: '/esqueci-a-senha', name: 'ForgetMyPassword', component: ForgetMyPassword, meta: { title: "Esqueci a senha" } },
	// { path: '/cadastro', name: 'Cadastro', component: Register, meta: { title: "Cadastro" } },
	{ path: '/parceiros', name: 'Parceiros', component: Partners, meta: { title: "Parceiros", acl: "partners" } },
	{ path: '/parceiros/novo', name: 'ParceirosNovo', component: PartnersCreate, meta: { title: "Adicionar parceiro", acl: "create-partners" } },
	{ path: '/parceiros/editar/:id', name: 'ParceirosEditar', component: PartnersEdit, meta: { title: "Visualizando o parceiro {partner_name}", acl: "view-partners" } },

	{ path: '/competencias', name: 'Competencias', component: Competences, meta: { title: "Competências", acl: "competences" } },
	{ path: '/competencias/editar/:id', name: 'CompetenciasEditar', component: CompetencesEdit, meta: { title: "Editando a competência {competence_name}", acl: "edit-competences" } },

	{ path: '/clientes', name: 'Clientes', component: Clients, meta: { title: "Clientes", acl: "customers" } },
	{ path: '/clientes/novo', name: 'ClientesNovo', component: ClientsCreate, meta: { title: "Adicionar cliente", acl: "create-customers" } },
	{ path: '/clientes/editar/:id', name: 'ClientesEditar', component: ClientsEdit, meta: { title: "Editando o cliente {client_name}", acl: "edit-customers" } },

	{ path: '/projetos', name: 'Projetos', component: Projects, meta: { title: "Projetos", acl: "projects" } },
	{ path: '/projetos/novo', name: 'ProjetosNovo', component: ProjectsCreate, meta: { title: "Adicionar projeto", acl: "create-projects" } },
	{ path: '/projetos/editar/:id', name: 'ProjetosEditar', component: ProjectsEdit, meta: { title: "Editando o projeto {project_name}", acl: "view-projects" } },

	{ path: '/jobs', name: 'Jobs', component: Jobs, meta: { title: "Demandas", acl: "jobs" } },
	{ path: '/jobs/novo', name: 'JobsNovo', component: JobsCreate, meta: { title: "Adicionar demanda", acl: "create-jobs" } },
	{ path: '/jobs/editar/:id', name: 'JobsEditar', component: JobsEdit, meta: { title: "Editando a demanda {job_name}", acl: "view-jobs" } },
	{
		path: '/financeiro', name: 'finance.index', component: TransactionIndex, meta: { title: "Financeiro", acl: "finance" }
	},
	{
		path: '/logs', name: 'logs.index', component: LogsIndex, meta: { title: "Logs de Sistema", acl: "logs" }
	},

]

if(Array.isArray(store.state.userLogged.roles)) {
	if (store.state.userLogged.permissions.includes("acl") || store.state.userLogged.permissions.includes("users")) {		
		routes.push({
			path: '/configuracoes', name: 'settings.index', component: SettingsIndex, meta: { title: "Configurações de Sistema", acl: ["acl","users"] }
		})
	}
	
	if (store.state.userLogged.permissions.includes("edit-users")) {		
		routes.push({
			path: '/configuracoes/usuarios/editar/:id', name: 'userEdit', component: UserEdit, meta: { title: "Edição de Usuário", acl: ["edit-users"] }
		})
	}
	
	if (store.state.userLogged.permissions.includes("create-users")) {		
		routes.push({
			path: '/configuracoes/usuarios/novo', name: 'userCreate', component: UserCreate, meta: { title: "Cadastro de Usuário", acl: ["create-users"] }
		})
	}
}

const router = new VueRouter({
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/cadastro', '/esqueci-a-senha']
	const authRequired = !publicPages.includes(to.path)
	const loggedIn = store.getters.isLoggedIn
	// try to access a restricted page + not logged in
	const logout = () => {
		store.dispatch("logout")
		return next('/login')
	}
	if (authRequired && !loggedIn) {
		return logout()
	}
	if (to.meta.acl) {
		if (!store.getters[typeof to.meta.acl == "string" ? 'hasPermission' : 'hasPermissions'](to.meta.acl)) {
			return logout()
		}
	}
	next()
})



export default router
