import Vue from 'vue';

function convertToCurrency(value) {
    if (value === null || value === undefined || Number.isNaN(value)) return '';
    const val = (value / 1).toFixed(2).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

Vue.filter('currency', (value, symbol = 'R$ ') => symbol + convertToCurrency(value));

Vue.filter('prettyCurrency', (value, symbol = 'R$ ') => {
    const split = convertToCurrency(value).split(',');
    return `<span class="currency-symbol">${symbol}</span> ${split[0]}<span class="value-decimals">${split[1]}</span>`;
});
