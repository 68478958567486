import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
        themes: {
            light: {
                // 00D5FF
                primary: '#f70a7e', // colors.lightBlue.lighten2, // #00D5FF -- 01579B
                accent: '#a4abc5', // colors.deepOrange.darken1, // #F4511E -- 512DA8
                successAlt: '#4DB6AC', // colors.teal.lighten2
                errorAlt: '#EA5352',
                bg: '#eaf0f7',
                publicBg: '#FAFAFA',
                sidebarBg: '#FFF',
            },
        },
    },
});
