<template>
    <v-navigation-drawer v-model="opened" app clipped permanent class="sidebarBg" width="220px" :mini-variant="openMenu">
        <v-list dense>
            <v-list-item
                v-for="pageLink in itemList"
                :key="pageLink.title"
                :class="{
                    'v-item--active v-list-item--active': isPageActive(
                        pageLink.activeNames
                    ),
                }"
                @click="goToLink(pageLink.url)"
            >
                <v-list-item-icon>
                    <v-icon>{{ 'mdi-' + pageLink.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title v-text="pageLink.title" />
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: 'MainSidebar',
        data() {
            return {
                opened: null,
                mini: false,
            };
        },
        computed: {
            itemList() {
                const list = [];
                if (!this.$hasRole('Diwer')) {
                    list.push({
                        title: 'Dashboard do Parceiro',
                        url: '/',
                        icon: 'home',
                        activeNames: ['Home'],
                        funcID: 1,
                    });
                    if (this.$hasPermission('jobs')) {
                        list.push({
                            title: 'Demandas',
                            url: '/jobs',
                            icon: 'alarm-light',
                            activeNames: ['Jobs', 'JobsNovo', 'JobsEditar'],
                            funcID: 4,
                        });
                    }
                } else {
                    list.push({
                        title: 'Dashboard Diwe',
                        url: '/',
                        icon: 'home',
                        activeNames: ['Home'],
                        funcID: 1,
                    });
                    if (this.$hasPermission('partners')) {
                        list.push({
                            title: 'Parceiros',
                            url: '/parceiros',
                            icon: 'handshake',
                            activeNames: ['Parceiros', 'ParceirosNovo', 'ParceirosEditar'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('competences')) {
                        list.push({
                            title: 'Competências',
                            url: '/competencias',
                            icon: 'account-cog',
                            activeNames: ['Competencias', 'CompetenciasEditar'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('customers')) {
                        list.push({
                            title: 'Clientes',
                            url: '/clientes',
                            icon: 'account-tie',
                            activeNames: ['Clientes', 'ClientesNovo', 'ClientesEditar'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('projects')) {
                        list.push({
                            title: 'Projetos',
                            url: '/projetos',
                            icon: 'folder-account',
                            activeNames: ['Projetos', 'ProjetosNovo', 'ProjetosEditar'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('jobs')) {
                        list.push({
                            title: 'Demandas',
                            url: '/jobs',
                            icon: 'alarm-light',
                            activeNames: ['Jobs', 'JobsNovo', 'JobsEditar'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('finance')) {
                        list.push({
                            title: 'Financeiro',
                            url: '/financeiro',
                            icon: 'cash-plus',
                            activeNames: ['Financeiro'],
                            funcID: 4,
                        });
                    }
                    if (this.$hasPermission('logs')) {
                        list.push({
                            title: 'Logs',
                            url: '/logs',
                            icon: 'animation-play-outline',
                            activeNames: ['Logs'],
                            funcID: 4,
                        });
                    }
                }
                if (this.$hasPermission('acl') || this.$hasPermission('users')) {
                    list.push({
                        title: 'Configurações',
                        url: '/configuracoes',
                        icon: 'cog',
                        activeNames: ['settings.index', 'userEdit', 'userCreate'],
                        funcID: 4,
                    });
                }
                return list;
            },
            openMenu() {
                return this.$store.getters.isOpenMenu;
            },
            user() {
                return this.$store.getters.userLogged;
            },
        },
        methods: {
            open() {
                this.$store.commit('openMenu', !this.openMenu);
            },
            goToLink(link) {
                this.$router.push(link);
            },
            isPageActive(activeNames) {
                return activeNames && activeNames.includes(this.$route.name);
            },
        },
    };
</script>

<style scoped lang="scss">
    .no-profile {
        background-color: #006a80;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        padding-top: 6px;

        span {
            font-size: 24px;
            color: white;
        }
    }
</style>
