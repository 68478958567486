var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"actionOnPage"},[_c('v-btn',{attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.$router.push('/parceiros')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Voltar")],1)],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v(" Informações do parceiro ")]),_c('file-upload-image',{attrs:{"name":_vm.parceiro.name},on:{"image":_vm.image}}),_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[!!_vm.parceiro.name || 'Nome é requerido'],"label":"Nome completo do parceiro"},model:{value:(_vm.parceiro.name),callback:function ($$v) {_vm.$set(_vm.parceiro, "name", $$v)},expression:"parceiro.name"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[
                                !!_vm.parceiro.email || 'E-mail é requerido' ],"label":"E-mail do parceiro"},model:{value:(_vm.parceiro.email),callback:function ($$v) {_vm.$set(_vm.parceiro, "email", $$v)},expression:"parceiro.email"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMaskByType('CELLPHONE')),expression:"getMaskByType('CELLPHONE')"}],attrs:{"outlined":"","required":"","rules":[
                                !!_vm.parceiro.phone || 'Telefone é requerido' ],"label":"Telefone do parceiro"},model:{value:(_vm.parceiro.phone),callback:function ($$v) {_vm.$set(_vm.parceiro, "phone", $$v)},expression:"parceiro.phone"}}),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"outlined":"","required":"","prefix":"R$","suffix":"/h","rules":[
                                !!_vm.parceiro.hour_value ||
                                    'Valor/hora é requerido' ],"label":"Valor/h do parceiro","placeholder":"30,00"},model:{value:(_vm.parceiro.hour_value),callback:function ($$v) {_vm.$set(_vm.parceiro, "hour_value", $$v)},expression:"parceiro.hour_value"}}),_c('v-text-field',{attrs:{"append-icon":_vm.typePassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Senha","type":_vm.typePassword ? 'password' : 'text',"required":"","outlined":"","rules":[
                                function () { return !!_vm.parceiro.password ||
                                    'O campo é obrigatório'; } ]},on:{"click:append":function () { return (_vm.typePassword = !_vm.typePassword); }},model:{value:(_vm.parceiro.password),callback:function ($$v) {_vm.$set(_vm.parceiro, "password", $$v)},expression:"parceiro.password"}}),_c('v-row',{staticClass:"no-padding"},[_c('v-col',{attrs:{"sm":"8"}},[_c('file-upload-image',{attrs:{"has-avatar":false,"label":"Contrato","placeholder":"Selecione um contrato"},on:{"image":_vm.onListenerUploadAgreement}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-text-field',{attrs:{"value":_vm.changeDate(
                                            _vm.parceiro.agreement_due_date
                                        ),"prepend-inner-icon":"mdi-calendar-range","label":"Data vencimento","readonly":"","outlined":""},on:{"click":function($event){_vm.datepickerAgreement = true}}}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.datepickerAgreement),callback:function ($$v) {_vm.datepickerAgreement=$$v},expression:"datepickerAgreement"}},[_c('v-date-picker',{attrs:{"close-on-content-click":false,"locale":"pt-br","show-current":true},on:{"input":_vm.selectedDate},model:{value:(
                                            _vm.parceiro.agreement_due_date
                                        ),callback:function ($$v) {_vm.$set(_vm.parceiro, "agreement_due_date", $$v)},expression:"\n                                            parceiro.agreement_due_date\n                                        "}})],1)],1)],1)],1)]),_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"ma-2 buttonWhite",attrs:{"color":"successAlt"},on:{"click":_vm.createPartner}},[_vm._v("Cadastrar parceiro")]),_c('v-btn',{staticClass:"ma-2 buttonWhite",attrs:{"color":"errorAlt"},on:{"click":function($event){return _vm.$router.push('/parceiros')}}},[_vm._v("Cancelar")])],1)]),_c('v-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v(" Skills e informações Diwe ")]),_c('v-autocomplete',{attrs:{"multiple":"","outlined":"","small":"","items":_vm.skillsItems,"item-text":"skill.name","item-value":"skill.id","label":"Skills","rules":[
                                function () { return !!_vm.parceiro.skills ||
                                    'Skills é obrigatório'; } ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.skill.name))])])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-title',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('div',[_c('div',{staticClass:"head",domProps:{"innerHTML":_vm._s(data.item.skill.name)}}),_c('div',{staticClass:"subhead d-block",domProps:{"innerHTML":_vm._s(
                                                data.item.competence.name
                                            )}})])]]}}]),model:{value:(_vm.parceiro.skills),callback:function ($$v) {_vm.$set(_vm.parceiro, "skills", $$v)},expression:"parceiro.skills"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","prepend-inner-icon":"mdi-earth","label":"Portfólio (URL)"},model:{value:(_vm.parceiro.portfolio),callback:function ($$v) {_vm.$set(_vm.parceiro, "portfolio", $$v)},expression:"parceiro.portfolio"}}),_c('v-text-field',{attrs:{"outlined":"","required":"","prepend-inner-icon":"mdi-calendar-range","value":_vm.changeDate(_vm.parceiro.date_start),"label":"Parceiro desde","placeholder":"Selecione uma data","readonly":"","rules":[
                                !!_vm.parceiro.date_start ||
                                    'Parceiro desde é requerido' ]},on:{"click":function($event){_vm.datepicker = true}}}),_c('app-editor',{staticClass:"mb-12",attrs:{"value":_vm.parceiro.obs,"height":"80","for-id":"obs","placeholder":"Observações"},model:{value:(_vm.parceiro.obs),callback:function ($$v) {_vm.$set(_vm.parceiro, "obs", $$v)},expression:"parceiro.obs"}}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-date-picker',{attrs:{"close-on-content-click":false,"locale":"pt-br","show-current":true},on:{"input":_vm.selectedDate},model:{value:(_vm.parceiro.date_start),callback:function ($$v) {_vm.$set(_vm.parceiro, "date_start", $$v)},expression:"parceiro.date_start"}})],1)],1),_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v("Dados bancários")]),_c('v-row',{staticClass:"no-padding mt-2"},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nome para emissão da nota"},model:{value:(_vm.parceiro.company_name),callback:function ($$v) {_vm.$set(_vm.parceiro, "company_name", $$v)},expression:"parceiro.company_name"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getMaskByType('CNPJ')),expression:"getMaskByType('CNPJ')"}],attrs:{"outlined":"","label":"CNPJ"},model:{value:(_vm.parceiro.cnpj),callback:function ($$v) {_vm.$set(_vm.parceiro, "cnpj", $$v)},expression:"parceiro.cnpj"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('app-editor',{staticClass:"mb-12",attrs:{"value":_vm.parceiro.bank_details,"height":"80","for-id":"bank_data","placeholder":"Informações bancárias"},model:{value:(_vm.parceiro.bank_details),callback:function ($$v) {_vm.$set(_vm.parceiro, "bank_details", $$v)},expression:"parceiro.bank_details"}})],1)],1)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }