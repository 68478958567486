<template>
    <div class="home">
        <div class="actionOnPage">
            <v-btn color="primary" small text @click="$router.push('/projetos')"
                ><v-icon>mdi-arrow-left</v-icon> Voltar</v-btn
            >
        </div>
        <v-form ref="form" v-model="valid">
            <v-row class="mt-5">
                <v-col sm="4">
                    <div class="cardDefault">
                        <div class="card-body">
                            <h3 class="titleDefault">Informações do projeto</h3>
                            <v-text-field
                                outlined
                                required
                                v-model="projeto.name"
                                :rules="[!!projeto.name || 'Nome é requerido']"
                                label="Nome do projeto"
                            ></v-text-field>
                            <v-select
                                outlined
                                required
                                v-model="projeto.client_id"
                                :rules="[
                                    !!projeto.client_id ||
                                        'Cliente é requerido',
                                ]"
                                label="Cliente"
                                :items="clientsItems"
                                item-text="name"
                                item-value="id"
                            ></v-select>
                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-calendar-range"
                                :value="changeDate(projeto.start_date)"
                                @click="showPicker = true"
                                label="Data de início"
                                placeholder="Selecione uma data"
                                readonly
                            ></v-text-field>
                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-calendar-range"
                                :value="changeDate(projeto.end_date)"
                                @click="showPickerDois = true"
                                label="Data de entrega"
                                placeholder="Selecione uma data"
                                readonly
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
                <v-col sm="4">
                    <div class="cardDefault">
                        <div class="card-body">
                            <h3 class="titleDefault">Links do cliente</h3>
                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-clipboard-check"
                                v-model="projeto.board"
                                label="Board (InVision)"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-google-drive"
                                v-model="projeto.drive_folder"
                                label="Pasta do Google Drive"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
                <v-col sm="12">
                    <div class="mt-5">
                        <v-btn
                            class="ma-2 buttonWhite"
                            color="successAlt"
                            @click="createProject"
                            >Salvar alterações</v-btn
                        >
                        <v-btn
                            class="ma-2 buttonWhite"
                            color="errorAlt"
                            @click="$router.push('/projetos')"
                            >Cancelar</v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="showPicker" max-width="400">
            <v-date-picker
                @input="selectedDate"
                locale="pt-br"
                v-model="projeto.start_date"
                :show-current="true"
            ></v-date-picker>
        </v-dialog>
        <v-dialog v-model="showPickerDois" max-width="400">
            <v-date-picker
                :min="projeto.start_date"
                @input="selectedDate"
                locale="pt-br"
                v-model="projeto.end_date"
                :show-current="true"
            ></v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import feedback from '../../util/mixins/feedback/feedback';
import object from '../../util/mixins/object/object';
import api from '../../util/api/api';
import moment from 'moment';

export default {
    name: 'ProjectsCreate',
    mixins: [feedback, object, api],
    data() {
        return {
            editar: false,
            projeto: {},
            valid: false,
            clientsItems: [],
            showPicker: false,
            showPickerDois: false,
        };
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.get('/clients').then((response) => {
            this.clientsItems = response.data.data;
        });
    },
    methods: {
        changeDate(value) {
            if (value) return moment(value).format('DD/MM/YYYY');
        },
        createProject() {
            if (this.$refs.form.validate()) {
                const projectObject = {
                    ...this.projeto,
                };
                this.post('/projects', projectObject).then(() => {
                    this.$store.commit('showMessage', {
                        message: 'Projeto adicionado com sucesso.',
                        type: 'success',
                    });
                    this.$router.push('/projetos');
                });
            }
        },
        selectedDate(valor) {
            if (valor) {
                this.showPicker = false;
                this.showPickerDois = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
