<template>
    <div class="mt-5">
        <div class="cardDefault">
            <div class="card-body">
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :items-per-page="10"
                    :loading="$store.getters.loading"
                >
                    <template v-slot:[`item.amount`]="{ item }">
                        {{ item.amount | currency }}
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                        {{ item.date | date }}
                    </template>
                    <template v-slot:[`item.job`]="{ item }">
                        {{ item.job.name }} - {{ item.job.client.name }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            outlined
                            :color="
                                item.payment_status.id === 1
                                    ? 'successAlt'
                                    : 'grey'
                            "
                        >
                            {{ item.payment_status.name }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.observation`]="{ item }">
                        {{ item.observation }}
                    </template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>

<script>
import object from '../../../util/mixins/object/object';
import feedback from '../../../util/mixins/feedback/feedback';
import api from '../../../util/api/api';
import moment from 'moment';

export default {
    name: 'PartnersPayment',
    mixins: [object, api, feedback],
    props: {
        id: { type: String, required: true },
    },
    data() {
        return {
            transactions: [],
            dialog: false,
            pagamentoValid: false,
            pagamento: {},
            showPicker: false,
            edit: false,
            headers: [
                { text: 'ID', align: 'start', value: 'id' },
                { text: 'Quantidade', align: 'start', value: 'amount' },
                { text: 'Data', value: 'date' },
                { text: 'Observação', value: 'observation' },
                { text: 'Status', value: 'status', width: '30%' },
                { text: 'Demanda / Cliente', value: 'job' },
            ],
        };
    },
    created() {
        this.getTransactions();
    },
    methods: {
        alterarStatus() {
            this.dialog = true;
        },
        getTransactions() {
            this.get(`/partners/${this.id}/transaction`).then((response) => {
                this.transactions = response.data.data;
            });
        },
        changeDate(value) {
            if (value) return moment(value).format('DD/MM/YYYY');
        },
    },
    computed: {
        statusItems() {
            return [
                { id: 2, name: 'Pendente' },
                { id: 1, name: 'Aprovado' },
            ];
        },
    },
};
</script>
