<template>
    <div class="home">
        <div class="actionOnPage" v-if="$hasPermission('create-projects')">
            <v-btn color="primary" @click="$router.push('/projetos/novo')">
                <v-icon>mdi-plus-circle</v-icon>
                Adicionar projeto
            </v-btn>
        </div>
        <v-row>
            <v-col sm="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    hide-details
                    solo
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <div class="cardDefault partnersCard">
                    <div class="card-body">
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :search="search"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.client`]="{ item }">{{
                                item.client.name
                            }}</template>
                            <template v-slot:[`item.board`]="{ item }">
                                <span v-if="validUrl(item.board)">
                                    <a :href="item.board" target="_blank">{{
                                        item.board.slice(0, 20) + '...'
                                    }}</a>
                                </span>
                                <span v-else>{{ item.board }}</span>
                            </template>
                            <template v-slot:[`item.drive_folder`]="{ item }">
                                <span v-if="validUrl(item.drive_folder)">
                                    <a
                                        :href="item.drive_folder"
                                        target="_blank"
                                        >{{
                                            item.drive_folder.slice(0, 20) +
                                            '...'
                                        }}</a
                                    >
                                </span>
                                <span v-else>{{ item.drive_folder }}</span>
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                {{ item.start_date | date }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <el-button-group>
                                    <el-button
                                        size="mini"
                                        type="success"
                                        icon="el-icon-view"
                                        v-if="$hasPermission('view-projects')"
                                        @click="
                                            $router.push(
                                                '/projetos/editar/' + item.id
                                            )
                                        "
                                    >
                                        Detalhes
                                    </el-button>
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        icon="el-icon-error"
                                        @click="exclude(item.id)"
                                        v-if="$hasPermission('delete-projects')"
                                    >
                                        Excluir
                                    </el-button>
                                </el-button-group>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import feedback from '../../util/mixins/feedback/feedback';
import api from '../../util/api/api';

export default {
    name: 'Projects',
    mixins: [feedback, api],
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                {
                    text: 'Nome',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Cliente', value: 'client' },
                { text: 'Data de início', value: 'start_date' },
                { text: 'Board', value: 'board' },
                { text: 'Drive', value: 'drive_folder' },
                { text: 'Ações', value: 'actions', width: '200px' },
            ],
            data: [],
        };
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.getData();
    },
    methods: {
        getData() {
            this.get('/projects').then((response) => {
                this.data = response.data.data;
            });
        },
        validUrl(string) {
            if (string == '#') {
                return false;
            }
            try {
                new URL(string);
            } catch (_) {
                return false;
            }

            return true;
        },
        exclude(id) {
            if (
                this.askForConfirmation(
                    'Remover Registro',
                    'Realmente deseja remover esse registro?',
                    () => {
                        this.delete(`/projects/${id}`).then(() => {
                            this.$store.commit('showMessage', {
                                message: 'Projeto deletado com sucesso.',
                                type: 'success',
                            });
                            this.getData();
                        });
                    }
                )
            );
        },
    },
};
</script>