var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.$hasPermission('create-competences'))?_c('div',{staticClass:"actionOnPage"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" Adicionar competência ")],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.skills",fn:function(ref){
var item = ref.item;
return [(item.skills.length > 0)?_c('span',_vm._l((item.skills),function(skill){return _c('v-chip',{key:skill.id,staticClass:"ma-2",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(skill.name)+" ")])}),1):_c('span',[_vm._v("Nenhuma skill cadastrada")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateHour")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('el-button-group',[(
                                        _vm.$hasPermission('edit-competences')
                                    )?_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-view"},on:{"click":function($event){return _vm.$router.push(
                                            ("/competencias/editar/" + (item.id))
                                        )}}},[_vm._v(" Editar ")]):_vm._e(),(
                                        _vm.$hasPermission('delete-competences')
                                    )?_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-error"},on:{"click":function($event){return _vm.exclude(item.id)}}},[_vm._v(" Excluir ")]):_vm._e()],1)]}}],null,true)})],1)])])],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"defaultModal"},[_c('v-card-title',[_vm._v("Criar competência")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","required":"","rules":[!!_vm.competence.name || 'Nome é requerido'],"label":"Nome da competência"},model:{value:(_vm.competence.name),callback:function ($$v) {_vm.$set(_vm.competence, "name", $$v)},expression:"competence.name"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"errorAlt","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"successAlt","dark":"","depressed":"","loading":_vm.$store.getters.loading,"disabled":_vm.$store.getters.loading},on:{"click":_vm.createCompetence}},[_vm._v(" Salvar competência ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }