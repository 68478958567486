<template>
    <div class="home">
        <div class="actionOnPage">
            <v-btn color="primary" small text @click="$router.push('/configuracoes')">
                <v-icon>mdi-arrow-left</v-icon>
                Voltar
            </v-btn>
        </div>
        <v-form ref="form" v-model="valid">
            <loading :condition="loading">
                <v-row class="mt-5">
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Informações do usuário
                                </h3>
                                <v-text-field
                                    outlined
                                    required
                                    v-model="user.name"
                                    :rules="[
                                        !!user.name || 'Nome é requerido',
                                    ]"
                                    label="Nome do Usuário"
                                />
                                <v-text-field
                                    outlined
                                    required
                                    v-model="user.email"
                                    :rules="[
                                        !!user.email || 'Email é requerido',
                                    ]"
                                    label="Email do Usuário"
                                />

                                <v-text-field
                                    type="password"
                                    outlined
                                    required
                                    v-model="user.password"
                                    :rules="[
                                        !!user.password || 'Senha é requerida',
                                    ]"
                                    label="Senha do Usuário"
                                />

                                <v-text-field
                                    type="password"
                                    outlined
                                    required
                                    v-model="user.confirm_password"
                                    :rules="[
                                        !user.confirm_password ? 'Confirmação de senha é requerida' : ((user.password === user.confirm_password) || 'Senha não confere')]"
                                    label="Confirmação de senha"
                                />

                                <small class="text-muted">
                                    Esta senha é temporária, será alterada após o primeiro login.
                                </small>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Grupos de Acesso
                                </h3>
                                <div class="role-list">
                                    <div
                                        :class="`role-item ${ hasRole(role) ? 'active' : 'not-active'}`"
                                        v-for="(role,i) in roles.filter(x=>!['Administrador de Sistema','Diwer','Parceiro'].includes(x.name))"
                                        :key="i"
                                        @click="toggleRole(role)"
                                    >
                                        {{ role.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Roles
                                </h3>
                                <div class="role-list disabled">
                                    <div
                                        :class="`role-item ${ hasRole(role) ? 'active' : 'not-active'}`"
                                        v-for="(role,i) in roles.filter(x=>['Diwer','Parceiro'].includes(x.name))"
                                        :key="i"
                                        @click="toggleRole(role)"
                                    >
                                        {{ role.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="12">
                        <div class="mt-5">
                            <v-btn
                                :loading="btn_loading"
                                :disabled="btn_loading"
                                class="ma-2 buttonWhite"
                                color="successAlt"
                                @click="create"
                            >
                                Salvar alterações
                            </v-btn>
                            <v-btn class="ma-2 buttonWhite" color="errorAlt" @click="$router.push('/configuracoes')">
                                Cancelar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </loading>
        </v-form>
    </div>
</template>
<script>
    import Loading from '../../components/common/DiweLoading.vue';
    import api from '../../util/api/api';

    export default {
        mixins: [api],
        components: {
            Loading,
        },
        data() {
            return {
                loading: true,
                valid: false,
                btn_loading: false,
                user: {
                    name: '',
                    email: '',
                    password: '',
                    confirm_password: '',
                    f_roles: ['Diwer'],
                },
                roles: [],
            };
        },
        created() {
            this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
            Promise.all([this.fetchRoles()]).then(() => {
                this.loading = false;
            });
        },
        methods: {
            toggleRole(role) {
                if (this.hasRole(role)) {
                    this.user.f_roles = this.user.f_roles.filter(r => r !== role.name);
                } else {
                    this.user.f_roles.push(role.name);
                }
            },
            hasRole(role) {
                if (!this.user.f_roles) {
                    return false;
                }
                return this.user.f_roles.find(r => r === role.name) ? true : false;
            },
            async fetchRoles() {
                this.get('/acl/roles').then(({ data }) => {
                    this.roles = data;
                });
            },
            create() {
                if (this.$refs.form.validate()) {
                    this.btn_loading = true;
                    this.post('/user/store', this.user).then(({ data }) => {
                        if (!data.success) {
                            this.btn_loading = false;
                            return this.$store.commit('showMessage', {
                                message: data.message,
                                type: 'danger',
                            });
                        }
                        this.$store.commit('showMessage', {
                            message: 'Usuário salvo com sucesso.',
                            type: 'success',
                        });
                        this.$router.push('/configuracoes');
                    });
                }
            },
        },
    };
</script>
