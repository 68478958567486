<template>
    <div class="login_box">
        <div class="logo_login">
            <img src="../../assets/images/logo.svg" alt="" />
        </div>
        <div class="card-login">
            <div class="login_form">
                <h2>Esqueceu a senha ?</h2>
                <form name="form" @submit.prevent="handleRemember">
                    <div class="erro" v-if="message">
                        {{ message }}
                    </div>
                    <v-text-field
                        append-icon="mdi-account-circle"
                        v-model="user.email"
                        label="E-mail"
                        class="required"
                        placeholder=" "
                        required
                        outlined
                        :rules="[
                                () =>
                                    !!user.email || 'O campo é obrigatório',
                            ]"
                    />
                    <div class="text-center">
                        <v-btn rounded large type="submit" :disabled="loading" class="gradient" dark>
                            Enviar senha temporária
                        </v-btn>
                    </div>
                    <div class="text-center mt-3">
                        <a class="recovery" @click="$router.push({name : 'Login'})">
                            Voltar ao login
                        </a>
                    </div>
                    <v-progress-circular indeterminate color="green" v-show="loading" />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../util/api/api';

    export default {
        name: 'login',
        mixins: [api],
        computed: {
            loggedIn() {
                return this.$store.getters.isLoggedIn;
            },
        },
        created() {
            this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        },
        data() {
            return {
                user: {
                    email: '',
                },
                message: '',
                loading: false,
                typePassword: String,
            };
        },
        mounted() {
            if (this.loggedIn) {
                this.$router.push('/');
            }
        },
        methods: {
            startRenewPassword() {
                this.loading = false;
            },
            async validateForm() {
                this.message = null;
                let emailExist = await this.$store.dispatch('checkEmailExist', this.user.email);
                if (!emailExist) {
                    this.loading = false;
                    this.message = 'Email não encontrado';
                    throw 'Email not Exist';
                }
            },
            async handleRemember() {
                this.loading = true;
                await this.validateForm();
                this.$store.dispatch('sendRememberEmail', this.user.email).then(sent => {
                    if (sent) {
                        this.$message.success(
                            'Um email com sua nova senha temporária foi enviado, verifique sua caixa de mensagens.'
                        );
                        this.$router.push({ name: 'Login' });
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .login_box {
        width: 550px;
        margin: 20vh auto;
    }
    .v-input--radio-group__input {
        justify-content: center !important;
    }
    .logo_login {
        text-align: center;
        img {
            max-width: 200px;
        }
    }
    .card-login {
        background-color: #fff;
        padding: 35px 40px;
        text-align: center;
        border-radius: 11px;
        margin-top: 47px;
        h2 {
            font-size: 22px;
            margin-bottom: 31px;
        }
        .erro {
            color: #f00;
            padding: 16px;
            margin-bottom: 21px;
            border: 1px solid;
            border-radius: 4px;
        }
        button {
            min-width: 260px !important;
        }
    }
    .v-input--selection-controls.v-input--radio-group.v-input--radio-group--row {
        margin-top: 0;
    }
    .v-input--radio-group__input {
        justify-content: center;
        align-items: center;
    }

    .recovery.disabled {
        color: #ccc;
    }
</style>
