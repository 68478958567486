<template>
    <v-snackbar
      :value="snackbar"
      @input="close"
      :timeout="4000"
      :color="type === 'success' ? 'successAlt' : 'errorAlt'"
    >
      {{ texto }}
      <v-btn
        color="white"
        text
        @click.native="close"
      >
        Fechar
      </v-btn>
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        snackbar() { return this.$store.getters.snackbar.show },
        texto() { return this.$store.getters.snackbar.message },
        type() { return this.$store.getters.snackbar.type },
    },
    methods: {
        close() {
            this.$store.commit('closeMessage');
        }
    }
}
</script>