<template>
  <quill-editor
    v-bind:value="value"
    v-bind:name="forId"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
    :options="{
       placeholder: placeholder,
       modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],
            // [{ 'indent': '-1' }, { 'indent': '+1' }],
            // [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'font': [] }],
            // [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            // ['clean'],
            // ['link', 'image', 'video']
          ]
       }
    }">
    <div :id="forId" slot="toolbar">
      <div class="m-editor__label">{{ placeholder }}</div>
    </div>
  </quill-editor>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
    props: {
        value: {
            type: String,
            required: true,
            default: '',
        },
        placeholder: {
            type: String,
        },
        forId: {
            type: String,
            required: true
        },
        height: {
            type: String,
            default: '100'
        }
    },
    components: {
        quillEditor
    },
}
</script>

<style lang="scss">
.m-editor {
  &__label {
    float: right;
    padding-right: 10px;
    padding-top: 10px;
    font: {
      size: 0.9em;
    };
  }
}

.quill-editor {
  .ql-container {
    min-height: 100px;
  }
}
</style>
