
<template>
    <div class="home defaultDashboard">
        <div class="box-wel">
            <div class="left-flex">
                <h1>Bem-vindo de volta, {{ user.name }}!</h1>
                <h3 v-if="!$hasRole('Diwer')">
                    Você atualmente está participando de
                    <b>{{ data.length }}</b> demandas.
                </h3>
                <h3 v-else>
                    Atualmente temos <b>{{ data.length }}</b> demandas ativas.
                </h3>
            </div>
            <div class="right-flex">
                <img src="../../assets/images/womenvector.svg" />
            </div>
        </div>

        <v-row v-if="$hasPermission('last-jobs')">
            <v-col sm="12">
                <h2 class="titleBox">Últimas Demandas</h2>
                <div class="cardDefault partnersCard" v-if="!$hasRole('Diwer')">
                    <div class="card-body">
                        <v-data-table
                            :headers="
                                !$hasRole('Diwer') ? headersPartners : headers
                            "
                            :items="data"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.user`]="{ item }">
                                {{ $getValue(item.user, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.project`]="{ item }">
                                {{ $getValue(item.project, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip
                                    outlined
                                    :color="getStatusColor(item.status)"
                                >
                                    {{ getStatusName(item.status) }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.client`]="{ item }">
                                {{ $getValue(item.client, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                {{ item.start_date | date }}
                            </template>
                        </v-data-table>
                    </div>
                </div>
                <div class="cardDefault partnersCard" v-else>
                    <div class="card-body">
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | date }}
                            </template>
                            <template v-slot:[`item.user`]="{ item }">
                                {{ $getValue(item.user, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.project`]="{ item }">
                                {{ $getValue(item.project, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip
                                    outlined
                                    :color="getStatusColor(item.status)"
                                >
                                    {{ getStatusName(item.status) }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.client`]="{ item }">
                                {{ $getValue(item.client, 'name', '  -  ') }}
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                {{ item.start_date | date }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    icon="el-icon-view"
                                    @click="
                                        $router.push('/jobs/editar/' + item.id)
                                    "
                                >
                                    Visualizar
                                </el-button>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import object from '../../util/mixins/object/object';
import api from '../../util/api/api';

export default {
    name: 'Home',
    mixins: [api, object],
    created() {
        this.$store.commit(
            'changeTitle',
            this.$router.currentRoute.meta.title[
                !this.$hasRoles(['Diwer']) ? 'partner' : 'diwer'
            ]
        );

        this.getData();
    },
    data() {
        return {
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Nome', value: 'name' },
                { text: 'Cliente', value: 'client' },
                { text: 'Projeto', value: 'project' },
                { text: 'Competência', value: 'created_at' },
                { text: 'Início', value: 'start_date' },
                { text: 'Fim', value: 'end_date' },
                { text: 'Parceiro', value: 'user' },
                { text: 'Status', value: 'status' },
                { text: 'Ações', value: 'actions', width: '200px' },
            ],
            headersPartners: [
                {
                    text: 'Demanda',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Cliente', value: 'client' },
                { text: 'Projeto', value: 'project' },
                { text: 'Competência', value: 'created_at' },
                { text: 'Prazo de entrega', value: 'end_date' },
                { text: 'Status', value: 'status' },
                { text: 'Ações', value: 'actions' },
            ],
            data: [],
        };
    },
    methods: {
        getData() {
            if (!this.$hasRole('Diwer')) {
                this.get(`/jobs`, {
                    user_id: this.user.id,
                }).then((response) => {
                    this.data = response.data.data;
                    this.get(`/partners/${this.user.id}/transaction`).then(
                        (response) => {
                            this.dataTransactions = response.data.data;
                        }
                    );
                });
            } else {
                this.get(`/jobs`).then((response) => {
                    this.data = response.data.data;
                });
            }
        },
    },
    computed: {
        user() {
            return this.$store.getters.userLogged;
        },
    },
};
</script>

<style lang="scss">
.cardDefault {
    .number {
        font-weight: 700;
        font-size: 2rem;
        color: #f70a7e;
        margin-top: 10px;
    }
    h4 {
        color: #bcc7d4;
        font-weight: 400;
        font-size: 15px;
        margin-top: -4px;
    }
    .circleIcon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f70a7e4a;
        margin: 0 auto;
        .v-icon {
            color: #141414;
        }
    }
}

.defaultDashboard {
    .cardDefault {
        height: auto !important;
    }
}

.box-wel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f60a7e),
        to(#fbca02)
    );
    background: -webkit-linear-gradient(left, #f60a7e, #fbca02);
    background: -o-linear-gradient(left, #f60a7e, #fbca02);
    background: linear-gradient(to right, #f60a7e, #fbca02);
    border-radius: 10px;
    padding: 0 46px;
    max-width: 70%;
    max-height: 139px;
    margin-bottom: 20px;
    h1 {
        font-size: 25px;
        font-weight: 700;
        color: #fff;
    }
    h3 {
        font-weight: 400;
        font-size: 18px;
        color: #fff;
    }
    img {
        width: 230px;
        position: relative;
        mix-blend-mode: multiply;
    }
}
h2.titleBox {
    font-size: 20px;
    color: #313131;
    margin-bottom: 10px;
}
</style>
