<template>
    <v-row>
        <v-col sm="12">
            <h2 class="titleBox" v-if="isPageMode">Mapeamento de atividades</h2>
            <div class="cardDefault partnersCard">
                <div class="card-body">
                    <v-row>
                        <v-col sm="6">
                            <v-text-field no-title clearable v-model="filters.filter" label="Filtro" />
                        </v-col>
                        <v-col sm="3" v-if="isPageMode">
                            <v-select
                                :items="featureItems"
                                v-model="filters.feature"
                                label="Feature"
                                item-text="name"
                                item-value="id"
                                clearable
                            />
                        </v-col>
                        <v-col sm="3">
                            <v-select
                                :items="actionItems"
                                v-model="filters.action_type"
                                label="Ação"
                                item-text="name"
                                item-value="id"
                                clearable
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">
                            <v-menu
                                ref="modalStart"
                                :close-on-content-click="false"
                                v-model="modalStart"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formatStartDate"
                                        clearable
                                        slot="activator"
                                        label="Data de início"
                                        prepend-icon="mdi-calendar-arrow-left"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filters.start_date = null"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="filters.start_date"
                                    no-title
                                    scrollable
                                    @input="() => (modalStart = false)"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col sm="3">
                            <v-menu
                                ref="modalFinal"
                                :close-on-content-click="false"
                                v-model="modalFinal"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="formatFinalDate"
                                        clearable
                                        slot="activator"
                                        label="Data Final"
                                        prepend-icon="mdi-calendar-arrow-left"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filters.end_date = null"
                                    />
                                </template>
                                <v-date-picker v-model="filters.end_date" no-title scrollable @input="() => (modalFinal = false)">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="2" class="mt-2">
                            <v-btn color="success" @click="updateFilter">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="data_table.items"
                        :items-per-page="data_table.per_page"
                        :server-items-length="data_table.total"
                        :page="data_table.page"
                        :sort-by="data_table.order_by"
                        :sort-desc="data_table.order_type == 'desc'"
                        :loading="loading.table"
                        @update:page="changePage"
                        @update:sort-by="changeSort"
                        @update:sort-desc="changeSortDesc"
                        @update:items-per-page="pageSizeChange"
                        show-expand
                    >
                        <template v-slot:[`item.feature`]="{ item }">
                            <span :class="getIcon(item.feature)" />
                            <span>{{ translate(item.feature) }}</span>
                        </template>
                        <template v-slot:[`item.action_type`]="{ item }">
                            <span :class="getIcon(item.action_type)" />
                            <span>{{ translate(item.action_type) }}</span>
                        </template>
                        <template v-slot:[`item.user`]="{ item }">
                            <span>{{ item.agent_info.name }}</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>
                                {{ $moment(item.created_at).format( 'DD/MM/YYYY - H:mm:ss' ) }}
                            </span>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" style="padding: 40px 10px">
                                <div class="d-flex flex-row mb-40">
                                    <div class="d-flex flex-column col-4">
                                        <h4 class="mb-2">
                                            <b class="text-primary">Usuário</b>
                                        </h4>
                                        <div>
                                            <b>Id : </b>
                                            {{ item.agent_info.id }}
                                        </div>
                                        <div>
                                            <b>Usuário : </b>
                                            {{ item.agent_info.name }}
                                        </div>
                                        <div>
                                            <b>Email : </b>
                                            {{ item.agent_info.name }}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column col-5">
                                        <h4 class="mb-2">
                                            <b class="text-primary">Acesso</b>
                                        </h4>
                                        <div v-if="false">
                                            <b>Ip de Origem : </b>
                                            {{ item.ip }}
                                        </div>
                                        <div>
                                            <b>Plataforma : </b>
                                            {{ item.agent_info.user_agent }}
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column col-3">
                                        <h4 class="mb-2">
                                            <b class="text-primary">
                                                Atividade
                                            </b>
                                        </h4>
                                        <div>
                                            <b>Alvo : </b>
                                            {{ translate(item.feature) }}
                                        </div>
                                        <div>
                                            <b>Referência :</b>
                                            {{ item.ref_id }}
                                        </div>
                                        <div>
                                            <b>Ação : </b>
                                            {{ translate(item.action_type) }}
                                        </div>
                                        <div>
                                            <b>Data e Hora : </b>
                                            {{ $moment(item.created_at).format( 'DD/MM/YYYY - H:mm:ss' ) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row" v-if="Object.keys(item.new_data).length">
                                    <div class="d-flex flex-column col-12 log-details">
                                        <h4 class="mb-2">
                                            <b class="text-primary">Detalhes</b>
                                        </h4>
                                        <div
                                            v-for="(value, i) in getItemLog(
                                                item
                                            )"
                                            :key="i"
                                        >
                                            <div style="display:flex;align-items-center;">
                                                <b class="mr-2">{{ value.translated }} : </b>
                                                <template
                                                    v-if="
                                                        ![
                                                            'destroy',
                                                            'create',
                                                        ].includes(item.action_type)
                                                    "
                                                >
                                                    <span v-html="value.old_data" />
                                                    <span class="el-icon-right mx-4 mt-1" />
                                                </template>
                                                <span v-html="value.new_data" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import object from '../../util/mixins/object/object';
    import api from '../../util/api/api';

    export default {
        name: 'LogsIndex',
        props: ['feature', 'feature_id'],
        mixins: [api, object],
        data() {
            return {
                modalStart: false,
                modalFinal: false,

                loading: {
                    table: true,
                },
                data_table: {
                    items: [],
                    page: 1,
                    per_page: 10,
                    total: 0,
                    order_by: 'id',
                    order_type: 'desc',
                },
                filters: {
                    start_date: '',
                    end_date: '',
                    feature: '',
                    action_type: '',
                    filter: '',
                    ref_id: '',
                },
                featureItems: [
                    { id: 'clients', name: 'Clientes' },
                    { id: 'partners', name: 'Parceiros' },
                    { id: 'competences', name: 'Competências' },
                    { id: 'jobs', name: 'Demandas' },
                    { id: 'transactions', name: 'Financeiro' },
                ],
                actionItems: [
                    { id: 'create', name: 'Cadastro' },
                    { id: 'update', name: 'Alteração' },
                    { id: 'destroy', name: 'Exclusão' },
                ],
                headers: [],
            };
        },
        created() {
            this.initPageMode();
        },
        computed: {
            isPageMode() {
                return this.feature && this.feature_id ? false : true;
            },
            formatStartDate() {
                return this.filters.start_date ? this.$moment(this.filters.start_date).format('DD/MM/YYYY') : '';
            },
            formatFinalDate() {
                return this.filters.end_date ? this.$moment(this.filters.end_date).format('DD/MM/YYYY') : '';
            },
            columnIndexes() {
                return {
                    common: {
                        name: 'Nome',
                        created_at: 'Data de cadastro',
                        updated_at: 'Data de edição',
                        end_date: 'Data de conclusão',
                        start_date: 'Data de início',
                        due_date: 'Vencimento',
                        amount: 'Valor',
                        status_id: 'Id do Status',
                        job_id: 'Id da Demanda',
                        user_id: 'Id do Usuário',
                    },
                    clients: {
                        site_url: 'Site',
                        phone: 'Telefone',
                        guide_url: 'Guia de boas práticas',
                        category: 'Categoria de faturamento',
                        responsible_name: 'Nome do responsável',
                        responsible_email: 'Email do responsável',
                        responsible_phone: 'Telefone do responsável',
                    },
                    competences: {},
                    partners: {
                        user_id: 'Id do Usuário',
                        date_start: 'Data de início',
                        new_partner: 'Novo Parceiro',
                    },
                    jobs: {
                        client_id: 'Id do Cliente',
                        partner_id: 'Id do Parceiro',
                        project_id: 'Id do Projeto',
                    },
                    transactions: {
                        installments: 'Parcelas',
                    },
                };
            },
        },
        methods: {
            initPageMode() {
                if (this.isPageMode) {
                    this.$set(this, 'headers', [
                        { text: 'ID', align: 'start', value: 'id', sortable: false },
                        { text: 'Feature', value: 'feature', sortable: false },
                        { text: 'Ação', value: 'action_type', sortable: false },
                        { text: 'Usuário', value: 'user', sortable: false },
                        { text: 'Data', value: 'created_at', sortable: false },
                    ]);
                    this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
                    this.fetchRecords(this.data_table);
                } else {
                    this.$set(this, 'headers', [
                        { text: 'Ação', value: 'action_type', sortable: false },
                        { text: 'Usuário', value: 'user', sortable: false },
                        { text: 'Data', value: 'created_at', sortable: false },
                    ]);
                    this.$set(this.filters, 'feature', this.feature);
                    this.$set(this.filters, 'ref_id', this.feature_id);
                    this.fetchRecords(this.data_table, this.filters);
                }
            },
            getItemLog(item) {
                let row = Object.keys(item.new_data).map(key => {
                    let translated = key;
                    if (this.columnIndexes[item.feature][key]) {
                        translated = this.columnIndexes[item.feature][key];
                    } else if (this.columnIndexes['common'][key]) {
                        translated = this.columnIndexes['common'][key];
                    }
                    let old_data = item.old_data[key] || null;
                    let new_data = item.new_data[key] || null;

                    return {
                        translated: translated.capitalize(),
                        old_data,
                        new_data,
                    };
                });
                return row;
            },
            getIcon(key) {
                const content = {
                    partners: ' mdi mdi-handshake mr-2',
                    competences: 'mdi mdi-account-cog mr-2',
                    clients: 'mdi mdi-account-tie mr-2',
                    jobs: 'mdi mdi-alarm-light mr-2',
                    transactions: 'mdi mdi-cash-plus mr-2',
                    update: 'el-icon-edit text-warning mr-2',
                    create: 'el-icon-plus text-success mr-2',
                    destroy: 'el-icon-close text-danger mr-2',
                };
                return content[key] || null;
            },
            translate(key) {
                const content = {
                    partners: 'Parceiros',
                    competences: 'Competencias',
                    clients: 'Clientes',
                    jobs: 'Demandas',
                    transactions: 'Financeiro',
                    update: 'Alteração',
                    create: 'Cadastro',
                    destroy: 'Exclusão',
                };
                return content[key] || key;
            },
            changeSortDesc(desc) {
                // this.fetchRecords({
                //     ...this.data_table,
                //     order_type: desc ? 'desc' : 'asc',
                // });
            },
            changeSort(order_by) {
                // this.fetchRecords({ ...this.data_table, order_by });
            },
            pageSizeChange(per_page) {
                this.fetchRecords({ ...this.data_table, per_page, page: 1 });
            },
            changePage(page) {
                this.fetchRecords({ ...this.data_table, page });
            },
            updateFilter() {
                this.fetchRecords({ ...this.data_table, page: 1 }, this.filters);
            },
            fetchRecords(data_table, filter = {}) {
                this.loading.table = true;
                this.$store
                    .dispatch('fetchLogs', {
                        ...data_table,
                        items: undefined,
                        total: undefined,
                        ...filter,
                    })
                    .then(({ data }) => {
                        this.data_table = data;
                        this.loading.table = false;
                    });
            },
        },
    };
</script>

<style lang="scss">
    .log-details {
        p {
            margin-bottom: 0 !important;
        }
    }
</style>
