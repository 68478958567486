/* eslint-disable indent */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import './plugins/mask'
import MultiFiltersPlugin from './plugins/MultiFilters'
import './util/filters/_filters'
import './util/acl/directives'
require('./plugins/helpers')
require('./plugins/element-ui')
import './assets/scss/app.scss'
import './assets/scss/custom.scss'

Vue.config.productionTip = false
Vue.use(MultiFiltersPlugin)
Vue.prototype.$moment = moment

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
