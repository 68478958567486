import store from '../../store'
import Vue from 'vue'


const hasRoles = (roles) => {
	return store.getters.hasRoles(["Diwer"])
}

const hasRole = (role) => {
	return store.getters.hasRole(role)
}

const hasPermissions = (permissions) => {
	return store.getters.hasPermissions(permissions)
}

const hasPermission = (permission) => {
	return store.getters.hasPermission(permission)
}


Vue.prototype.$hasRole = hasRole
Vue.prototype.$hasRoles = hasRoles
Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$hasPermissions = hasPermissions