<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="home">
                    <div class="actionOnPage mb-2">
                        <v-btn color="primary" small text @click="$router.push('/competencias')">
                            <v-icon>mdi-arrow-left</v-icon>
                            Voltar
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-tabs v-model="tab">
                    <v-tab>Visualização / Edição</v-tab>
                    <v-tab v-if="$hasPermission('logs') && id">Logs</v-tab>
                </v-tabs>
                <div class="home">
                    <template v-if="id && tab == 1 && $hasPermission('logs')">
                        <LogsIndex class="mt-5" feature="competences" :feature_id="id" />
                    </template>
                    <template v-if="tab == 0">
                        <loading :condition="loading">
                            <v-row class="mt-5">
                                <v-col sm="4">
                                    <div class="cardDefault">
                                        <div class="card-body">
                                            <h3 class="titleDefault">Competência</h3>
                                            <v-text-field
                                                outlined
                                                required
                                                v-model="competencia.name"
                                                :rules="[!!competencia.name || 'Nome é requerido']"
                                                label="Nome da competência"
                                            />
                                        </div>
                                    </div>
                                    <div class="mt-5">
                                        <v-btn
                                            :loading="$store.getters.loading"
                                            :disabled="$store.getters.loading"
                                            class="ma-2 buttonWhite"
                                            color="successAlt"
                                            @click="saveCompetence"
                                        >
                                            Salvar alterações
                                        </v-btn>
                                        <v-btn class="ma-2 buttonWhite" color="errorAlt" @click="$router.push('/competencias')">
                                            Cancelar
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col sm="4">
                                    <div class="cardDefault">
                                        <div class="card-body">
                                            <h3 class="titleDefault">Skills</h3>
                                            <div v-if="competencia.skills.length > 0">
                                                <v-chip
                                                    v-for="skill in competencia.skills"
                                                    v-bind:key="skill.id"
                                                    class="ma-2"
                                                    color="primary"
                                                    label
                                                    text-color="white"
                                                >
                                                    <v-icon left>mdi-label</v-icon>
                                                    {{ skill.name }}
                                                    <v-icon small @click="exclude(skill.id)" right>mdi-close-circle</v-icon>
                                                </v-chip>
                                            </div>
                                            <div v-else>Nenhuma skills cadastrada</div>
                                            <div class="adicionarSkill">
                                                <v-btn class="ma-2" @click="editar = true" text color="successAlt">
                                                    Adicionar Skill
                                                </v-btn>
                                                <v-text-field
                                                    v-if="editar"
                                                    outlined
                                                    required
                                                    v-model="skill"
                                                    :rules="[!!skill || 'Nome é requerido']"
                                                    label="Nome da Skill"
                                                >
                                                    <template v-slot:append-outer>
                                                        <v-icon
                                                            :loading="$store.getters.loading"
                                                            :disabled="$store.getters.loading"
                                                            color="successAlt"
                                                            @click="saveSkill"
                                                        >
                                                            mdi-check-circle
                                                        </v-icon>
                                                        <v-icon color="errorAlt" @click="editar = false">
                                                            mdi-close-circle
                                                        </v-icon>
                                                    </template>
                                                </v-text-field>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </loading>
                    </template>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import feedback from '../../util/mixins/feedback/feedback';
    import Loading from '../../components/common/DiweLoading.vue';
    import api from '../../util/api/api';
    import LogsIndex from '../../views/Logs/LogsIndex';

    export default {
        name: 'CompetenceEdit',
        components: { Loading, LogsIndex },
        mixins: [feedback, api],
        data() {
            return {
                tab: 0,
                loading: false,
                id: null,
                editar: false,
                competencia: {},
                skill: '',
            };
        },
        created() {
            this.id = this.$route.params.id;
            this.getData();
        },
        methods: {
            getData() {
                this.loading = true;
                this.get(`/competences/${this.id}`).then(response => {
                    this.get(`/skills?competence_id=${this.id}`).then(responseSkills => {
                        this.competencia = response.data.data;
                        this.competencia.skills = responseSkills.data.data;
                        this.$store.commit(
                            'changeTitle',
                            this.$router.currentRoute.meta.title.replace('{competence_name}', this.competencia.name)
                        );
                        this.loading = false;
                    });
                });
            },
            exclude(id) {
                if (
                    this.askForConfirmation('Remover Registro', 'Realmente deseja remover esse registro?', () => {
                        this.delete(`/skills/${id}`).then(() => {
                            this.getData();
                        });
                    })
                );
            },
            saveSkill() {
                this.post(`/skills`, {
                    name: this.skill,
                    competence_id: this.id,
                }).then(response => {
                    this.competencia.skills.push({
                        id: response.data.data.id,
                        name: this.skill,
                    });
                    this.$store.commit('showMessage', {
                        message: 'Skill adicionada com sucesso.',
                        type: 'success',
                    });
                    this.editar = false;
                    this.skill = '';
                });
            },
            saveCompetence() {
                this.put(`/competences/${this.id}`, {
                    name: this.competencia.name,
                }).then(() => {
                    this.$router.push('/competencias');
                    this.$store.commit('showMessage', {
                        message: 'Competência atualiza com sucesso.',
                        type: 'success',
                    });
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .adicionarSkill {
        padding-top: 5px;
        margin-top: 10px;
        border-top: 1px dashed #ccc;
    }
</style>
