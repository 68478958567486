<template>
    <div class="home">
        <div class="actionOnPage" v-if="$hasPermission('create-customers')">
            <v-btn color="primary" @click="$router.push('/clientes/novo')">
                <v-icon>mdi-plus-circle</v-icon>
                Adicionar cliente
            </v-btn>
        </div>
        <v-row>
            <v-col sm="3">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    hide-details
                    solo
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <div class="cardDefault partnersCard">
                    <div class="card-body">
                        <v-data-table
                            :headers="headers"
                            :items="data"
                            :search="search"
                            :items-per-page="10"
                            :loading="$store.getters.loading"
                        >
                            <template v-slot:[`item.site_url`]="{ item }">
                                <a :href="item.site_url" target="_blank">{{
                                    item.site_url
                                }}</a></template
                            >
                            <template v-slot:[`item.guide_url`]="{ item }">
                                <span v-if="validUrl(item.guide_url)">
                                    <a :href="item.guide_url" target="_blank">{{
                                        item.guide_url.slice(0, 30) +
                                        (item.guide_url > 30 ? '...' : '')
                                    }}</a>
                                </span>
                                <span v-else>{{ item.guide_url }}</span>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | dateHour }}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <el-button-group>
                                    <el-button
                                        size="mini"
                                        type="primary"
                                        icon="el-icon-edit"
                                        v-if="$hasPermission('edit-customers')"
                                        @click="
                                            $router.push(
                                                '/clientes/editar/' + item.id
                                            )
                                        "
                                    >
                                        Editar
                                    </el-button>
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        icon="el-icon-error"
                                        v-if="
                                            $hasPermission('delete-customers')
                                        "
                                        @click="exclude(item.id)"
                                    >
                                        Excluir
                                    </el-button>
                                </el-button-group>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import feedback from '../../util/mixins/feedback/feedback';
import api from '../../util/api/api';

export default {
    name: 'Clients',
    mixins: [feedback, api],
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                },
                { text: 'Nome', value: 'name' },
                { text: 'Site', value: 'site_url' },
                { text: 'Responsável', value: 'responsible_name' },
                { text: 'Telefone', value: 'responsible_phone' },
                { text: 'Guia de boas práticas', value: 'guide_url' },
                { text: 'Criado em', value: 'created_at' },
                { text: 'Ações', value: 'actions', width: '200px' },
            ],
            data: [],
        };
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.$router.currentRoute.meta.title;
        this.getData();
    },
    methods: {
        validUrl(string) {
            try {
                new URL(string);
            } catch (_) {
                return false;
            }

            return true;
        },
        getData() {
            this.get('/clients').then((response) => {
                this.data = response.data.data;
            });
        },
        exclude(id) {
            if (
                this.askForConfirmation(
                    'Remover Registro',
                    'Realmente deseja remover esse registro?',
                    () => {
                        this.delete(`/clients/${id}`).then(() => {
                            this.getData();
                            this.$store.commit('showMessage', {
                                message: 'Cliente deletado com sucesso.',
                                type: 'success',
                            });
                        });
                    }
                )
            );
        },
    },
};
</script>