<template>
    <div class="home">
        <div class="actionOnPage">
            <v-btn color="primary" small text @click="$router.push('/configuracoes')">
                <v-icon>mdi-arrow-left</v-icon>
                Voltar
            </v-btn>
        </div>
        <v-form ref="form" v-model="valid">
            <loading :condition="loading">
                <v-row class="mt-5">
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Informações do usuário
                                </h3>
                                <v-text-field
                                    outlined
                                    required
                                    v-model="user.name"
                                    :rules="[
                                        !!user.name || 'Nome é requerido',
                                    ]"
                                    label="Nome do Usuário"
                                />
                                <v-text-field
                                    outlined
                                    required
                                    v-model="user.email"
                                    :rules="[
                                        !!user.email || 'Email é requerido',
                                    ]"
                                    label="Email do Usuário"
                                />
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Grupos de Acesso
                                </h3>
                                <div class="role-list">
                                    <div
                                        :class="`role-item ${ hasRole(role) ? 'active' : 'not-active'}`"
                                        v-for="(role,i) in roles.filter(x=>!['Administrador de Sistema','Diwer','Parceiro'].includes(x.name))"
                                        :key="i"
                                        @click="toggleRole(role)"
                                    >
                                        {{ role.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="4">
                        <div class="cardDefault">
                            <div class="card-body">
                                <h3 class="titleDefault">
                                    Roles
                                </h3>
                                <div class="role-list disabled">
                                    <div
                                        :class="`role-item ${ hasRole(role) ? 'active' : 'not-active'}`"
                                        v-for="(role,i) in roles.filter(x=>['Diwer','Parceiro'].includes(x.name))"
                                        :key="i"
                                        @click="toggleRole(role)"
                                    >
                                        {{ role.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col sm="12">
                        <div class="mt-5">
                            <v-btn
                                :loading="btn_loading"
                                :disabled="btn_loading"
                                class="ma-2 buttonWhite"
                                color="successAlt"
                                @click="update"
                            >
                                Salvar alterações
                            </v-btn>
                            <v-btn class="ma-2 buttonWhite" color="errorAlt" @click="$router.push('/configuracoes')">
                                Cancelar
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </loading>
        </v-form>
    </div>
</template>
<script>
    import Loading from '../../components/common/DiweLoading.vue';
    import api from '../../util/api/api';

    export default {
        mixins: [api],
        components: {
            Loading,
        },
        data() {
            return {
                loading: true,
                valid: false,
                btn_loading: false,
                user: {},
                roles: [],
            };
        },
        created() {
            this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
            Promise.all([this.fetchRoles(), this.fetchUser()]).then(() => {
                this.loading = false;
            });
        },
        methods: {
            toggleRole(role) {
                if (this.hasRole(role)) {
                    this.user.f_roles = this.user.f_roles.filter(r => r !== role.name);
                } else {
                    this.user.f_roles.push(role.name);
                }
            },
            hasRole(role) {
                if (!this.user.roles) {
                    return false;
                }
                return this.user.f_roles.find(r => r === role.name) ? true : false;
            },
            async fetchRoles() {
                this.get('/acl/roles').then(({ data }) => {
                    this.roles = data;
                });
            },
            async fetchUser() {
                let { data } = await this.get(`/find-user/${this.$router.currentRoute.params.id}`, this.filter);
                Object.keys(data).forEach(key => {
                    this.$set(this.user, key, data[key]);
                });
            },
            update() {
                if (this.$refs.form.validate()) {
                    this.btn_loading = true;
                    this.put(`/user/${this.$router.currentRoute.params.id}`, this.user).then(({ data }) => {
                        if (!data.success) {
                            this.btn_loading = false;
                            return this.$store.commit('showMessage', {
                                message: data.message,
                                type: 'danger',
                            });
                        }
                        this.$store.commit('showMessage', {
                            message: 'Usuário atualizado com sucesso.',
                            type: 'success',
                        });
                        this.$router.push('/configuracoes');
                    });
                }
            },
        },
    };
</script>
