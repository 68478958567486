<template>
    <div class="inputUpload">
        <v-avatar class="gradient" size="62" v-if="hasAvatar">
            <img :src="imgPreview" v-if="imgPreview" />
            <span class="white--text headline" v-else>{{ name ? name.substring(0, 2) : '?' }}</span>
        </v-avatar>
        <v-file-input
            v-model="files"
            color="primary"
            :label="label"
            :multiple="false"
            :placeholder="placeholder"
            @change="uploadFile"
            outlined
            :show-size="1000"
            :loading="loading"
        >
            <template v-slot:selection="">
                <span class="overline grey--text text--darken-3 mx-2">
                    +{{ files.length }} selecionada
                </span>
            </template>
        </v-file-input>
    </div>
</template>

<script>
    import api from '../../util/api/api';
    export default {
        props: {
            name: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: 'Foto do parceiro',
            },
            placeholder: {
                type: String,
                default: 'Selecione uma imagem',
            },
            hasAvatar: {
                type: Boolean,
                default: true,
            },
        },
        mixins: [api],
        data: () => ({
            files: [],
            imgPreview: '',
            loading: false,
        }),
        methods: {
            uploadFile(file) {
                let imageNow = file[0];
                this.imgPreview = URL.createObjectURL(imageNow);
                const formData = new FormData();
                this.loading = true;
                formData.append('file', imageNow);
                this.postMultipart('/upload', formData).then(response => {
                    console.log(response);
                    this.$emit('image', response.data);
                    this.loading = false;
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .inputUpload {
        display: flex;
        button.v-icon {
            opacity: 0 !important;
        }
    }
</style>
