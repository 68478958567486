export default {
    name: 'object',
    computed: {
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 0,
                masked: false,
            };
        },
        moneyCents() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
            };
        },
        rule() {
            return [(v) => !!v || 'Campo obrigatório'];
        },
    },
    methods: {
        unformatMaskByType(value, type) {
            if (type === 'CNPJ') return value.replace(/\./g, '').replace('/', '').replace('-', '');
            if (type === 'PHONE') return value.replace('(', '').replace(')', '').replace(/ /g, '').replace('-', '');
            if (type === 'MONEY') return Number(value.replace(/ /g, '').replace('R$', '').replace(/\./g, '').replace(',', '.'));
            if (type === 'MONEYMASK') return value.toFixed(2);
            return value;
        },
        getMaskByType(type) {
            if (type === 'CNPJ') return '##.###.###/####-##';
            if (type === 'CPF') return '###.###.###-##';
            if (type === 'CELLPHONE') return '(##) # ####-####';
            if (type === 'PHONE') return '(##) ####-####';
            return '####################';
        },
        getStatusColor(status) {
            if(status === 'payment-approved') return 'successAlt';
            if(status === 'pending') return 'grey';
            if(status === 'open') return 'blue';
            if(status === 'finish') return 'successAlt';
            if(status === 'delivered') return 'success';
            if(status === 'inprogress') return 'orange';
        },
        getStatusName(status) {
            if(status === 'payment-approved') return 'Pagamento aprovado';
            if(status === 'pending') return 'Pendente';
            if(status === 'open') return 'Aberto';
            if(status === 'finish') return 'Finalizado';
            if(status === 'delivered') return 'Entregue';
            if(status === 'inprogress') return 'Em andamento';
        }
    },
};
