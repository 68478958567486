export default {
	name: 'headerParam',
	methods: {
		getParams() {
			const params = {}
			return JSON.stringify(params)
		},
		getFrontData() {
			return ''
		},
		getAgent() {
			return ''
		},
		getAuthorizationToken() {
			const authToken = this.$store.getters.tokenIn
			if (authToken) return `Bearer ${authToken}`
			return ''
		},
	},
}
