var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"titleDefault"},[_vm._v(" Informações de pagamento "),(_vm.$hasPermission('create-payments'))?_c('v-btn',{staticClass:"mr-4",staticStyle:{"float":"right"},attrs:{"outlined":"","color":"successAlt"},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"mr-20"},[_vm._v("mdi-plus-circle")]),_vm._v(" Adicionar novo pagamento ")],1):_vm._e()],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"filtrosArea mt-5",attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs3":""}},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"Status","item-text":"name","item-value":"id","clearable":""},on:{"change":_vm.filterPaymentStatus}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-menu',{ref:"show_start_date",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.start_date,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.start_date=$event},"update:return-value":function($event){_vm.start_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"slot":"activator","value":_vm.changeDate(_vm.start_date),"label":"Data de início","prepend-icon":"mdi-calendar-arrow-left","readonly":""},slot:"activator"},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_start_date),callback:function ($$v) {_vm.show_start_date=$$v},expression:"show_start_date"}},[_c('v-date-picker',{on:{"input":_vm.filterStartDate},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-flex',{attrs:{"xs3":""}},[_c('v-menu',{ref:"show_end_date",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.end_date,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.end_date=$event},"update:return-value":function($event){_vm.end_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"slot":"activator","value":_vm.changeDate(_vm.end_date),"label":"Data fim","prepend-icon":"mdi-calendar-arrow-right","readonly":""},slot:"activator"},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.show_end_date),callback:function ($$v) {_vm.show_end_date=$$v},expression:"show_end_date"}},[_c('v-date-picker',{on:{"input":_vm.filterEndDate},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":!_vm.$hasRole('Diwer') ? _vm.headersPartners : _vm.headers,"items":_vm.transactions,"items-per-page":10,"loading":_vm.$store.getters.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.due_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.payment_status.id === 1
                                ? 'successAlt'
                                : 'grey'}},[_vm._v(" "+_vm._s(item.payment_status.name)+" ")])]}},{key:"item.observation",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.observation)+" ")]}},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [(_vm.$hasPermission('edit-payments'))?_c('v-btn',{staticClass:"mr-3 buttonWhite",attrs:{"color":"successAlt","disabled":!_vm.$hasPermission('edit-payments-any-status') && item.status_id === 1,"outlined":""},on:{"click":function($event){return _vm.openDialog(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Editar ")],1):_vm._e(),(_vm.$hasPermission('delete-payments'))?_c('v-btn',{staticClass:"buttonWhite",attrs:{"color":"errorAlt","outlined":""},on:{"click":function($event){return _vm.excludeTransaction(item.id)}}},[_c('v-icon',[_vm._v("mdi-close-circle")]),_vm._v(" Excluir ")],1):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"bodyFinal"},[_c('td',[_c('b',[_vm._v("Total:")])]),_c('td',[_c('b',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.transactions.reduce( function (a, b) { return a + (b['amount'] || 0); }, 0 )))+" ")])]),_c('td',{attrs:{"colspan":"4"}})])]},proxy:true}],null,true)})],1)]),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"defaultModal"},[_c('v-card-title',[_vm._v(_vm._s(!_vm.edit ? 'Novo Pagamento' : 'Editando o transaction'))]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.transactionValid),callback:function ($$v) {_vm.transactionValid=$$v},expression:"transactionValid"}},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyCents),expression:"moneyCents"}],attrs:{"outlined":"","required":"","prefix":"R$","rules":[
                            !!_vm.transaction.amount ||
                                'Quantidade é requerido' ],"label":"Valor Total"},model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", $$v)},expression:"transaction.amount"}}),(!_vm.edit)?_c('v-text-field',{attrs:{"outlined":"","required":"","type":"number","rules":[
                            !!_vm.transaction.installments ||
                                'Quantidade é requerido' ],"label":"Quantidade de parcelas"},model:{value:(_vm.transaction.installments),callback:function ($$v) {_vm.$set(_vm.transaction, "installments", $$v)},expression:"transaction.installments"}}):_vm._e(),_c('v-text-field',{attrs:{"outlined":"","required":"","prepend-inner-icon":"mdi-calendar-range","value":_vm.changeDate(_vm.transaction.due_date),"label":"Dia do Pagamento","placeholder":"Selecione uma data","readonly":""},on:{"click":function($event){_vm.showPicker = true}}}),(_vm.edit)?_c('v-select',{attrs:{"outlined":"","label":"Status","items":_vm.statusItems,"item-text":"name","item-value":"id"},model:{value:(_vm.transaction.status_id),callback:function ($$v) {_vm.$set(_vm.transaction, "status_id", $$v)},expression:"transaction.status_id"}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"errorAlt","text":""},on:{"click":_vm.cancelDialog}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"successAlt","dark":"","depressed":"","loading":_vm.$store.getters.loading,"disabled":_vm.$store.getters.loading},on:{"click":_vm.savePayment}},[_vm._v(" Salvar Pagamento ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","show-current":true},on:{"input":_vm.selectedDate},model:{value:(_vm.transaction.due_date),callback:function ($$v) {_vm.$set(_vm.transaction, "due_date", $$v)},expression:"transaction.due_date"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }