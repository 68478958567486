var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"cardDefault"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.job.name)+" - "+_vm._s(item.job.client.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":item.payment_status.id === 1
                                ? 'successAlt'
                                : 'grey'}},[_vm._v(" "+_vm._s(item.payment_status.name)+" ")])]}},{key:"item.observation",fn:function(ref){
                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.observation)+" ")]}}],null,true)})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }