<template>
    <div>
        <div
            v-if="condition"
            class="text-center"
        >
            <v-progress-circular
                v-if="large"
                :size="50"
                class="mt-4"
                color="primary"
                indeterminate
            />

            <v-progress-circular
                v-if="!large"
                color="primary"
                indeterminate
            />
        </div>
        <div v-if="!condition">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        condition: { type: Boolean, required: true },
        large: { type: Boolean, default: false },
    },
};
</script>

<style scoped lang="scss">

</style>
