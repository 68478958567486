<template>
    <v-app-bar app flat dark clipped-left clipped-right color="primary" class="headerBg">
        <v-app-bar-nav-icon @click="openMainMenu()" />

        <v-toolbar-title class="title text-uppercase">
            <img @click="$router.push(`/`)" src="../../assets/images/logo.svg" class="logo-header cursor-pointer" />
        </v-toolbar-title>

        <v-spacer />
        <div class="diwer" v-if="$hasRole('Diwer')">
            <img src="../../assets/images/logowhite.svg" class="logo-header cursor-pointer" @click="$router.push(`/`)" />
        </div>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <div class="accountArea" v-on="on">
                    <v-avatar color="primary" class="gradient" size="40">
                        <img :src="$serverURI(user.image)" v-if="user.image" />
                        <span class="white--text avatarText" v-else>
                            {{ getInitials(user.name) }}
                        </span>
                    </v-avatar>
                    <span class="white--text">{{ user.name }}</span>
                </div>
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-title>Minha conta</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'AppHeader',
        data() {
            return {};
        },
        computed: {
            openMenu() {
                return this.$store.getters.isOpenMenu;
            },
            user() {
                return this.$store.getters.userLogged;
            },
            userImage() {
                return this.$store.getters.userImage;
            },
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$router.push('/login');
            },
            openMainMenu() {
                this.$emit('openMenu', !this.openMenu);
            },
            getInitials(name) {
                let nameInitial;
                if (name) {
                    nameInitial = name
                        .replace(/[^A-Za-z0-9À-ÿ ]/gi, '')
                        .replace(/ +/gi, ' ')
                        .split(/ /)
                        .reduce((acc, item) => acc + item[0], '')
                        .concat(name.substr(1))
                        .concat(name)
                        .substr(0, 2)
                        .toUpperCase();
                }
                return nameInitial;
            },
        },
    };
</script>

<style scoped lang="scss">
    .logo-header {
        width: 80px;
        margin-top: 13px;
        margin-left: 6px;
    }
    .headerBg {
        background-image: url(../../assets/images/headerbg.svg);
    }
    .accountArea {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > div {
            margin-right: 13px;
        }
    }
    .diwer {
        padding: 0px 22px;
        background: #fff;
        border-radius: 50px;
        margin-right: 18px;
    }
    .diwer img {
        max-width: 47px;
    }
</style>
