var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.$hasPermission('create-projects'))?_c('div',{staticClass:"actionOnPage"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/projetos/novo')}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" Adicionar projeto ")],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.client.name))]}},{key:"item.board",fn:function(ref){
var item = ref.item;
return [(_vm.validUrl(item.board))?_c('span',[_c('a',{attrs:{"href":item.board,"target":"_blank"}},[_vm._v(_vm._s(item.board.slice(0, 20) + '...'))])]):_c('span',[_vm._v(_vm._s(item.board))])]}},{key:"item.drive_folder",fn:function(ref){
var item = ref.item;
return [(_vm.validUrl(item.drive_folder))?_c('span',[_c('a',{attrs:{"href":item.drive_folder,"target":"_blank"}},[_vm._v(_vm._s(item.drive_folder.slice(0, 20) + '...'))])]):_c('span',[_vm._v(_vm._s(item.drive_folder))])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.start_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('el-button-group',[(_vm.$hasPermission('view-projects'))?_c('el-button',{attrs:{"size":"mini","type":"success","icon":"el-icon-view"},on:{"click":function($event){return _vm.$router.push(
                                            '/projetos/editar/' + item.id
                                        )}}},[_vm._v(" Detalhes ")]):_vm._e(),(_vm.$hasPermission('delete-projects'))?_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-error"},on:{"click":function($event){return _vm.exclude(item.id)}}},[_vm._v(" Excluir ")]):_vm._e()],1)]}}],null,true)})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }