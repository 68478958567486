<template>
    <v-row>
        <v-col sm="12">
            <v-card>
                <v-tabs v-model="tab">
                    <template v-if="$hasPermission('users')">
                        <v-tab key="users">Usuários de Sistema</v-tab>
                    </template>
                    <template v-if="$hasPermission('acl')">
                        <v-tab key="acl"> Perfis e Grupos de Acesso (ACL) </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <template v-if="$hasPermission('users')">
                        <v-tab-item key="users">
                            <v-card>
                                <v-card-text>
                                    <TableUser />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </template>
                    <template v-if="$hasPermission('acl')">
                        <v-tab-item key="acl">
                            <v-card>
                                <v-card-text>
                                    <TableAcl />
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </template>
                </v-tabs-items>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import object from '../../util/mixins/object/object';
    import api from '../../util/api/api';
    import TableAcl from './partials/TableAcl';
    import TableUser from './partials/TableUser';
    export default {
        name: 'SettingsIndex',
        mixins: [api, object],
        components: {
            TableAcl,
            TableUser,
        },
        data() {
            return {
                tab: 'acl',
            };
        },
        created() {
            this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        },
    };
</script>
