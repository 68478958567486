var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[(_vm.isPageMode)?_c('h2',{staticClass:"titleBox"},[_vm._v("Mapeamento de atividades")]):_vm._e(),_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"no-title":"","clearable":"","label":"Filtro"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),(_vm.isPageMode)?_c('v-col',{attrs:{"sm":"3"}},[_c('v-select',{attrs:{"items":_vm.featureItems,"label":"Feature","item-text":"name","item-value":"id","clearable":""},model:{value:(_vm.filters.feature),callback:function ($$v) {_vm.$set(_vm.filters, "feature", $$v)},expression:"filters.feature"}})],1):_vm._e(),_c('v-col',{attrs:{"sm":"3"}},[_c('v-select',{attrs:{"items":_vm.actionItems,"label":"Ação","item-text":"name","item-value":"id","clearable":""},model:{value:(_vm.filters.action_type),callback:function ($$v) {_vm.$set(_vm.filters, "action_type", $$v)},expression:"filters.action_type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-menu',{ref:"modalStart",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"slot":"activator","clearable":"","label":"Data de início","prepend-icon":"mdi-calendar-arrow-left","readonly":""},on:{"click:clear":function($event){_vm.filters.start_date = null}},slot:"activator",model:{value:(_vm.formatStartDate),callback:function ($$v) {_vm.formatStartDate=$$v},expression:"formatStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalStart),callback:function ($$v) {_vm.modalStart=$$v},expression:"modalStart"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function () { return (_vm.modalStart = false); }},model:{value:(_vm.filters.start_date),callback:function ($$v) {_vm.$set(_vm.filters, "start_date", $$v)},expression:"filters.start_date"}})],1)],1),_c('v-col',{attrs:{"sm":"3"}},[_c('v-menu',{ref:"modalFinal",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"slot":"activator","clearable":"","label":"Data Final","prepend-icon":"mdi-calendar-arrow-left","readonly":""},on:{"click:clear":function($event){_vm.filters.end_date = null}},slot:"activator",model:{value:(_vm.formatFinalDate),callback:function ($$v) {_vm.formatFinalDate=$$v},expression:"formatFinalDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFinal),callback:function ($$v) {_vm.modalFinal=$$v},expression:"modalFinal"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function () { return (_vm.modalFinal = false); }},model:{value:(_vm.filters.end_date),callback:function ($$v) {_vm.$set(_vm.filters, "end_date", $$v)},expression:"filters.end_date"}})],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"sm":"2"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.updateFilter}},[_vm._v(" Filtrar ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data_table.items,"items-per-page":_vm.data_table.per_page,"server-items-length":_vm.data_table.total,"page":_vm.data_table.page,"sort-by":_vm.data_table.order_by,"sort-desc":_vm.data_table.order_type == 'desc',"loading":_vm.loading.table,"show-expand":""},on:{"update:page":_vm.changePage,"update:sort-by":_vm.changeSort,"update:sort-desc":_vm.changeSortDesc,"update:items-per-page":_vm.pageSizeChange},scopedSlots:_vm._u([{key:"item.feature",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getIcon(item.feature)}),_c('span',[_vm._v(_vm._s(_vm.translate(item.feature)))])]}},{key:"item.action_type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getIcon(item.action_type)}),_c('span',[_vm._v(_vm._s(_vm.translate(item.action_type)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.agent_info.name))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format( 'DD/MM/YYYY - H:mm:ss' ))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"40px 10px"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex flex-row mb-40"},[_c('div',{staticClass:"d-flex flex-column col-4"},[_c('h4',{staticClass:"mb-2"},[_c('b',{staticClass:"text-primary"},[_vm._v("Usuário")])]),_c('div',[_c('b',[_vm._v("Id : ")]),_vm._v(" "+_vm._s(item.agent_info.id)+" ")]),_c('div',[_c('b',[_vm._v("Usuário : ")]),_vm._v(" "+_vm._s(item.agent_info.name)+" ")]),_c('div',[_c('b',[_vm._v("Email : ")]),_vm._v(" "+_vm._s(item.agent_info.name)+" ")])]),_c('div',{staticClass:"d-flex flex-column col-5"},[_c('h4',{staticClass:"mb-2"},[_c('b',{staticClass:"text-primary"},[_vm._v("Acesso")])]),(false)?_c('div',[_c('b',[_vm._v("Ip de Origem : ")]),_vm._v(" "+_vm._s(item.ip)+" ")]):_vm._e(),_c('div',[_c('b',[_vm._v("Plataforma : ")]),_vm._v(" "+_vm._s(item.agent_info.user_agent)+" ")])]),_c('div',{staticClass:"d-flex flex-column col-3"},[_c('h4',{staticClass:"mb-2"},[_c('b',{staticClass:"text-primary"},[_vm._v(" Atividade ")])]),_c('div',[_c('b',[_vm._v("Alvo : ")]),_vm._v(" "+_vm._s(_vm.translate(item.feature))+" ")]),_c('div',[_c('b',[_vm._v("Referência :")]),_vm._v(" "+_vm._s(item.ref_id)+" ")]),_c('div',[_c('b',[_vm._v("Ação : ")]),_vm._v(" "+_vm._s(_vm.translate(item.action_type))+" ")]),_c('div',[_c('b',[_vm._v("Data e Hora : ")]),_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format( 'DD/MM/YYYY - H:mm:ss' ))+" ")])])]),(Object.keys(item.new_data).length)?_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column col-12 log-details"},[_c('h4',{staticClass:"mb-2"},[_c('b',{staticClass:"text-primary"},[_vm._v("Detalhes")])]),_vm._l((_vm.getItemLog(
                                            item
                                        )),function(value,i){return _c('div',{key:i},[_c('div',{staticStyle:{"display":"flex"}},[_c('b',{staticClass:"mr-2"},[_vm._v(_vm._s(value.translated)+" : ")]),(
                                                    ![
                                                        'destroy',
                                                        'create' ].includes(item.action_type)
                                                )?[_c('span',{domProps:{"innerHTML":_vm._s(value.old_data)}}),_c('span',{staticClass:"el-icon-right mx-4 mt-1"})]:_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(value.new_data)}})],2)])})],2)]):_vm._e()])]}}],null,true)})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }