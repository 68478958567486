<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="home">
                    <div class="actionOnPage mb-2">
                        <v-btn color="primary" small text @click="$router.push('/jobs')">
                            <v-icon>mdi-arrow-left</v-icon>
                            Voltar
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-tabs v-model="tab">
                    <v-tab>Visualização / Edição</v-tab>
                    <v-tab v-if="$hasPermission('logs') && id">Logs</v-tab>
                </v-tabs>
                <div class="home">
                    <template v-if="id && tab == 1 && $hasPermission('logs')">
                        <LogsIndex class="mt-5" feature="jobs" :feature_id="id" />
                    </template>
                    <template v-if="tab == 0">
                        <div class="home jobsPage mt-5">
                            <v-tabs v-model="activeTab" light slider-color="primary">
                                <v-tab key="DATA"> Dados </v-tab>
                                <v-tab-item key="DATA">
                                    <v-form ref="form" v-model="valid">
                                        <loading :condition="loadingData">
                                            <v-row class="mt-5">
                                                <v-col sm="6">
                                                    <div class="cardDefault">
                                                        <div class="card-body">
                                                            <h3 class="titleDefault">
                                                                Informações da demanda
                                                            </h3>
                                                            <v-row class="no-padding">
                                                                <v-col sm="6">
                                                                    <v-text-field outlined required v-model="job.name"
                                                                        :rules="[
                                                                            !!job.name ||
                                                                            'Nome é requerido',
                                                                        ]" label="Nome da demanda" :disabled="
    !$hasRole('Diwer')
"></v-text-field>
                                                                </v-col>
                                                                <v-col sm="6">
                                                                    <v-select outlined v-model="job.status"
                                                                        label="Status" :items="statusItems"
                                                                        item-text="name" item-value="id" :disabled="
                                                                            !$hasRole('Diwer')
                                                                        "></v-select>
                                                                </v-col>
                                                                <v-col sm="6">
                                                                    <v-select outlined required v-model="job.client_id"
                                                                        :rules="[
                                                                            !!job.client_id ||
                                                                            'Cliente é requerido',
                                                                        ]" label="Cliente" :items="clientsItems" item-text="name"
                                                                        item-value="id" @change="changeClient"
                                                                        :disabled="
                                                                            !$hasRole('Diwer')
                                                                        "></v-select>
                                                                </v-col>
                                                                <v-col sm="6">
                                                                    <v-select outlined required v-model="job.project_id"
                                                                        :rules="[
                                                                            !!job.project_id ||
                                                                            'Projeto é requerido',
                                                                        ]" label="Projeto" :items="projectItems" item-text="name"
                                                                        item-value="id" :disabled="
                                                                            projectItems.length ===
                                                                            0 ||
                                                                            !$hasRole('Diwer')
                                                                        "></v-select>
                                                                </v-col>
                                                                <v-col sm="6">
                                                                    <v-text-field outlined required
                                                                        prepend-inner-icon="mdi-calendar-range" :value="
                                                                            changeDate(
                                                                                job.start_date
                                                                            )
                                                                        " @click="showPicker = true" label="Data de início"
                                                                        placeholder="Selecione uma data"
                                                                        readonly></v-text-field>
                                                                </v-col>
                                                                <v-col sm="6">
                                                                    <v-text-field outlined required
                                                                        prepend-inner-icon="mdi-calendar-range" :value="
                                                                            changeDate(job.end_date)
                                                                        " @click="
    showPickerDois = true
" label="Data de entrega" placeholder="Selecione uma data"
                                                                        readonly></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="no-padding">
                                                                <v-col sm="8">
                                                                    <v-text-field outlined required
                                                                        prepend-inner-icon="mdi-earth"
                                                                        v-model="job.briefing" :rules="[
                                                                            !!job.briefing ||
                                                                            'Briefing é requerido',
                                                                        ]" label="Briefing" :disabled="
    !$hasRole('Diwer')
" />
                                                                </v-col>
                                                                <v-col sm="4">
                                                                    <v-text-field outlined required v-model="job.budget"
                                                                        :rules="[
                                                                            !!job.budget ||
                                                                            'Budget é requerido',
                                                                        ]" prefix="R$" v-money="moneyCents" label="Budget" />
                                                                </v-col>
                                                                <v-col sm="12 pb-12">
                                                                    <app-editor required v-model="job.description"
                                                                        :value="job.description" height="120"
                                                                        for-id="description" placeholder="Descrição" />
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col sm="6">
                                                    <div class="cardDefault">
                                                        <div class="card-body">
                                                            <h3 class="titleDefault">
                                                                Informações do parceiro
                                                            </h3>
                                                            <v-autocomplete multiple outlined small v-model="job.skills"
                                                                :items="skillsItems" item-text="skill.name"
                                                                item-value="skill.id" v-if="$hasRole('Diwer')"
                                                                label="Skills">
                                                                <template v-slot:selection="{ item }">
                                                                    <v-chip color="primary">
                                                                        <span>{{ item.skill.name }}</span>
                                                                    </v-chip>
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    <template v-if="
                                                                        typeof data.item !==
                                                                        'object'
                                                                    ">
                                                                        <v-list-title v-text="data.item"></v-list-title>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div>
                                                                            <div class="head" v-html="
                                                                                data.item.skill
                                                                                    .name
                                                                            "></div>
                                                                            <div class="
                                                                subhead
                                                                d-block
                                                            " v-html="
                                                                data.item
                                                                    .competence
                                                                    .name
                                                            "></div>
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                            </v-autocomplete>
                                                            <v-select outlined v-model="job.partners"
                                                                label="Parceiros Pré-Selecionados"
                                                                :items="partnersItems" item-text="user.name"
                                                                item-value="user.id" @input="onChangePartners($event)"
                                                                multiple persistent-hint>
                                                                <template slot="item" slot-scope="{ item }">
                                                                    <div class="m-select__option">
                                                                        <div class="head">
                                                                            <div class="head" v-html="
                                                                                item.user.name
                                                                            "></div>
                                                                        </div>
                                                                        <div class="
                                                            subhead
                                                            d-block
                                                            pb-2
                                                        ">
                                                                            <v-chip x-small outlined color="primary"
                                                                                class="ml-2" v-for="(
                                                                skill, index
                                                            ) in item.user.skills.slice(
                                                                0,
                                                                3
                                                            )" :key="index">
                                                                                <span>{{ skill.name }}</span>
                                                                            </v-chip>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </v-select>

                                                            <v-select outlined v-model="job.user_id"
                                                                label="Parceiro Aprovado" :items="partnersPreSelected"
                                                                item-text="name" item-value="id" />

                                                            <div class="labelRating" style="margin-top: -12px"
                                                                v-show="ratingCanBeEdit">
                                                                <label>Qualidade de entrega:</label>
                                                                <v-rating v-model="job.quality"
                                                                    background-color="grey darken-1"
                                                                    color="yellow darken-3" />
                                                            </div>

                                                            <div class="labelRating" v-show="ratingCanBeEdit">
                                                                <label>Eficiência na comunicação:</label>
                                                                <v-rating v-model="job.communication"
                                                                    background-color="grey darken-1"
                                                                    color="yellow darken-3" />
                                                            </div>

                                                            <v-select outlined class="m-select" v-model="job.squad"
                                                                label="Squad" :items="squad" persistent-hint>
                                                            </v-select>

                                                            <app-editor required v-model="job.note" :value="job.note"
                                                                height="120" for-id="note" placeholder="Observações"
                                                                v-if="$hasRole('Diwer')" />
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col sm="12">
                                                    <div class="mt-5" v-if="$hasRole('Diwer')">
                                                        <v-btn :loading="$store.getters.loading"
                                                            :disabled="$store.getters.loading" class="ma-2 buttonWhite"
                                                            color="successAlt" @click="updateJob">Salvar
                                                            alterações</v-btn>
                                                        <v-btn class="ma-2 buttonWhite" color="errorAlt"
                                                            @click="$router.push('/jobs')">Cancelar</v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </loading>
                                    </v-form>

                                    <v-dialog v-model="showPicker" max-width="400">
                                        <v-date-picker @input="selectedDate" locale="pt-br" v-model="job.start_date"
                                            :show-current="true"></v-date-picker>
                                    </v-dialog>
                                    <v-dialog v-model="showPickerDois" max-width="400">
                                        <v-date-picker :min="job.start_date" @input="selectedDate" locale="pt-br"
                                            v-model="job.end_date" :show-current="true"></v-date-picker>
                                    </v-dialog>
                                </v-tab-item>
                                <v-tab key="PAGAMENTO" :disabled="Object.is(job.user, null)">
                                    Pagamento
                                </v-tab>
                                <v-tab-item key="PAGAMENTO">
                                    <v-row class="mt-5">
                                        <v-col sm="12">
                                            <job-payment :id="id" />
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs>
                        </div>
                    </template>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable indent */

import feedback from '../../util/mixins/feedback/feedback';
import object from '../../util/mixins/object/object';
import JobPayment from './tabs/JobsPayment.vue';
import api from '../../util/api/api';
import Loading from '../../components/common/DiweLoading.vue';
import moment from 'moment';
import AppEditor from '../../components/form/AppEditor';
import { mapValues, filter, map, isObject } from 'lodash';
import LogsIndex from '../../views/Logs/LogsIndex';

export default {
    name: 'JobsEdit',
    mixins: [feedback, object, api],
    components: { JobPayment, Loading, AppEditor, LogsIndex },
    data() {
        return {
            tab: 0,
            skillsItems: [],
            partnersItems: [],
            partnersPreSelected: [],
            // squad: ['Squadrilha', 'NHPJ Squad', 'Polvo Squad', 'SocialLab', 'Coral','Projetos específicos', 'Ararasquad','Squad PDC'],
            squad: ['Squadrilha Squad', 'NHPJ Squad', 'Polvo Squad', 'SocialLab Squad', 'Coral Squad', 'Projetos específicos Squad', 'Arara Squad', 'PDC Squad', 'Mídia Squad'],
            editar: false,
            job: {},
            valid: false,
            clientsItems: [],
            projectItems: [],
            showPicker: false,
            showPickerDois: false,
            activeTab: 0,
            id: null,
            loadingData: false,
            xxx: '',
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.getData();
    },
    methods: {
        ...isObject,
        getData() {
            this.loadingData = true;
            this.get(`/jobs/${this.id}`).then(response => {
                this.job = response.data.data;
                this.job.skills = this.job.skills.map(item => item.id);
                this.job.user_id = this.job.user ? this.job.user.id : '';
                this.job.partners = Object.values(mapValues(this.job.users, 'id'));
                this.partnersPreSelected = this.job.users;
                this.$store.commit('changeTitle', this.$router.currentRoute.meta.title.replace('{job_name}', this.job.name));
                this.get('/clients').then(response => {
                    this.clientsItems = response.data.data;
                });
                this.fetchPartners();
                this.get(`/skills`, {
                    grouped: true,
                }).then(response => {
                    this.skillsItems = response.data.data;
                });
                this.get(`/projects`).then(response => {
                    this.projectItems = response.data.data;
                    this.loadingData = false;
                });
            });
        },
        changeClient(client) {
            this.get(`/projects`, {
                client_id: client,
            }).then(response => {
                this.projectItems = response.data.data;
            });
        },
        changeDate(value) {
            if (value) return moment(value).format('DD/MM/YYYY');
        },
        updateJob() {
            if (this.$refs.form.validate()) {
                const jobObject = {
                    ...this.job,
                };
                this.put(`/jobs/${this.id}`, jobObject).then(() => {
                    this.$store.commit('showMessage', {
                        message: 'Demanda atualizada com sucesso.',
                        type: 'success',
                    });
                    this.$router.push('/jobs');
                });
            }
        },
        selectedDate(valor) {
            if (valor) {
                this.showPicker = false;
                this.showPickerDois = false;
            }
        },
        fetchPartners() {
            const queryParams = { perPage: 999999 };
            this.get(`/partners`, queryParams).then(response => {
                this.partnersItems = response.data.data;
            });
        },
        onChangePartners(partnerIDs) {
            const filtered = filter(this.partnersItems, partner => {
                return partnerIDs.includes(partner.user.id);
            });

            this.partnersPreSelected = map(filtered, 'user');
        },
    },
    computed: {
        statusItems() {
            return [
                { id: 'open', name: 'Aberto' },
                { id: 'inprogress', name: 'Em andamento' },
                { id: 'finish', name: 'Finalizado' },
                { id: 'delivered', name: 'Entregue' },
            ];
        },
        user() {
            return this.$store.getters.userLogged;
        },
        ratingCanBeEdit() {
            return this.job.status === 'delivered' && this.$hasRole('Diwer');
        },
    },
};
</script>

<style lang="scss">
.labelRating {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    label {
        font-weight: 700;
        font-size: 15px;
        margin-right: 10px;
    }
}

.no-padding {
    .col {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.jobsPage {
    .theme--light.v-tabs-items {
        background-color: transparent !important;
    }
}

.m-select {
    &__option {
        width: 100%;
        padding: 2px 0;
        border-bottom: 1px solid #f5f1f1;
    }
}
</style>
