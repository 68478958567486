var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('quill-editor',_vm._g(_vm._b({attrs:{"value":_vm.value,"name":_vm.forId,"options":{
     placeholder: _vm.placeholder,
     modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          // ['blockquote', 'code-block'],
          // [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          // [{ 'script': 'sub' }, { 'script': 'super' }],
          // [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          // [{ 'font': [] }],
          // [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }] ]
     }
  }}},'quill-editor',Object.assign({}, _vm.$attrs),false),_vm.$listeners),[_c('div',{attrs:{"slot":"toolbar","id":_vm.forId},slot:"toolbar"},[_c('div',{staticClass:"m-editor__label"},[_vm._v(_vm._s(_vm.placeholder))])])])}
var staticRenderFns = []

export { render, staticRenderFns }