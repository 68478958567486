import Vue from 'vue'
import Vuex from 'vuex'
import api from '../util/api/api'
import ApiLogEngine from '../util/api/api_log_engine'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)



const hasRoles = (userRoles, roles) => {
	return roles.map(x => userRoles.includes(x)).filter(x => x).length > 0
}

const hasPermissions = (user, permissions) => {
	return permissions.map(x => user.permissions.includes(x)).filter(x => x).length > 0
}

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: ['token', 'userLogged', 'status', 'openMenu'] //Apenas variaveis que devem persistir no sistema
		})],
	state: {
		titlePage: "Dashboard",
		userLogged: "",
		token: "",
		status: "",
		openMenu: false,
		snackbar: {
			show: false,
			message: 'Mensagem de testes',
			type: 'success'
		},
		loading: false,
	},
	mutations: {
		loading(state, payload) {
			state.loading = payload
		},
		showMessage(state, payload) {
			state.snackbar.show = true
			state.snackbar.message = payload.message
			state.snackbar.type = payload.type
		},
		closeMessage(state) {
			state.snackbar.show = false
		},
		changeTitle(state, title) {
			state.titlePage = title
			let titleDefault = ' - Diwe Intranet'
			document.title = title + titleDefault
		},
		auth_success(state, token) {
			state.status = 'success'
			state.token = token
		},
		userLogged(state, payload) {
			state.userLogged = payload
		},
		logout(state) {
			state.status = ''
			state.token = ''
		},
		openMenu(state, open) {
			state.openMenu = open
		}
	},
	actions: {
		closeMessage({ commit }) {
			commit('closeMessage')
		},
		async sendRememberEmail(_, email) {
			let { data } = await api.methods.post('/send-remember-email', {email : email})
			return data
		},
		async checkEmailExist(_, email) {
			let { data } = await api.methods.post('/check-email-exist', {email : email})
			return data
		},
		async login(context, user) {
			let { data } = await api.methods.post('/login', user)
			if (data.user.renew_password) {
				return "renew_password"
			}
			context.commit('auth_success', data.access_token)
			context.commit('userLogged', { ...data.user, roles: data.roles, permissions: data.permissions })
			return "logged"
		},
		logout({ commit }) {
			return new Promise((resolve) => {
				commit('logout')
				resolve()
			})
		},
		async fetchLogs(context, payload = {}) {

			let result = await ApiLogEngine.get("/logs", {
				params: {
					...payload
				}
			})
			return result
		}
	},
	getters: {
		userLogged: state => state.userLogged,
		isOpenMenu: state => state.openMenu,
		isLoggedIn: state => !!state.token,
		tokenIn: state => state.token,
		authStatus: state => state.status,
		titlePage: state => state.titlePage,
		snackbar: state => state.snackbar,
		loading: state => state.loading,
		hasRoles: state => (roles) => {
			return hasRoles(state.userLogged.roles, roles)
		},
		hasRole: state => (role) => {
			return hasRoles(state.userLogged.roles, [role])
		},
		hasPermissions: state => (permissions) => {
			return hasPermissions(state.userLogged, permissions)
		},
		hasPermission: state => (permission) => {
			return hasPermissions(state.userLogged, [permission])
		}
	}
})