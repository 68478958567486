import Vue from 'vue';

Vue.filter('cnpj', (value) => {
    if (value) return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12)}`;
    return '';
});

Vue.filter('cpf', (value) => {
    if (value) return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9)}`;
    return '';
});
