<template>
    <div id="acl-table" class="mt-4">
        <loading :condition="loading">
            <template v-for="group_key in Object.keys(grouped_data)">
                <div class="row mb-5" :key="group_key">
                    <div class="col-12">
                        <v-data-table
                            :headers="headers"
                            :items="grouped_data[group_key]"
                            item-key="name"
                            :loading="loading"
                            hide-default-footer
                            disable-pagination
                            hide-default-header
                        >
                            <template #header="{ props: { headers } }">
                                <thead class="v-data-table-header">
                                    <tr>
                                        <template v-for="header in headers">
                                            <template v-if="header.text == '_permission_group_'">
                                                <th class="group-column" :key="header.value">
                                                    {{ group_key }}
                                                </th>
                                            </template>
                                            <template v-else>
                                                <th :key="header.value">
                                                    {{ header.text }}
                                                </th>
                                            </template>
                                        </template>
                                    </tr>
                                </thead>
                            </template>

                            <template #item="{ item }">
                                <tr>
                                    <template v-for="(header, i) in headers">
                                        <template v-if="i == 0">
                                            <td :key="i">
                                                <b :permission-value="item.permission_name">
                                                    {{ item.permission_description }}
                                                </b>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <td
                                                :key="i"
                                                :class="
                                                    `role-color ${
                                                        item[`role_${i}`]
                                                            ? 'role-success'
                                                            : 'role-danger'
                                                    }`
                                                "
                                            >
                                                {{ item[`role_${i}`] ? 'Sim' : 'Não' }}
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </template>
        </loading>
    </div>
</template>

<script>
    import Loading from '../../../components/common/DiweLoading.vue';
    import api from '../../../util/api/api';
    import _ from 'lodash';
    export default {
        mixins: [api],
        data() {
            return {
                loading: true,
                data: [],
                permissions: [],
                roles: [],
                headers: [
                    {
                        text: '_permission_group_',
                        value: 'permission',
                        sortable: false,
                    },
                ],
            };
        },
        components: {
            Loading,
        },
        computed: {
            grouped_data() {
                return _.groupBy(this.data, 'permission_group');
            },
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.get('/acl/list').then(({ data }) => {
                    this.processData(data);
                });
            },
            async processData(data) {
                this.roles = data.roles;
                this.permissions = data.permissions;
                this.roles.map((role, i) => {
                    this.headers.push({
                        text: role.name,
                        value: `role_${i + 1}`,
                        width: '100px',
                        sortable: false,
                    });
                });
                let table_data = await this.permissions.map(permission => {
                    let row = {
                        permission_group: permission.group,
                        permission_name: permission.name,
                        permission_description: permission.description,
                    };
                    this.roles.forEach((role, i) => {
                        row[`role_${i + 1}`] = role.permissions.includes(permission.name);
                    });
                    return row;
                });

                this.data = table_data;
                this.loading = false;
                this.$waitElement('.v-row-group__header', els => {
                    this.processGroups(els);
                });
            },
            processGroups(els) {
                [...els].forEach(el => {
                    el.firstElementChild.innerText = '    ' + el.firstElementChild.innerText.split(':')[1];
                    el.style.visibility = 'visible';
                    ('block');
                });
                this.loading = false;
            },
        },
    };
</script>
<style lang="scss">
    #acl-table {
        .role-color {
            color: white !important;
            &.role-success {
                background-color: #37a780 !important;
            }
            &.role-danger {
                background-color: #b55d72 !important;
            }
        }
        .v-data-table-header {
            th {
                border: thin solid rgba(0, 0, 0, 0.12);
                background-color: #ececec !important;
                &.group-column {
                    width: 350px;
                }
            }
        }
        td {
            border: 0.5px solid #cfcfcf;
        }
        tr:hover {
            background-color: transparent !important;
        }
    }
</style>
