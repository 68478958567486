var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.$hasPermission('create-customers'))?_c('div',{staticClass:"actionOnPage"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/clientes/novo')}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" Adicionar cliente ")],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"cardDefault partnersCard"},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":10,"loading":_vm.$store.getters.loading},scopedSlots:_vm._u([{key:"item.site_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.site_url,"target":"_blank"}},[_vm._v(_vm._s(item.site_url))])]}},{key:"item.guide_url",fn:function(ref){
var item = ref.item;
return [(_vm.validUrl(item.guide_url))?_c('span',[_c('a',{attrs:{"href":item.guide_url,"target":"_blank"}},[_vm._v(_vm._s(item.guide_url.slice(0, 30) + (item.guide_url > 30 ? '...' : '')))])]):_c('span',[_vm._v(_vm._s(item.guide_url))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateHour")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('el-button-group',[(_vm.$hasPermission('edit-customers'))?_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push(
                                            '/clientes/editar/' + item.id
                                        )}}},[_vm._v(" Editar ")]):_vm._e(),(
                                        _vm.$hasPermission('delete-customers')
                                    )?_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-error"},on:{"click":function($event){return _vm.exclude(item.id)}}},[_vm._v(" Excluir ")]):_vm._e()],1)]}}],null,true)})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }