<template>
    <div>
        <v-row>
            <v-col sm="12">
                <div class="home">
                    <div class="actionOnPage mb-2">
                        <v-btn color="primary" small text @click="$router.push('/clientes')">
                            <v-icon>mdi-arrow-left</v-icon>
                            Voltar
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12">
                <v-tabs v-model="tab">
                    <v-tab>Visualização / Edição</v-tab>
                    <v-tab v-if="$hasPermission('logs') && id">Logs</v-tab>
                </v-tabs>
                <div class="home">
                    <template v-if="id && tab == 1 && $hasPermission('logs')">
                        <LogsIndex class="mt-5" feature="clients" :feature_id="id" />
                    </template>
                    <template v-if="tab == 0">
                        <v-form ref="form" v-model="valid">
                            <loading :condition="loading">
                                <v-row class="mt-5">
                                    <v-col sm="4">
                                        <div class="cardDefault">
                                            <div class="card-body">
                                                <h3 class="titleDefault">
                                                    Informações do cliente
                                                </h3>
                                                <v-text-field
                                                    outlined
                                                    required
                                                    v-model="cliente.name"
                                                    :rules="[!!cliente.name || 'Nome é requerido']"
                                                    label="Nome do cliente"
                                                />
                                                <v-text-field
                                                    outlined
                                                    required
                                                    v-model="cliente.phone"
                                                    v-mask="getMaskByType('CELLPHONE')"
                                                    :rules="[!!cliente.phone ||'Telefone é requerido',]"
                                                    label="Telefone"
                                                />
                                                <v-text-field
                                                    outlined
                                                    required
                                                    prepend-inner-icon="mdi-earth"
                                                    v-model="cliente.site_url"
                                                    :rules="[!!cliente.site_url || 'Website é requerido']"
                                                    label="Website"
                                                />
                                                <v-text-field
                                                    outlined
                                                    required
                                                    prepend-inner-icon="mdi-file-document-edit"
                                                    v-model="cliente.guide_url"
                                                    :rules="[!!cliente.guide_url ||'Guia de práticas é requerido']"
                                                    label="Guia de boas práticas"
                                                />
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="4">
                                        <div class="cardDefault">
                                            <div class="card-body">
                                                <h3 class="titleDefault">
                                                    Informações do responsável
                                                </h3>
                                                <v-text-field
                                                    outlined
                                                    required
                                                    v-model="cliente.responsible_name"
                                                    :rules="[!!cliente.responsible_name || 'Nome do responsável é requerido']"
                                                    label="Nome do responsável"
                                                />
                                                <v-text-field
                                                    outlined
                                                    required
                                                    v-model="cliente.responsible_email"
                                                    :rules="[!!cliente.responsible_email || 'E-mail do responsável é requerido']"
                                                    label="E-mail do responsável"
                                                />
                                                <v-text-field
                                                    outlined
                                                    required
                                                    v-model="cliente.responsible_phone"
                                                    v-mask="getMaskByType('CELLPHONE')"
                                                    :rules="[!!cliente.responsible_phone || 'Telefone do responsável é requerido']"
                                                    label="Telefone do responsável"
                                                />
                                                <v-text-field
                                                    outlined
                                                    v-model="cliente.category"
                                                    label="Categoria de Faturamento"
                                                />
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col sm="12">
                                        <div class="mt-5">
                                            <v-btn
                                                :loading="$store.getters.loading"
                                                :disabled="$store.getters.loading"
                                                class="ma-2 buttonWhite"
                                                color="successAlt"
                                                @click="updateClient"
                                            >
                                                Salvar alterações
                                            </v-btn>
                                            <v-btn class="ma-2 buttonWhite" color="errorAlt" @click="$router.push('/clientes')">
                                                Cancelar
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </loading>
                        </v-form>
                    </template>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import feedback from '../../util/mixins/feedback/feedback';
    import object from '../../util/mixins/object/object';
    import Loading from '../../components/common/DiweLoading.vue';
    import api from '../../util/api/api';
    import LogsIndex from '../../views/Logs/LogsIndex';

    export default {
        name: 'ClientsCreate',
        components: { Loading, LogsIndex },
        mixins: [feedback, object, api],
        data() {
            return {
                tab: 0,
                loading: false,
                id: null,
                editar: false,
                cliente: {},
                valid: false,
            };
        },
        created() {
            this.id = this.$route.params.id;
            this.loading = true;
            this.get(`/clients/${this.id}`).then(response => {
                this.cliente = response.data.data;
                this.$store.commit(
                    'changeTitle',
                    this.$router.currentRoute.meta.title.replace('{client_name}', this.cliente.name)
                );
                this.loading = false;
            });
        },
        methods: {
            updateClient() {
                if (this.$refs.form.validate()) {
                    const clientObject = {
                        ...this.cliente,
                        responsible_phone: this.unformatMaskByType(this.cliente.responsible_phone, 'PHONE'),
                        phone: this.unformatMaskByType(this.cliente.phone, 'PHONE'),
                    };
                    this.put(`/clients/${this.id}`, clientObject).then(() => {
                        this.$store.commit('showMessage', {
                            message: 'Cliente atualizado com sucesso.',
                            type: 'success',
                        });
                        this.$router.push('/clientes');
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
