import axios from 'axios';
import api from './api';
import headerParam from './headerParam';

export default {
    name: 'interceptor',
    mixins: [api, headerParam],
    methods: {
        createInterceptors() {

            axios.interceptors.request.use((config) => {
                this.$store.commit('loading', true);
                const newConfig = config;
                if(this.getAuthorizationToken() != ""){
                    newConfig.headers.Authorization = this.getAuthorizationToken();
                }
                newConfig.headers['App-Params'] = this.getParams();
                newConfig.headers['App-Agent'] = this.getAgent();
                newConfig.headers['App-Front-Data'] = this.getFrontData();
                return newConfig;
            }, error => Promise.reject(error));

            axios.interceptors.response.use((response) => {
                this.$store.commit('loading', false);
                return response;
            }, (error) => {
                this.$store.commit('loading', false);
                if ((error.response.status === 401 )) {
                    this.$store.dispatch('logout').then(() => {
                        window.location.replace('/login');
                    });
                } else if (error.response.status === 400) {
                    const { data: { message }} = error.response
                    this.$store.commit('showMessage', { message: message, type: 'error' });
                } else if (error.response.status === 403) {
                    this.$router.push('/forbidden');
                } else if ((error.response.status === 401 && !window.location.href.includes('/app')) || error.response.status === 409) { // TODO verificar o 409 para conflitos de remoção
                    this.$store.commit('showMessage', {message: 'Ops, algo deu errado, entre em contato com o desenvolvedor.', type: 'error' });
                } else if (error.response.status === 422) {
                    this.$store.commit('showMessage', {message: 'Ops, algo deu errado, entre em contato com o desenvolvedor.', type: 'error' });
                } else if (error.response.status === 502) {
                    this.$store.commit('showMessage', {message: 'Ops, algo deu errado, entre em contato com o desenvolvedor.', type: 'error' });
                } else if (error.response.status >= 500 && error.response.status <= 599) {
                    this.$store.commit('showMessage', {message: 'Ops, algo deu errado, entre em contato com o desenvolvedor.', type: 'error' });
                }
                return Promise.reject(error);
            });
        },
    },
};
