<template>
    <div class="home">
        <div class="actionOnPage">
            <v-btn
                color="primary"
                small
                text
                @click="$router.push('/parceiros')"
                ><v-icon>mdi-arrow-left</v-icon> Voltar</v-btn
            >
        </div>
        <v-form ref="form" v-model="valid">
            <v-row class="mt-5">
                <v-col sm="6">
                    <div class="cardDefault">
                        <div class="card-body">
                            <h3 class="titleDefault">
                                Informações do parceiro
                            </h3>
                            <file-upload-image
                                :name="parceiro.name"
                                @image="image"
                            />
                            <v-text-field
                                outlined
                                required
                                v-model="parceiro.name"
                                :rules="[!!parceiro.name || 'Nome é requerido']"
                                label="Nome completo do parceiro"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                required
                                v-model="parceiro.email"
                                :rules="[
                                    !!parceiro.email || 'E-mail é requerido',
                                ]"
                                label="E-mail do parceiro"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                required
                                v-model="parceiro.phone"
                                v-mask="getMaskByType('CELLPHONE')"
                                :rules="[
                                    !!parceiro.phone || 'Telefone é requerido',
                                ]"
                                label="Telefone do parceiro"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                required
                                prefix="R$"
                                suffix="/h"
                                v-model="parceiro.hour_value"
                                :rules="[
                                    !!parceiro.hour_value ||
                                        'Valor/hora é requerido',
                                ]"
                                v-money="money"
                                label="Valor/h do parceiro"
                                placeholder="30,00"
                            ></v-text-field>
                            <v-text-field
                                v-model="parceiro.password"
                                :append-icon="
                                    typePassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                @click:append="
                                    () => (typePassword = !typePassword)
                                "
                                label="Senha"
                                :type="typePassword ? 'password' : 'text'"
                                required
                                outlined
                                :rules="[
                                    () =>
                                        !!parceiro.password ||
                                        'O campo é obrigatório',
                                ]"
                            />
                            <v-row class="no-padding">
                                <v-col sm="8">
                                    <file-upload-image
                                        :has-avatar="false"
                                        label="Contrato"
                                        placeholder="Selecione um contrato"
                                        @image="onListenerUploadAgreement"
                                    />
                                </v-col>
                                <v-col sm="4">
                                    <v-text-field
                                        :value="
                                            changeDate(
                                                parceiro.agreement_due_date
                                            )
                                        "
                                        prepend-inner-icon="mdi-calendar-range"
                                        label="Data vencimento"
                                        @click="datepickerAgreement = true"
                                        readonly
                                        outlined
                                    />
                                    <v-dialog
                                        v-model="datepickerAgreement"
                                        max-width="400"
                                    >
                                        <v-date-picker
                                            @input="selectedDate"
                                            :close-on-content-click="false"
                                            locale="pt-br"
                                            v-model="
                                                parceiro.agreement_due_date
                                            "
                                            :show-current="true"
                                        />
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <div class="mt-5">
                        <v-btn
                            class="ma-2 buttonWhite"
                            color="successAlt"
                            @click="createPartner"
                            >Cadastrar parceiro</v-btn
                        >
                        <v-btn
                            class="ma-2 buttonWhite"
                            color="errorAlt"
                            @click="$router.push('/parceiros')"
                            >Cancelar</v-btn
                        >
                    </div>
                </v-col>
                <v-col sm="6">
                    <div class="cardDefault">
                        <div class="card-body">
                            <h3 class="titleDefault">
                                Skills e informações Diwe
                            </h3>
                            <v-autocomplete
                                multiple
                                outlined
                                small
                                v-model="parceiro.skills"
                                :items="skillsItems"
                                item-text="skill.name"
                                item-value="skill.id"
                                label="Skills"
                                :rules="[
                                    () =>
                                        !!parceiro.skills ||
                                        'Skills é obrigatório',
                                ]"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip color="primary">
                                        <span>{{ item.skill.name }}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:item="data">
                                    <template
                                        v-if="typeof data.item !== 'object'"
                                    >
                                        <v-list-title
                                            v-text="data.item"
                                        ></v-list-title>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <div
                                                class="head"
                                                v-html="data.item.skill.name"
                                            ></div>
                                            <div
                                                class="subhead d-block"
                                                v-html="
                                                    data.item.competence.name
                                                "
                                            ></div>
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-earth"
                                v-model="parceiro.portfolio"
                                label="Portfólio (URL)"
                            ></v-text-field>

                            <v-text-field
                                outlined
                                required
                                prepend-inner-icon="mdi-calendar-range"
                                :value="changeDate(parceiro.date_start)"
                                @click="datepicker = true"
                                label="Parceiro desde"
                                placeholder="Selecione uma data"
                                readonly
                                :rules="[
                                    !!parceiro.date_start ||
                                        'Parceiro desde é requerido',
                                ]"
                            ></v-text-field>

                            <app-editor
                                v-model="parceiro.obs"
                                :value="parceiro.obs"
                                class="mb-12"
                                height="80"
                                for-id="obs"
                                placeholder="Observações"
                            />

                            <v-dialog v-model="datepicker" max-width="400">
                                <v-date-picker
                                    @input="selectedDate"
                                    :close-on-content-click="false"
                                    locale="pt-br"
                                    v-model="parceiro.date_start"
                                    :show-current="true"
                                />
                            </v-dialog>
                        </div>
                        <div class="card-body">
                            <h3 class="titleDefault">Dados bancários</h3>
                            <v-row class="no-padding mt-2">
                                <v-col sm="12">
                                    <v-text-field
                                        outlined
                                        v-model="parceiro.company_name"
                                        label="Nome para emissão da nota"
                                    />
                                </v-col>
                                <v-col sm="12">
                                    <v-text-field
                                        outlined
                                        v-model="parceiro.cnpj"
                                        v-mask="getMaskByType('CNPJ')"
                                        label="CNPJ"
                                    />
                                </v-col>
                                <v-col sm="12">
                                    <app-editor
                                        v-model="parceiro.bank_details"
                                        :value="parceiro.bank_details"
                                        class="mb-12"
                                        height="80"
                                        for-id="bank_data"
                                        placeholder="Informações bancárias"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
/* eslint-disable indent */

import FileUploadImage from '../../components/common/FileUploadImage';
import object from '../../util/mixins/object/object';
import api from '../../util/api/api';
import moment from 'moment';
import AppEditor from '../../components/form/AppEditor';

export default {
    components: {
        FileUploadImage,
        AppEditor,
    },
    mixins: [object, api],
    name: 'PartnersCreate',
    data() {
        return {
            valid: false,
            parceiro: {},
            datepicker: false,
            datepickerAgreement: false,
            typePassword: false,
            skillsItems: [],
        };
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.getSkills();
    },
    methods: {
        changeDate(value) {
            if (value) return moment(value).format('DD/MM/YYYY');
        },
        getSkills() {
            this.get(`/skills`, {
                grouped: true,
            }).then((response) => {
                this.skillsItems = response.data.data;
            });
        },
        createPartner() {
            if (this.$refs.form.validate()) {
                const parceiroObject = {
                    ...this.parceiro,
                    hour_value: this.unformatMaskByType(
                        this.parceiro.hour_value,
                        'MONEY'
                    ),
                    phone: this.unformatMaskByType(
                        this.parceiro.phone,
                        'PHONE'
                    ),
                };
                this.post('/partners', parceiroObject).then(() => {
                    this.$store.commit('showMessage', {
                        message: 'Parceiro adicionado com sucesso.',
                        type: 'success',
                    });
                    this.$router.push('/parceiros');
                });
            }
        },
        image(image) {
            this.parceiro.image = image;
        },
        onListenerUploadAgreement(image) {
            this.parceiro.agreement = image;
        },
        selectedDate(valor) {
            if (valor) {
                this.datepicker = false;
                this.datepickerAgreement = false;
            }
        },
    },
};
</script>
