<template>
    <div class="login_box">
        <div class="logo_login">
            <img src="../../assets/images/logo.svg" alt="" />
        </div>
        <div class="card-login">
            <div class="login_form">
                <template v-if="action == 'login'">
                    <h2>Bem vindo novamente, faça seu login.</h2>
                    <form name="form" @submit.prevent="handleLogin">
                        <div class="erro" v-if="message">
                            {{ message }}
                        </div>

                        <v-text-field
                            append-icon="mdi-account-circle"
                            v-model="user.username"
                            label="E-mail"
                            class="required"
                            placeholder=" "
                            required
                            outlined
                            :rules="[
                                () =>
                                    !!user.username || 'O campo é obrigatório',
                            ]"
                        />

                        <v-text-field
                            v-model="user.password"
                            :append-icon="
                                typePassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="() => (typePassword = !typePassword)"
                            label="Senha"
                            class="required"
                            :type="typePassword ? 'password' : 'text'"
                            placeholder=" "
                            required
                            outlined
                            :rules="[
                                () =>
                                    !!user.password || 'O campo é obrigatório',
                            ]"
                        />

                        <div class="text-center">
                            <v-btn
                                rounded
                                large
                                type="submit"
                                :disabled="loading"
                                class="gradient"
                                dark
                                >Entrar</v-btn
                            >
                        </div>
                        <div class="text-center mt-3">
                            <a class="recovery" @click="$router.push({name : 'ForgetMyPassword'})">
                                Esqueceu a senha ?
                            </a>
                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                        v-bind="attrs"
                                        v-on="on"
                                        class="recovery disabled"
                                        >Ainda não tem conta? Cadastre-se
                                        aqui</a
                                    >
                                </template>
                                <span>Em breve...</span>
                            </v-tooltip> -->
                        </div>

                        <v-progress-circular
                            indeterminate
                            color="green"
                            v-show="loading"
                        />
                    </form>
                </template>
                <template v-if="action == 'renew_password'">
                    <div class="erro" v-if="message">
                        {{ message }}
                    </div>
                    <h2>Para prosseguir, defina sua senha</h2>
                    <form name="form" @submit.prevent="handleLogin">
                        <v-text-field
                            v-model="user.new_password"
                            :append-icon="
                                typePassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="() => (typePassword = !typePassword)"
                            label="Senha"
                            class="required"
                            :type="typePassword ? 'password' : 'text'"
                            placeholder=" "
                            required
                            outlined
                            :rules="[
                                () =>
                                    !!user.new_password ||
                                    'O campo é obrigatório',
                            ]"
                        />

                        <v-text-field
                            v-model="user.confirmation"
                            :append-icon="
                                typePassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="() => (typePassword = !typePassword)"
                            label="Confirme a Senha"
                            class="required"
                            :type="typePassword ? 'password' : 'text'"
                            placeholder=" "
                            required
                            outlined
                            :rules="[
                                () =>
                                    !!user.confirmation ||
                                    'O campo é obrigatório',
                            ]"
                        />

                        <div class="text-center">
                            <v-btn
                                rounded
                                large
                                type="submit"
                                :disabled="loading"
                                class="gradient"
                                dark
                                >Definir</v-btn
                            >
                        </div>

                        <v-progress-circular
                            indeterminate
                            color="green"
                            v-show="loading"
                        />
                    </form>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../util/api/api';

export default {
    name: 'login',
    mixins: [api],
    computed: {
        loggedIn() {
            return this.$store.getters.isLoggedIn;
        },
    },
    created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
    },
    data() {
        return {
            action: 'login',
            user: {
                username: '',
                password: '',
                new_password: '',
                confirmation: '',
            },
            typePassword: String,
            loginGroup: 'diwer',
            loading: false,
            message: '',
        };
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    methods: {
        startRenewPassword() {
            this.action = 'renew_password';
            this.loading = false;
        },
        validateForm() {
            if (this.action == 'login') {
                if (!this.user.username || !this.user.password) {
                    this.loading = false;
                    this.message = 'Você precisa digitar um e-mail e uma senha';
                    throw 'Login error';
                }
            }
            if (this.action == 'renew_password') {
                if (
                    !this.user.username ||
                    !this.user.password ||
                    !this.user.new_password ||
                    !this.user.confirmation
                ) {
                    this.loading = false;
                    this.message = 'Você precisa digitar a senha e confirmação';
                    throw 'Renew Pass error';
                }
                if (this.user.new_password != this.user.confirmation) {
                    this.loading = false;
                    this.message = 'Confirme a senha';
                    throw 'confirmation Error';
                }
            }
        },
        handleLogin() {
            this.loading = true;
            this.validateForm();

            this.$store
                .dispatch('login', {
                    email: this.user.username,
                    password: this.user.password,
                    new_password: this.user.new_password,
                    confirmation: this.user.confirmation,
                })
                .then((action) => {
                    if (action == 'logged') {
                        return this.$router.push('/');
                    }
                    if (action == 'renew_password') {
                        this.startRenewPassword();
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.message = 'E-mail ou usuário inválidos.';
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login_box {
    width: 550px;
    margin: 20vh auto;
}
.v-input--radio-group__input {
    justify-content: center !important;
}
.logo_login {
    text-align: center;
    img {
        max-width: 200px;
    }
}
.card-login {
    background-color: #fff;
    padding: 35px 40px;
    text-align: center;
    border-radius: 11px;
    margin-top: 47px;
    h2 {
        font-size: 22px;
        margin-bottom: 31px;
    }
    .erro {
        color: #f00;
        padding: 16px;
        margin-bottom: 21px;
        border: 1px solid;
        border-radius: 4px;
    }
    button {
        min-width: 260px !important;
    }
}
.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row {
    margin-top: 0;
}
.v-input--radio-group__input {
    justify-content: center;
    align-items: center;
}

.recovery.disabled {
    color: #ccc;
}
</style>