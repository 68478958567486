<template>
    <div class="home jobsPage">
        <div class="actionOnPage mb-40">
            <v-btn color="primary" small text @click="$router.push('/jobs')"
                ><v-icon>mdi-arrow-left</v-icon> Voltar</v-btn
            >
        </div>
        <v-tabs v-model="activeTab" light slider-color="primary">
            <v-tab key="DATA"> Dados </v-tab>
            <v-tab-item key="DATA">
                <v-form ref="form" v-model="valid">
                    <v-row class="mt-5">
                        <v-col sm="6">
                            <div class="cardDefault">
                                <div class="card-body">
                                    <h3 class="titleDefault">
                                        Informações da demanda
                                    </h3>
                                    <v-row class="no-padding">
                                        <v-col sm="12">
                                            <v-text-field
                                                outlined
                                                required
                                                v-model="job.name"
                                                :rules="[
                                                    !!job.name ||
                                                        'Nome é requerido',
                                                ]"
                                                label="Nome do job"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-select
                                                outlined
                                                required
                                                v-model="job.client_id"
                                                :rules="[
                                                    !!job.client_id ||
                                                        'Cliente é requerido',
                                                ]"
                                                label="Cliente"
                                                :items="clientsItems"
                                                item-text="name"
                                                item-value="id"
                                                @change="changeClient"
                                            ></v-select>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-row class="mt-0">
                                                <v-col sm="10">
                                                    <v-select
                                                        outlined
                                                        required
                                                        v-model="job.project_id"
                                                        :rules="[
                                                            !!job.project_id ||
                                                                'Projeto é requerido',
                                                        ]"
                                                        label="Projeto"
                                                        :items="projectItems"
                                                        item-text="name"
                                                        item-value="id"
                                                        :disabled="
                                                            projectItems.length ===
                                                            0
                                                        "
                                                    ></v-select>
                                                </v-col>
                                                <v-col
                                                    sm="2"
                                                    class="ml-n4 mt-2"
                                                >
                                                    <v-btn
                                                        color="primary"
                                                        :disabled="
                                                            !job.client_id
                                                        "
                                                        @click="
                                                            tokenDialogGenerator
                                                        "
                                                    >
                                                        <v-icon
                                                            >mdi-plus-circle</v-icon
                                                        >
                                                    </v-btn>
                                                    <project-create-simplified
                                                        :token="
                                                            dialogProjectToken
                                                        "
                                                        :clientId="
                                                            job.client_id
                                                        "
                                                        @on-create="
                                                            listenerOnCreateProject
                                                        "
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field
                                                outlined
                                                required
                                                prepend-inner-icon="mdi-calendar-range"
                                                :value="
                                                    changeDate(job.start_date)
                                                "
                                                @click="showPicker = true"
                                                label="Data de início"
                                                placeholder="Selecione uma data"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="6">
                                            <v-text-field
                                                outlined
                                                required
                                                prepend-inner-icon="mdi-calendar-range"
                                                :value="
                                                    changeDate(job.end_date)
                                                "
                                                @click="showPickerDois = true"
                                                label="Data de entrega"
                                                placeholder="Selecione uma data"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="no-padding">
                                        <v-col sm="8">
                                            <v-text-field
                                                outlined
                                                required
                                                prepend-inner-icon="mdi-earth"
                                                v-model="job.briefing"
                                                :rules="[
                                                    !!job.briefing ||
                                                        'Briefing é requerido',
                                                ]"
                                                label="Briefing"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="4">
                                            <v-text-field
                                                outlined
                                                required
                                                v-model="job.budget"
                                                :rules="[
                                                    !!job.budget ||
                                                        'Budget é requerido',
                                                ]"
                                                prefix="R$"
                                                v-money="moneyCents"
                                                label="Budget"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="12 pb-12">
                                            <app-editor
                                                required
                                                v-model="job.description"
                                                :value="job.description"
                                                height="120"
                                                for-id="description"
                                                placeholder="Descrição"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-col>
                        <v-col sm="6">
                            <div class="cardDefault">
                                <div class="card-body">
                                    <h3 class="titleDefault">
                                        Informações do parceiro
                                    </h3>
                                    <v-autocomplete
                                        multiple
                                        outlined
                                        small
                                        v-model="job.skills"
                                        :items="skillsItems"
                                        item-text="skill.name"
                                        item-value="skill.id"
                                        @change="fetchPartners"
                                        label="Skills"
                                    >
                                        <template v-slot:selection="{ item }">
                                            <v-chip color="primary">
                                                <span>{{
                                                    item.skill.name
                                                }}</span>
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template
                                                v-if="
                                                    typeof data.item !==
                                                    'object'
                                                "
                                            >
                                                <v-list-title
                                                    v-text="data.item"
                                                ></v-list-title>
                                            </template>
                                            <template v-else>
                                                <div>
                                                    <div
                                                        class="head"
                                                        v-html="
                                                            data.item.skill.name
                                                        "
                                                    ></div>
                                                    <div
                                                        class="subhead d-block"
                                                        v-html="
                                                            data.item.competence
                                                                .name
                                                        "
                                                    ></div>
                                                </div>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                    <v-select
                                        outlined
                                        class="m-select"
                                        v-model="job.partners"
                                        label="Selecionar parceiro(s)"
                                        :items="partnersItems"
                                        item-text="user.name"
                                        item-value="user.id"
                                        multiple
                                        persistent-hint
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            {{ item.user.name }} +
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <div class="m-select__option">
                                                <div class="head">
                                                    <div
                                                        class="head"
                                                        v-html="item.user.name"
                                                    ></div>
                                                </div>
                                                <div
                                                    class="subhead d-block pb-2"
                                                >
                                                    <v-chip
                                                        x-small
                                                        outlined
                                                        color="primary"
                                                        class="ml-2"
                                                        v-for="(
                                                            skill, index
                                                        ) in item.user.skills.slice(
                                                            0,
                                                            3
                                                        )"
                                                        :key="index"
                                                    >
                                                        <span>{{
                                                            skill.name
                                                        }}</span>
                                                    </v-chip>
                                                </div>
                                            </div>
                                        </template>
                                    </v-select>

                                    <v-select
                                        outlined
                                        class="m-select"
                                        v-model="job.squad"
                                        label="Squad"
                                        :items="squad"
                                        persistent-hint
                                    >
                                    </v-select>

                                    <app-editor
                                        required
                                        v-model="job.note"
                                        :value="job.note"
                                        height="120"
                                        for-id="note"
                                        placeholder="Observações"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col sm="12">
                            <div class="mt-5">
                                <v-btn
                                    :loading="$store.getters.loading"
                                    :disabled="$store.getters.loading"
                                    class="ma-2 buttonWhite"
                                    color="successAlt"
                                    @click="createJob"
                                    >Salvar alterações</v-btn
                                >
                                <v-btn
                                    class="ma-2 buttonWhite"
                                    color="errorAlt"
                                    @click="$router.push('/jobs')"
                                    >Cancelar</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-form>

                <v-dialog v-model="showPicker" max-width="400">
                    <v-date-picker
                        @input="selectedDate"
                        locale="pt-br"
                        v-model="job.start_date"
                        :show-current="true"
                    ></v-date-picker>
                </v-dialog>
                <v-dialog v-model="showPickerDois" max-width="400">
                    <v-date-picker
                        :min="job.start_date"
                        @input="selectedDate"
                        locale="pt-br"
                        v-model="job.end_date"
                        :show-current="true"
                    ></v-date-picker>
                </v-dialog>
            </v-tab-item>
            <v-tab key="PAGAMENTO" :disabled="true"> Pagamento </v-tab>
            <v-tab-item key="PAGAMENTO">
                <v-row class="mt-5">
                    <v-col sm="12">
                        Você precisa criar o job primeiramente.
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
/* eslint-disable indent */

import feedback from '../../util/mixins/feedback/feedback';
import object from '../../util/mixins/object/object';
import api from '../../util/api/api';
import moment from 'moment';
import ProjectCreateSimplified from '../Projects/components/ProjectCreateSimplified';
import AppEditor from '../../components/form/AppEditor';
import _ from 'lodash';
export default {
    name: 'JobsCreate',
    mixins: [feedback, object, api],
    components: { ProjectCreateSimplified, AppEditor },
    data() {
        return {
            skillsItems: [],
            partnersItems: [],
            // squad: ['Squadrilha', 'NHPJ Squad', 'Polvo Squad', 'SocialLab', 'Coral','Projetos específicos', 'Ararasquad','Squad PDC'],
                squad: ['Squadrilha Squad', 'NHPJ Squad', 'Polvo Squad', 'SocialLab Squad', 'Coral Squad','Projetos específicos Squad', 'Arara Squad','PDC Squad', 'Mídia Squad'],
            editar: false,
            job: {},
            valid: false,
            clientsItems: [],
            projectItems: [],
            showPicker: false,
            showPickerDois: false,
            activeTab: 0,
            dialogProjectToken: '',
        };
    },
    async created() {
        this.$store.commit('changeTitle', this.$router.currentRoute.meta.title);
        this.get('/clients').then((response) => {
            this.clientsItems = response.data.data;
            this.getSkills();
        });
        await this.fetchPartners();
    },
    methods: {
        getSkills() {
            this.get(`/skills`, {
                grouped: true,
            }).then((response) => {
                this.skillsItems = response.data.data;
            });
        },
        fetchPartners() {
            const filterSkills = this.job.skills
                ? { 'filter[skills]': this.job.skills.join() }
                : null;
            const queryParams = { perPage: 100, ...filterSkills };
            this.get(`/partners`, queryParams).then((response) => {
                this.partnersItems = _.orderBy(
                    response.data.data,
                    (row) => {
                        return row.user.name;
                    },
                    ['asc']
                );
            });
        },
        changeClient(client) {
            this.get(`/projects`, {
                client_id: client,
            }).then((response) => {
                this.projectItems = response.data.data;
            });
        },
        changeDate(value) {
            if (value) return moment(value).format('DD/MM/YYYY');
        },
        createJob() {
            if (this.$refs.form.validate()) {
                const jobObject = {
                    ...this.job,
                    partner_id: 1,
                    status: 'open',
                };
                this.post('/jobs', jobObject).then(() => {
                    this.$store.commit('showMessage', {
                        message: 'Demanda adicionada com sucesso.',
                        type: 'success',
                    });
                    this.$router.push('/jobs');
                });
            }
        },
        selectedDate(valor) {
            if (valor) {
                this.showPicker = false;
                this.showPickerDois = false;
            }
        },
        tokenDialogGenerator() {
            this.dialogProjectToken = Math.random().toString(16);
        },
        listenerOnCreateProject() {
            this.changeClient(this.job.client_id);
        },
    },
};
</script>

<style lang="scss">
.labelRating {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    label {
        font-weight: 700;
        font-size: 15px;
        margin-right: 10px;
    }
}
.no-padding {
    .col {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
.jobsPage {
    .theme--light.v-tabs-items {
        background-color: transparent !important;
    }
}
.m-select {
    &__option {
        width: 100%;
        padding: 2px 0;
        border-bottom: 1px solid #f5f1f1;
    }
}
</style>
